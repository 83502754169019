import { connect } from "react-redux";
import { IconButton, Paper, Tooltip, Typography } from "@material-ui/core"
import { EmailOutlined, EditOutlined, DeleteForeverOutlined } from '@material-ui/icons';
import { sendTeatroEventToSubscribers } from "../../../../../data/actions/subscribeActions";

const styles = {
    paper: {
        margin: '20px 0',
        padding: '10px 20px',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '120px',
        justifyContent: 'space-between',
    },
    cardTop: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    cardBottom: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardBottomLeft: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    bottomLeftText: {
        marginRight: '10px',
    },
    date: isFuture => ({
        color: isFuture ? '#00c92b' : '#ff4a4a',
    })
}

const TeatroEventCard = ({
    lang,
    event,
    isFuture,
    handleOpenEditDialog,
    sendTeatroEventToSubscribers,
    handleOpenConfirmDeleteDialog,
}) => {
    const handleSendToSubscribers = () => sendTeatroEventToSubscribers(event);

    const formatDate = (date) => {
        const d = new Date(date);
        const hours = d.getHours().toString().length === 1 ? `0${d.getHours()}` : d.getHours();
        const minutes = d.getMinutes().toString().length === 1 ? `${d.getMinutes()}0` : d.getMinutes();
        return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()} - ${hours}:${minutes}`;
    }
    const date = formatDate(event.date);
    const minimalConsumptionText = lang === 'bg'
        ?
        `Минимална консумация: ${event.minimalConsumption && event.minimalConsumption !== '0' ? `${event.minimalConsumption} лв.` : 'НЯМА'}`
        :
        `Minimal consumption: ${event.minimalConsumption && event.minimalConsumption !== '0' ? `${event.minimalConsumption} bgn` : 'NONE'}`
    const reservationText = lang === 'bg'
        ?
        `Задължителна резервация: ${event.reservationRequired ? 'ДА' : 'НЕ'}`
        :
        `Reservation required: ${event.reservationRequired ? 'YES' : 'NO'}`;
    const entranceText = lang === 'bg'
        ?
        `Вход: ${event.price && event.price !== '0' ? `${event.price} лв.` : 'Свободен'}`
        :
        `Entrance: ${event.price && event.price !== '0' ? `${event.price} bgn` : 'FREE'}`;
    return (
        <Paper style={styles.paper} elevation={3}>
            <div style={styles.cardTop}>
                <Typography variant='h6'>
                    {event.title[lang]} - <span style={styles.date(isFuture)}>{date}ч.</span>
                </Typography>
                <div>
                    <Tooltip title='Изпрати на абонатите'>
                        <IconButton onClick={handleSendToSubscribers} size='small'>
                            <EmailOutlined />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Промени'>
                        <IconButton onClick={handleOpenEditDialog} color='primary' size='small'>
                            <EditOutlined />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Изтрий'>
                        <IconButton onClick={handleOpenConfirmDeleteDialog} color='secondary' size='small'>
                            <DeleteForeverOutlined />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <div style={styles.cardContent}>
                <Typography variant='caption'>{event.description[lang]}</Typography>
            </div>
            <div style={styles.cardBottom}>
                <div style={styles.cardBottomLeft}>
                    <Typography variant='body2' style={styles.bottomLeftText}>{minimalConsumptionText}</Typography>
                    <Typography variant='body2' style={styles.bottomLeftText}>{reservationText}</Typography>
                </div>
                <div>
                    <Typography variant='h6'>{entranceText}</Typography>
                </div>
            </div>
        </Paper>
    )
}

const mapDispatchToProps = { sendTeatroEventToSubscribers }

export default connect(null, mapDispatchToProps)(TeatroEventCard);
