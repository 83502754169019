import { constants, languages, pages } from '../constants';
import { CHANGE_PAGE_LANGAUGE } from '../actions/pageActions';

const initialState = {
    page: pages.teatro.name,
    language: languages.bg.code,
    heading: constants.heading.teatro
}

export const pageReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_PAGE_LANGAUGE: return { ...state, language: action.payload };
        default: return state;
    }
}

export const getLang = state => state.page.language;
