import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ErrorMessage, Formik } from 'formik';
import {
    Button,
    Dialog,
    Select,
    MenuItem,
    Checkbox,
    TextField,
    withStyles,
    FormControl,
    DialogTitle,
    DialogActions,
    DialogContent,
    FormControlLabel,
} from '@material-ui/core';
import { notificationTypes } from '../../../../../../data/constants';
import { showNotification } from '../../../../../../data/actions/notificationActions';
import { getTeatroMenuCategories } from '../../../../../../data/actions/templatesActions';
import { validateNotEmptyInputs, validateNumberInputs } from '../../../../../../data/validations';

const styles = theme => ({
    input: {
        margin: '10px 0',
    },
    multiInput: {
        width: '45%',
    },
    oneRowMultiInputs: {
        margin: '10px 0',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
})

const defaultQuantityType = { en: 'gr.', bg: 'гр.' };
const quantityTypes = [
    defaultQuantityType,
    { en: 'ml.', bg: 'мл.' },
    { en: 'pcs.', bg: 'бр.' },
]

function EditMealDialog({
    open,
    meal,
    classes,
    categories,
    handleClose,
    handleUpdate,
    showNotification,
    getTeatroMenuCategories,
}) {
    useEffect(() => {
        getTeatroMenuCategories();
    }, [getTeatroMenuCategories]);

    const handleEditMeal = values => {
        const { orderId, enName, bgName, mealType, netWeight, price, enIngredients, bgIngredients, isNew, quantityType } = values;
        if (orderId && enName && bgName && mealType && netWeight && price) {
            const updatedMeal = {
                ...meal,
                isAvailable: true,
                orderId,
                name: {
                    en: enName,
                    bg: bgName,
                },
                type: mealType,
                ingredients: {
                    en: enIngredients || '-',
                    bg: bgIngredients || '-',
                },
                netWeight,
                price: Number(price).toFixed(2),
                isNew,
                quantityType,
            }
            handleUpdate(updatedMeal);
        } else {
            showNotification('all inputs are required', notificationTypes.error);
        }
    }

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    orderId: meal?.orderId || '',
                    enName: meal?.name?.en || '',
                    bgName: meal?.name?.bg || '',
                    enIngredients: meal?.ingredients?.en || '',
                    bgIngredients: meal?.ingredients?.bg || '',
                    mealType: meal?.type || '',
                    netWeight: meal?.netWeight || '',
                    price: meal?.price || '',
                    isNew: meal?.isNew || '',
                    quantityType: meal?.quantityType || defaultQuantityType,
                }}
                validate={values => ({
                    ...validateNotEmptyInputs(
                        [
                            'orderId',
                            'enName',
                            'bgName',
                            'mealType',
                            'netWeight',
                            'price',
                            'quantityType',
                        ],
                        values
                    ),
                    ...validateNumberInputs(['orderId', 'netWeight', 'price'], values)
                })}
                onSubmit={values => handleEditMeal(values)}
            >
                {
                    ({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                    }) => {
                        const {
                            orderId,
                            enName,
                            bgName,
                            mealType,
                            netWeight,
                            price,
                            enIngredients,
                            bgIngredients,
                            isNew,
                            quantityType,
                        } = values;
                        return (
                            <form>
                                <Dialog fullWidth maxWidth='sm' open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                                    <DialogTitle id="form-dialog-title">Промяна на ястие</DialogTitle>
                                    <DialogContent>
                                        <div className={classes.input}>
                                            <TextField
                                                fullWidth
                                                margin='dense'
                                                name='orderId'
                                                value={orderId}
                                                variant='outlined'
                                                onBlur={handleBlur}
                                                label="Номер подредба"
                                                onChange={handleChange}
                                            />
                                            {
                                                errors.orderId && touched.orderId &&
                                                <ErrorMessage component='div' className='invalid-field-msg' name='orderId' />
                                            }
                                        </div>
                                        <div className={classes.input}>
                                            <FormControl fullWidth>
                                                <Select
                                                    margin='dense'
                                                    name='mealType'
                                                    value={mealType}
                                                    variant='outlined'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                >
                                                    {categories.map((x, i) => <MenuItem key={i} value={`${x.en}|${x.bg}`}>{x.bg}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                            {
                                                errors.mealType && touched.mealType &&
                                                <ErrorMessage component='div' className='invalid-field-msg' name='mealType' />
                                            }
                                        </div>
                                        <div className={classes.oneRowMultiInputs}>
                                            <div className={classes.multiInput}>
                                                <TextField
                                                    fullWidth
                                                    autoFocus
                                                    name='bgName'
                                                    margin='dense'
                                                    value={bgName}
                                                    variant='outlined'
                                                    onBlur={handleBlur}
                                                    label="Име (Български)"
                                                    onChange={handleChange}
                                                />
                                                {
                                                    errors.bgName && touched.bgName &&
                                                    <ErrorMessage component='div' className='invalid-field-msg' name='bgName' />
                                                }
                                            </div>
                                            <div className={classes.multiInput}>
                                                <TextField
                                                    fullWidth
                                                    name='enName'
                                                    margin='dense'
                                                    value={enName}
                                                    variant='outlined'
                                                    onBlur={handleBlur}
                                                    label="Име (Английски)"
                                                    onChange={handleChange}
                                                />
                                                {
                                                    errors.enName && touched.enName &&
                                                    <ErrorMessage component='div' className='invalid-field-msg' name='enName' />
                                                }
                                            </div>
                                        </div>
                                        <div className={classes.oneRowMultiInputs}>
                                            <div className={classes.multiInput}>
                                                <TextField
                                                    rows={3}
                                                    fullWidth
                                                    multiline
                                                    margin='dense'
                                                    variant='outlined'
                                                    onBlur={handleBlur}
                                                    name='bgIngredients'
                                                    value={bgIngredients}
                                                    onChange={handleChange}
                                                    label="Съставки (Български)"
                                                />
                                                {
                                                    errors.bgIngredients && touched.bgIngredients &&
                                                    <ErrorMessage component='div' className='invalid-field-msg' name='bgIngredients' />
                                                }
                                            </div>
                                            <div className={classes.multiInput}>
                                                <TextField
                                                    rows={3}
                                                    fullWidth
                                                    multiline
                                                    margin='dense'
                                                    variant='outlined'
                                                    onBlur={handleBlur}
                                                    name='enIngredients'
                                                    value={enIngredients}
                                                    onChange={handleChange}
                                                    label="Съставки (Английски)"
                                                />
                                                {
                                                    errors.enIngredients && touched.enIngredients &&
                                                    <ErrorMessage component='div' className='invalid-field-msg' name='enIngredients' />
                                                }
                                            </div>
                                        </div>
                                        <div className={classes.oneRowMultiInputs}>
                                            <TextField
                                                margin='dense'
                                                name='netWeight'
                                                value={netWeight}
                                                variant='outlined'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                className={classes.multiInput}
                                                label={`Количество (${quantityType.bg})`}
                                            />
                                            {
                                                errors.netWeight && touched.netWeight &&
                                                <ErrorMessage component='div' className='invalid-field-msg' name='netWeight' />
                                            }
                                            <FormControl className={classes.multiInput}>
                                                <Select
                                                    margin='dense'
                                                    variant='outlined'
                                                    name='quantityType'
                                                    onBlur={handleBlur}
                                                    value={quantityType}
                                                    onChange={handleChange}
                                                >
                                                    {quantityTypes.map((x, i) => <MenuItem key={i} value={x}>{x.bg}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                            {
                                                errors.quantityType && touched.quantityType &&
                                                <ErrorMessage component='div' className='invalid-field-msg' name='quantityType' />
                                            }
                                        </div>
                                        <div className={classes.input}>
                                            <TextField
                                                fullWidth
                                                name='price'
                                                value={price}
                                                margin='dense'
                                                variant='outlined'
                                                label="Цена (лв.)"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            {
                                                errors.price && touched.price &&
                                                <ErrorMessage component='div' className='invalid-field-msg' name='price' />
                                            }
                                        </div>
                                        <div className={classes.input}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name='isNew'
                                                        checked={isNew}
                                                        color="primary"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                }
                                                label="Ново предложение"
                                            />
                                            {
                                                errors.isNew && touched.isNew &&
                                                <ErrorMessage component='div' className='invalid-field-msg' name='isNew' />
                                            }
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleClose} variant='outlined' color="secondary">
                                            Отказ
                                        </Button>
                                        <Button type='submit' onClick={handleSubmit} variant='outlined' color="primary">
                                            Промени
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </form>
                        )
                    }
                }
            </Formik>
        </div>
    );
}

const mapStateToProps = state => ({
    categories: state.templates.teatroMenuCategories,
})

const mapDispatchToProps = {
    showNotification,
    getTeatroMenuCategories,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditMealDialog));
