import {
    GET_ORANGE_SPECIAL_OFFERS,
    INSERT_ORANGE_SPECIAL_OFFER,
    UPDATE_ORANGE_SPECIAL_OFFER,
    DELETE_ORANGE_SPECIAL_OFFER,
} from "../actionTypes";

const orangeOffersInitialState = {
    all: [],
}

export const orangeOffersReducer = (state = orangeOffersInitialState, action) => {
    switch (action.type) {
        case GET_ORANGE_SPECIAL_OFFERS: return {
            ...state,
            all: action.payload,
        }
        case INSERT_ORANGE_SPECIAL_OFFER: return {
            ...state,
            all: [...state.all, action.payload],
        }
        case UPDATE_ORANGE_SPECIAL_OFFER: {
            const foundIndex = state.all.findIndex(x => x._id === action.payload._id);
            if (foundIndex !== -1) {
                const copy = state.all.slice();
                copy.splice(foundIndex, 1, action.payload);
                return {
                    ...state,
                    all: copy,
                }
            } else {
                return state;
            }
        }
        case DELETE_ORANGE_SPECIAL_OFFER: {
            const foundIndex = state.all.findIndex(x => x._id === action.payload);
            if (foundIndex !== -1) {
                const copy = state.all.slice();
                copy.splice(foundIndex, 1);
                return {
                    ...state,
                    all: copy,
                }
            } else {
                return state;
            }
        }
        default: return state;
    }
}
