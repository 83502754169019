import { ErrorMessage, Formik } from "formik";
import {
    Dialog,
    Button,
    TextField,
    withStyles,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
} from "@material-ui/core";
import { validateNotEmptyInputs, validateNumberInputs } from "../../../../../data/validations";

const styles = theme => ({
    input: {
        margin: '10px 0',
    },
})

const OrangeSpecialOfferEditDialog = ({
    open,
    offer,
    classes,
    handleClose,
    handleUpdate,
}) => {
    const handleEditClick = values => {
        const { enTitle, bgTitle, enDescription, bgDescription, price } = values;
        const offerObj = {
            ...offer,
            title: {
                en: enTitle,
                bg: bgTitle,
            },
            description: {
                en: enDescription,
                bg: bgDescription,
            },
            price: Number(price).toFixed(2),
        }
        handleUpdate(offerObj);
    }

    const handleCloseClick = () => handleClose();

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    enTitle: offer?.title?.en,
                    bgTitle: offer?.title?.bg,
                    enDescription: offer?.description?.en,
                    bgDescription: offer?.description?.bg,
                    price: offer?.price,
                }}
                validate={values => ({
                    ...validateNotEmptyInputs(['enTitle', 'bgTitle', 'enDescription', 'bgDescription', 'price'], values),
                    ...validateNumberInputs(['price'], values),
                })}
                onSubmit={values => handleEditClick(values)}
            >
                {
                    ({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                    }) => {
                        const { enTitle, bgTitle, enDescription, bgDescription, price } = values;
                        return (
                            <form>
                                <Dialog fullWidth maxWidth='sm' open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                                    <DialogTitle id="form-dialog-title">Създаване на нова оферта</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>Препоръка: попълнете всички полета</DialogContentText>
                                        <div className={classes.input}>
                                            <TextField
                                                fullWidth
                                                name='bgTitle'
                                                margin='dense'
                                                value={bgTitle}
                                                variant='outlined'
                                                onBlur={handleBlur}
                                                label="Име (Български)"
                                                onChange={handleChange}
                                            />
                                            {
                                                errors.bgTitle && touched.bgTitle &&
                                                <ErrorMessage component='div' name='bgTitle' className='invalid-field-msg' />
                                            }
                                        </div>
                                        <div className={classes.input}>
                                            <TextField
                                                rows={3}
                                                fullWidth
                                                multiline
                                                margin='dense'
                                                variant='outlined'
                                                onBlur={handleBlur}
                                                name='bgDescription'
                                                value={bgDescription}
                                                onChange={handleChange}
                                                label="Описание (Български)"
                                            />
                                            {
                                                errors.bgDescription && touched.bgDescription &&
                                                <ErrorMessage component='div' name='bgDescription' className='invalid-field-msg' />
                                            }
                                        </div>
                                        <div className={classes.input}>
                                            <TextField
                                                fullWidth
                                                name='enTitle'
                                                margin='dense'
                                                value={enTitle}
                                                variant='outlined'
                                                onBlur={handleBlur}
                                                label="Име (Английски)"
                                                onChange={handleChange}
                                            />
                                            {
                                                errors.enTitle && touched.enTitle &&
                                                <ErrorMessage component='div' name='enTitle' className='invalid-field-msg' />
                                            }
                                        </div>
                                        <div className={classes.input}>
                                            <TextField
                                                rows={3}
                                                multiline
                                                fullWidth
                                                margin='dense'
                                                variant='outlined'
                                                onBlur={handleBlur}
                                                name='enDescription'
                                                value={enDescription}
                                                onChange={handleChange}
                                                label="Описание (Английски)"
                                            />
                                            {
                                                errors.enDescription && touched.enDescription &&
                                                <ErrorMessage component='div' name='enDescription' className='invalid-field-msg' />
                                            }
                                        </div>
                                        <div className={classes.input}>
                                            <TextField
                                                fullWidth
                                                name='price'
                                                value={price}
                                                margin='dense'
                                                label="Цена (лв.)"
                                                variant='outlined'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            {
                                                errors.price && touched.price &&
                                                <ErrorMessage component='div' name='price' className='invalid-field-msg' />
                                            }
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseClick} variant='outlined' color="secondary">
                                            Отказ
                                        </Button>
                                        <Button type='submit' onClick={handleSubmit} variant='outlined' color="primary">
                                            Създай
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </form>
                        )
                    }
                }
            </Formik>
        </div>
    )
}

export default withStyles(styles)(OrangeSpecialOfferEditDialog);
