import {
    GET_TEATRO_MENU,
    GET_ORANGE_MENU,
    GET_TEATRO_LUNCH_MENUS,
    UPDATE_TEATRO_MENU_MEAL,
    ADD_MEAL_TO_TEATRO_MENU,
    DELETE_TEATRO_LUNCH_MENU,
    ADD_OFFER_TO_ORANGE_MENU,
    DELETE_MEAL_FROM_TEATRO_MENU,
    ADD_MEAL_TO_TEATRO_LUNCH_MENU,
    DELETE_OFFER_FROM_ORANGE_MENU,
    INSERT_BLANK_TEATRO_LUNCH_MENU,
    DELETE_MEAL_FROM_TEATRO_LUNCH_MENU,
    EDIT_OFFER_OF_ORANGE_MENU,
} from "../actionTypes";

const teatroMenuInitialState = {
    menu: {},
}

export const teatroMenuReducer = (state = teatroMenuInitialState, action) => {
    switch (action.type) {
        case GET_TEATRO_MENU: return {
            ...state,
            menu: action.payload,
        }
        case ADD_MEAL_TO_TEATRO_MENU: return {
            ...state,
            menu: { ...state.menu, meals: [...state.menu.meals, action.payload] },
        }
        case UPDATE_TEATRO_MENU_MEAL: {
            const foundIndex = state.menu.meals.findIndex(x => x._id === action.payload._id);
            if (foundIndex !== -1) {
                const copy = state.menu.meals.slice();
                copy.splice(foundIndex, 1, action.payload);
                return {
                    ...state,
                    menu: { ...state.menu, meals: copy },
                }
            } else {
                return state;
            }
        }
        case DELETE_MEAL_FROM_TEATRO_MENU: {
            const foundItem = state.menu.meals.find(x => x._id === action.payload);
            if (foundItem) {
                const index = state.menu.meals.indexOf(foundItem);
                const copy = state.menu.meals.slice();
                copy.splice(index, 1);
                return {
                    ...state,
                    menu: { ...state.menu, meals: copy },
                };
            } else {
                return state;
            }
        }
        default: return state;
    }
}

const teatroLunchMenuInitialState = {
    menus: [],
}

export const teatroLunchMenuReducer = (state = teatroLunchMenuInitialState, action) => {
    switch (action.type) {
        case GET_TEATRO_LUNCH_MENUS: return {
            ...state,
            menus: action.payload,
        }
        case INSERT_BLANK_TEATRO_LUNCH_MENU: {
            const newMenu = { ...action.payload, categories: [] };
            return {
                ...state,
                menus: [
                    ...state.menus,
                    newMenu,
                ]
            }
        }
        case DELETE_TEATRO_LUNCH_MENU: {
            const foundItem = state.menus.find(x => x._id === action.payload);
            if (foundItem) {
                const index = state.menus.indexOf(foundItem);
                const copy = state.menus.slice();
                copy.splice(index, 1);
                return {
                    ...state,
                    menus: copy,
                }
            } else {
                return state;
            }
        }
        case ADD_MEAL_TO_TEATRO_LUNCH_MENU: {
            const { menuId, meal } = action.payload;
            const menu = state.menus.find(x => x._id === menuId);
            if (menu) {
                const menuCopy = { ...menu };
                menuCopy.meals.push(meal);
                const index = state.menus.indexOf(menu);
                const stateCopy = state.menus.slice();
                stateCopy.splice(index, 1, menuCopy);
                return {
                    ...state,
                    menus: stateCopy,
                }
            } else {
                return state;
            }
        }
        case DELETE_MEAL_FROM_TEATRO_LUNCH_MENU: {
            const { menuId, mealId } = action.payload;
            const menu = state.menus.find(x => x._id === menuId);
            debugger;
            if (menu) {
                const menuCopy = { ...menu };
                const meal = menuCopy.meals.find(meal => meal._id === mealId);
                if (meal) {
                    const mealIndex = menuCopy.meals.indexOf(meal);
                    menuCopy.meals.splice(mealIndex, 1);
                    const menuIndex = state.menus.indexOf(menu);
                    const stateCopy = state.menus.slice();
                    stateCopy.splice(menuIndex, 1, menuCopy);
                    return {
                        ...state,
                        menus: stateCopy,
                    }
                } else {
                    return state;
                }
            } else {
                return state;
            }
        }
        default: return state;
    }
}

const orangeMenuInitialState = {
    menu: {},
}

export const orangeMenuReducer = (state = orangeMenuInitialState, action) => {
    switch (action.type) {
        case GET_ORANGE_MENU: return {
            ...state,
            menu: action.payload,
        }
        case ADD_OFFER_TO_ORANGE_MENU: return {
            ...state,
            menu: { ...state.menu, offers: [...state.menu.offers, action.payload] },
        }
        case EDIT_OFFER_OF_ORANGE_MENU: {
            const foundItem = state.menu.offers.find(x => x._id === action.payload._id);
            if (foundItem) {
                const index = state.menu.offers.indexOf(foundItem);
                const copy = state.menu.offers.slice();
                copy.splice(index, 1, action.payload);
                return {
                    ...state,
                    menu: { ...state.menu, offers: copy },
                }
            } else {
                return state;
            }
        }
        case DELETE_OFFER_FROM_ORANGE_MENU: {
            const foundItem = state.menu.offers.find(x => x._id === action.payload);
            if (foundItem) {
                const index = state.menu.offers.indexOf(foundItem);
                const copy = state.menu.offers.slice();
                copy.splice(index, 1);
                return {
                    ...state,
                    menu: { ...state.menu, offers: copy },
                }
            } else {
                return state;
            }
        }
        default: return state;
    }
}
