import React from "react";
import { Fade } from 'react-reveal';
import { connect } from "react-redux";
import Gallery from "react-photo-gallery";

import { Box, Container, Link, Typography, withStyles } from "@material-ui/core";

import { constants, corporatePhoneTeatro } from "../../../data/constants";
import { getLang } from "../../../data/reducers/pageReducer";

import withRoot from "./modules/withRoot";
import AppAppBar from "./modules/views/AppAppBar";
import { cateringPhotos } from "./TeatroGalleryPhotos";

const styles = {
    header: {
        margin: '50px 0',
        ['@media (max-width:750px)']: { // eslint-disable-line no-useless-computed-key
            margin: '30px 0',
            fontSize: '25px',
        }
    },
    gallery: {
        margin: '30px 0',
    },
    contactPhone: {
        color: 'red'
    },
}

const TeatroCatering = ({
    lang,
    classes,
}) => {
    const cateringText1 = {
        en: 'Try out a beautiful and delicious catering snacks from our catering menu.',
        bg: 'Насладете се на красиви и вкусни кетеринг хапки от нашето кетеринг меню.',
    }
    const cateringText2 = {
        en: 'Contact us for orders or more informaction on phone ',
        bg: 'За поръчки или въпроси - свържете се с нас на телефон ',
    }
    const photos = cateringPhotos.map(x => ({ src: x, width: 1, height: 1 }));
    return (
        <React.Fragment>
            <AppAppBar />
            <Container>
                <Box sx={{ mt: 7, mb: 12 }}>
                    <Fade top>
                        <Typography className={classes.header} variant="h3" gutterBottom align="center">
                            {constants.heading.teatro}{' - '}
                            {lang === 'bg' ? 'Кетеринг' : 'Catering'}
                        </Typography>
                    </Fade>
                    <Fade bottom>
                        <Typography variant='h5' align='center'>
                            {cateringText1[lang]}
                        </Typography>
                        <Typography variant='h5' align='center'>
                            {cateringText2[lang]}
                            <Link className={classes.contactPhone} href={`tel:${corporatePhoneTeatro}`}>
                                {corporatePhoneTeatro}
                            </Link>
                        </Typography>
                    </Fade>
                    <div className={classes.gallery}>
                        <Gallery photos={photos} />
                    </div>
                </Box>
            </Container>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    lang: getLang(state),
})

export default connect(mapStateToProps)(withStyles(styles)(withRoot(TeatroCatering)));
