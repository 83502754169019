import l_catering1 from '../../../assets/photos/teatro/catering/landscape/1.jpg';
import l_catering2 from '../../../assets/photos/teatro/catering/landscape/2.jpg';
import l_catering3 from '../../../assets/photos/teatro/catering/landscape/3.jpg';
import l_catering4 from '../../../assets/photos/teatro/catering/landscape/4.jpg';
import p_catering1 from '../../../assets/photos/teatro/catering/portrait/1.jpg';
import p_catering2 from '../../../assets/photos/teatro/catering/portrait/2.jpg';

import p_decoration1 from '../../../assets/photos/teatro/decorations/portrait/1.jpg';
import p_decoration2 from '../../../assets/photos/teatro/decorations/portrait/2.jpg';
import p_decoration3 from '../../../assets/photos/teatro/decorations/portrait/3.jpg';
import p_decoration4 from '../../../assets/photos/teatro/decorations/portrait/4.jpg';

import l_dessert1 from '../../../assets/photos/teatro/desserts/landscape/1.jpg';
import l_dessert3 from '../../../assets/photos/teatro/desserts/landscape/3.jpg';
import l_dessert4 from '../../../assets/photos/teatro/desserts/landscape/4.jpg';
import l_dessert5 from '../../../assets/photos/teatro/desserts/landscape/5.jpg';
import l_dessert6 from '../../../assets/photos/teatro/desserts/landscape/6.jpg';
import l_dessert8 from '../../../assets/photos/teatro/desserts/landscape/8.jpg';
import l_dessert9 from '../../../assets/photos/teatro/desserts/landscape/9.jpg';
import l_dessert10 from '../../../assets/photos/teatro/desserts/landscape/10.jpg';
import p_dessert1 from '../../../assets/photos/teatro/desserts/portrait/1.jpg';
import p_dessert3 from '../../../assets/photos/teatro/desserts/portrait/3.jpg';
import p_dessert4 from '../../../assets/photos/teatro/desserts/portrait/4.jpg';
import p_dessert5 from '../../../assets/photos/teatro/desserts/portrait/5.jpg';
import p_dessert6 from '../../../assets/photos/teatro/desserts/portrait/6.jpg';
import p_dessert8 from '../../../assets/photos/teatro/desserts/portrait/8.jpg';
import p_dessert9 from '../../../assets/photos/teatro/desserts/portrait/9.jpg';

import l_drink1 from '../../../assets/photos/teatro/drinks/landscape/1.jpg';
import l_drink2 from '../../../assets/photos/teatro/drinks/landscape/2.jpg';
import l_drink3 from '../../../assets/photos/teatro/drinks/landscape/3.jpg';
import l_drink4 from '../../../assets/photos/teatro/drinks/landscape/4.jpg';
import l_drink5 from '../../../assets/photos/teatro/drinks/landscape/5.jpg';
import l_drink6 from '../../../assets/photos/teatro/drinks/landscape/6.jpg';
import l_drink7 from '../../../assets/photos/teatro/drinks/landscape/7.jpg';
import p_drink1 from '../../../assets/photos/teatro/drinks/portrait/1.jpg';
import p_drink2 from '../../../assets/photos/teatro/drinks/portrait/2.jpg';
import p_drink3 from '../../../assets/photos/teatro/drinks/portrait/3.jpg';
import p_drink4 from '../../../assets/photos/teatro/drinks/portrait/4.jpg';
import p_drink5 from '../../../assets/photos/teatro/drinks/portrait/5.jpg';
import p_drink6 from '../../../assets/photos/teatro/drinks/portrait/6.jpg';
import p_drink7 from '../../../assets/photos/teatro/drinks/portrait/7.jpg';
import p_drink8 from '../../../assets/photos/teatro/drinks/portrait/8.jpg';
import p_drink9 from '../../../assets/photos/teatro/drinks/portrait/9.jpg';
import p_drink10 from '../../../assets/photos/teatro/drinks/portrait/10.jpg';

import l_meal1 from '../../../assets/photos/teatro/meals/landscape/1.jpg';
import l_meal2 from '../../../assets/photos/teatro/meals/landscape/2.jpg';
import l_meal3 from '../../../assets/photos/teatro/meals/landscape/3.jpg';
import l_meal4 from '../../../assets/photos/teatro/meals/landscape/4.jpg';
import l_meal5 from '../../../assets/photos/teatro/meals/landscape/5.jpg';
import l_meal6 from '../../../assets/photos/teatro/meals/landscape/6.jpg';
import l_meal7 from '../../../assets/photos/teatro/meals/landscape/7.jpg';
import l_meal8 from '../../../assets/photos/teatro/meals/landscape/8.jpg';
import l_meal9 from '../../../assets/photos/teatro/meals/landscape/9.jpg';
import p_meal1 from '../../../assets/photos/teatro/meals/portrait/1.jpg';
import p_meal2 from '../../../assets/photos/teatro/meals/portrait/2.jpg';
import p_meal3 from '../../../assets/photos/teatro/meals/portrait/3.jpg';
import p_meal4 from '../../../assets/photos/teatro/meals/portrait/4.jpg';
import p_meal5 from '../../../assets/photos/teatro/meals/portrait/5.jpg';
import p_meal6 from '../../../assets/photos/teatro/meals/portrait/6.jpg';
import p_meal7 from '../../../assets/photos/teatro/meals/portrait/7.jpg';
import p_meal8 from '../../../assets/photos/teatro/meals/portrait/8.jpg';
import p_meal9 from '../../../assets/photos/teatro/meals/portrait/9.jpg';
import p_meal10 from '../../../assets/photos/teatro/meals/portrait/10.jpg';
import p_meal11 from '../../../assets/photos/teatro/meals/portrait/11.jpg';
import p_meal12 from '../../../assets/photos/teatro/meals/portrait/12.jpg';
import p_meal13 from '../../../assets/photos/teatro/meals/portrait/13.jpg';
import p_meal14 from '../../../assets/photos/teatro/meals/portrait/14.jpg';
import p_meal15 from '../../../assets/photos/teatro/meals/portrait/15.jpg';
import p_meal16 from '../../../assets/photos/teatro/meals/portrait/16.jpg';

import l_premeal1 from '../../../assets/photos/teatro/pre-meals/landscape/1.jpg';
import l_premeal2 from '../../../assets/photos/teatro/pre-meals/landscape/2.jpg';
import l_premeal3 from '../../../assets/photos/teatro/pre-meals/landscape/3.jpg';
import l_premeal4 from '../../../assets/photos/teatro/pre-meals/landscape/4.jpg';
import p_premeal1 from '../../../assets/photos/teatro/pre-meals/portrait/1.jpg';
import p_premeal3 from '../../../assets/photos/teatro/pre-meals/portrait/3.jpg';
import p_premeal4 from '../../../assets/photos/teatro/pre-meals/portrait/4.jpg';
import p_premeal5 from '../../../assets/photos/teatro/pre-meals/portrait/5.jpg';

import l_salad1 from '../../../assets/photos/teatro/salads/landscape/1.jpg';
import l_salad2 from '../../../assets/photos/teatro/salads/landscape/2.jpg';
import p_salad1 from '../../../assets/photos/teatro/salads/portrait/1.jpg';
import p_salad2 from '../../../assets/photos/teatro/salads/portrait/2.jpg';
import p_salad3 from '../../../assets/photos/teatro/salads/portrait/3.jpg';

import l_soup1 from '../../../assets/photos/teatro/soups/landscape/1.jpg';
import p_soup1 from '../../../assets/photos/teatro/soups/portrait/1.jpg';
import p_soup2 from '../../../assets/photos/teatro/soups/portrait/2.jpg';

import l_sushi1 from '../../../assets/photos/teatro/sushi/landscape/1.jpg';
import l_sushi2 from '../../../assets/photos/teatro/sushi/landscape/2.jpg';
import l_sushi3 from '../../../assets/photos/teatro/sushi/landscape/3.jpg';
import p_sushi1 from '../../../assets/photos/teatro/sushi/portrait/1.jpg';

export const landscapeCatering = [l_catering1, l_catering2, l_catering3, l_catering4]
export const portraitCatering = [p_catering1, p_catering2]

export const portraitDescoration = [p_decoration1, p_decoration2, p_decoration3, p_decoration4]

export const landscapeDessert = [
    l_dessert1, l_dessert3, l_dessert4, l_dessert5,
    l_dessert6, l_dessert8, l_dessert9, l_dessert10,
]
export const portraitDessert = [
    p_dessert1, p_dessert3, p_dessert4,
    p_dessert5, p_dessert6, p_dessert8, p_dessert9,
]

export const landscapeDrink = [l_drink1, l_drink2, l_drink3, l_drink4, l_drink5, l_drink6, l_drink7]
export const portraitDrink = [
    p_drink1, p_drink2, p_drink3, p_drink4, p_drink5,
    p_drink6, p_drink7, p_drink8, p_drink9, p_drink10,
]

export const landscapeMeal = [
    l_meal1, l_meal2, l_meal3, l_meal4, l_meal5, l_meal6, l_meal7, l_meal8, l_meal9,
]
export const portraitMeal = [
    p_meal1, p_meal2, p_meal3, p_meal4, p_meal5, p_meal6, p_meal7, p_meal8,
    p_meal9, p_meal10, p_meal11, p_meal12, p_meal13, p_meal14, p_meal15, p_meal16,
];

export const landscapePremeal = [l_premeal1, l_premeal2, l_premeal3, l_premeal4]
export const portraitPremeal = [p_premeal1, p_premeal3, p_premeal4, p_premeal5]

export const landscapeSalad = [l_salad1, l_salad2]
export const portraitSalad = [p_salad1, p_salad2, p_salad3]

export const landscapeSoup = [l_soup1]
export const portraitSoup = [p_soup1, p_soup2]

export const landscapeSushi = [l_sushi1, l_sushi2, l_sushi3]
export const portraitSushi = [p_sushi1]

export const allPhotos = [
    ...landscapeDrink,
    ...portraitDrink,
    ...landscapeSoup,
    ...portraitSoup,
    ...landscapeSalad,
    ...portraitSalad,
    ...landscapePremeal,
    ...portraitPremeal,
    ...landscapeMeal,
    ...portraitMeal,
    ...landscapeSushi,
    ...portraitSushi,
    ...landscapeDessert,
    ...portraitDessert,
]

export const cateringPhotos = [
    ...landscapeCatering,
    ...portraitCatering,
]
