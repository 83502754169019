import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Table,
    Tooltip,
    TableRow,
    TableBody,
    TableHead,
    TableCell,
    TextField,
    Typography,
    withStyles,
    IconButton,
} from "@material-ui/core"

import {
    addTeatroLunchMenuCategory,
    getTeatroLunchMenuCategories,
    deleteTeatroLunchMenuCategory,
} from "../../../../../../data/actions/templatesActions";

import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';

import { styles } from './teatroLunchMenuCategoriesConstants';

const TeatroLunchMenuCategories = ({
    classes,
    categories,
    addTeatroLunchMenuCategory,
    getTeatroLunchMenuCategories,
    deleteTeatroLunchMenuCategory,
}) => {
    const [categoryEn, setCategoryEn] = useState('');
    const [categoryBg, setCategoryBg] = useState('');

    useEffect(() => {
        getTeatroLunchMenuCategories();
    }, [getTeatroLunchMenuCategories]);

    const handleCategoryEnChange = ev => setCategoryEn(ev.target.value);
    const handleCategoryBgChange = ev => setCategoryBg(ev.target.value);

    const handleDelete = id => deleteTeatroLunchMenuCategory(id);
    const handleAdd = () => {
        addTeatroLunchMenuCategory({ en: categoryEn, bg: categoryBg });
        setCategoryEn('');
        setCategoryBg('');
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Typography className={classes.tableHeader}>
                            Име на тип (Английски)
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography className={classes.tableHeader}>
                            Име на тип (Български)
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography className={classes.tableHeader}>
                            Действия
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <TextField
                            size='small'
                            color='primary'
                            variant='outlined'
                            value={categoryEn}
                            label='Тип (Английски)'
                            onChange={handleCategoryEnChange}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            size='small'
                            color='primary'
                            variant='outlined'
                            value={categoryBg}
                            label='Тип (Български)'
                            onChange={handleCategoryBgChange}
                        />
                    </TableCell>
                    <TableCell>
                        <Tooltip title='Add'>
                            <IconButton
                                size='small'
                                color='primary'
                                onClick={handleAdd}
                            >
                                <AddBoxOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                </TableRow>
                {
                    categories && categories.map((x, i) => {
                        return (
                            <TableRow key={i} className={classes.disabledRow}>
                                <TableCell>
                                    <TextField
                                        disabled
                                        size='small'
                                        label={x.en}
                                        color='primary'
                                        variant='outlined'
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        disabled
                                        size='small'
                                        label={x.bg}
                                        color='primary'
                                        variant='outlined'
                                    />
                                </TableCell>
                                <TableCell>
                                    <Tooltip title='Delete'>
                                        <IconButton
                                            size='small'
                                            color='secondary'
                                            onClick={() => handleDelete(x._id)}
                                        >
                                            <DeleteForeverOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        )
                    })
                }
            </TableBody>
        </Table>
    )
}

const mapStateToProps = state => ({
    categories: state.templates.teatroLunchMenuCategories,
})

const mapDispatchToProps = {
    addTeatroLunchMenuCategory,
    getTeatroLunchMenuCategories,
    deleteTeatroLunchMenuCategory,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TeatroLunchMenuCategories));
