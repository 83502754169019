export const CHANGE_PAGE_LANGAUGE = 'CHANGE_PAGE_LANGAUGE';

const changePageLanguageAction = newLang => ({
    type: CHANGE_PAGE_LANGAUGE,
    payload: newLang,
})

export const changePageLanguage = newLang => async dispatch => {
    try {
        const action = changePageLanguageAction(newLang);
        dispatch(action);
    } catch (err) {
        console.warn('changePageLanguage:', err);
    }
}
