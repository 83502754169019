export const styles = theme => ({
    settingsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    settingsWrapper: {
        width: '45%',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
    },
    paperWrapper: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'space-evenly',
    },
    settingsHeader: {
        width: '100%',
        marginBottom: '20px',
        textDecoration: 'underline',
    },
    paperHeader: {
        width: '100%',
        margin: '20px 0',
        textDecoration: 'underline',
    },
})
