import {
    Dialog,
    Button,
    Typography,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
} from '@material-ui/core';

const ConfirmDeleteOfferDialog = ({
    open,
    offer,
    handleClose,
    handleDelete,
}) => {
    const handleDeleteClick = () => handleDelete(offer._id);
    return (
        <div>
            <Dialog fullWidth maxWidth='sm' open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Премахване на предложение</DialogTitle>
                <DialogContent>
                    <DialogContentText>Сигурни ли сте, че искате да изтриете следното предложение?</DialogContentText>
                    {
                        offer &&
                        <Typography variant='h6'>{offer.name.bg} - {Number(offer.price).toFixed(2)}лв.</Typography>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant='outlined' color="secondary">
                        Отказ
                    </Button>
                    <Button onClick={handleDeleteClick} variant='outlined' color="primary">
                        Изтрий
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ConfirmDeleteOfferDialog;
