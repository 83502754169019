import {
    Paper,
    Typography,
    withStyles,
} from "@material-ui/core"

import TeatroMenuCategories from "./TeatroMenuCategories/TeatroMenuCategories";
import TeatroLunchMenuCategories from "./TeatroLunchMenuCategories/TeatroLunchMenuCategories";

import { styles } from './teatroSettingsTabConstants';

const TeatroSettingsTab = ({
    classes,
}) => {
    return (
        <div className={classes.settingsContainer}>
            <Typography
                variant='h5'
                align='center'
                className={classes.settingsHeader}
            >
                Teatro Настройки
            </Typography>
            <div className={classes.paperWrapper}>
                <Paper elevation={3} className={classes.settingsWrapper}>
                    <Typography
                        variant='h6'
                        align='center'
                        className={classes.paperHeader}
                    >
                        Меню
                    </Typography>
                    <TeatroMenuCategories />
                </Paper>
                <Paper elevation={3} className={classes.settingsWrapper}>
                    <Typography
                        variant='h6'
                        align='center'
                        className={classes.paperHeader}
                    >
                        Обедно меню
                    </Typography>
                    <TeatroLunchMenuCategories />
                </Paper>
            </div>
        </div>
    )
}

export default withStyles(styles)(TeatroSettingsTab);
