import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Fade } from 'react-reveal';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';

import withRoot from './modules/withRoot';
import AppAppBar from "./modules/views/AppAppBar"
import Typography from './modules/components/Typography';
import TeatroEventCardClient from './modules/components/TeatroEventCardClient';

import { constants } from '../../../data/constants';
import { getLang } from '../../../data/reducers/pageReducer';
import { getTeatroEvents } from '../../../data/actions/eventActions';

const styles = ({
    header: {
        margin: '50px 0',
        ['@media (max-width:750px)']: { // eslint-disable-line no-useless-computed-key
            margin: '30px 0',
            fontSize: '25px',
        }
    },
});

const TeatroEvents = ({
    lang,
    events,
    classes,
    getTeatroEvents,
}) => {
    useEffect(() => {
        getTeatroEvents();
    }, [getTeatroEvents])

    const noEventsText = {
        en: 'Currently there is no events, you can expect something interesting very soon :)',
        bg: 'В момента няма очаквани събития, но може да очаквате нещо интересно скоро :)',
    }
    const futureEvents = events.filter(x => x.date > Date.now());
    return (
        <React.Fragment>
            <AppAppBar />
            <Container>
                <Box sx={{ mt: 7, mb: 12 }}>
                    <Fade top>
                        <Typography variant="h3" className={classes.header} gutterBottom align="center">
                            {constants.heading.teatro}{' - '}
                            {lang === 'bg' ? 'Събития' : 'Events'}
                        </Typography>
                    </Fade>
                    {
                        futureEvents.length > 0
                            ?
                            futureEvents.map(event => <TeatroEventCardClient lang={lang} event={event} key={event._id} />)
                            :
                            <Fade bottom>
                                <Typography align='center' variant='h5'>{noEventsText[lang]}</Typography>
                            </Fade>
                    }
                </Box>
            </Container>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    lang: getLang(state),
    events: state.teatroEvents.all,
})

const mapDispatchToProps = {
    getTeatroEvents,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRoot(TeatroEvents)));
