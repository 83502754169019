import { Paper, Typography, withStyles } from "@material-ui/core";
import OrangeMenuCategories from "./OrangeMenuCategories/OrangeMenuCategories";

import { styles } from './orangeSettingsTabConstants';

const OrangeSettingsTab = ({
    classes,
}) => {
    return (
        <div className={classes.settingsContainer}>
            <Typography
                variant='h5'
                align='center'
                className={classes.settingsHeader}
            >
                Orange Настройки
            </Typography>
            <div className={classes.paperWrapper}>
                <Paper elevation={3} className={classes.settingsWrapper}>
                    <Typography
                        variant='h6'
                        align='center'
                        className={classes.paperHeader}
                    >
                        Меню
                    </Typography>
                    <OrangeMenuCategories />
                </Paper>
            </div>
        </div>
    )
}

export default withStyles(styles)(OrangeSettingsTab);
