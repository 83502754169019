import { notificationTypes } from "../constants";
import { showNotification } from "./notificationActions";
import { hideApplicationLoader, showApplicationLoader } from "./applicationLoaderActions";
import {
    GET_ORANGE_SPECIAL_OFFERS,
    DELETE_ORANGE_SPECIAL_OFFER,
    INSERT_ORANGE_SPECIAL_OFFER,
    UPDATE_ORANGE_SPECIAL_OFFER,
} from "../actionTypes";
import { offersService } from "../../services/offersService";

const getOrangeSpecialOffersSuccess = offers => ({
    type: GET_ORANGE_SPECIAL_OFFERS,
    payload: offers,
})

export const getOrangeSpecialOffers = () => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        const result = await offersService.getOrangeOffers();
        const action = getOrangeSpecialOffersSuccess(result);
        dispatch(action);
    } catch (err) {
        console.log('getOrangeSpecialOffers:', err);
        dispatch(showNotification('could not get special offers', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

const insertOrangeSpecialOfferSuccess = offer => ({
    type: INSERT_ORANGE_SPECIAL_OFFER,
    payload: offer,
})

export const insertOrangeSpecialOffer = offer => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        const result = await offersService.insertOrangeOffer(offer);
        const action = insertOrangeSpecialOfferSuccess(result);
        dispatch(action);
        dispatch(showNotification('successfully created special offer', notificationTypes.success));
    } catch (err) {
        console.log('insertOrangeSpecialOffer:', err);
        dispatch(showNotification('could not create special offer', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

const updateOrangeSpecialOfferSuccess = offer => ({
    type: UPDATE_ORANGE_SPECIAL_OFFER,
    payload: offer,
})

export const updateOrangeSpecialOffer = offer => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        await offersService.updateOrangeOffer(offer);
        const action = updateOrangeSpecialOfferSuccess(offer);
        dispatch(action);
        dispatch(showNotification('successfully updated special offer', notificationTypes.success));
    } catch (err) {
        console.log('updateOrangeSpecialOffer:', err);
        dispatch(showNotification('could not update special offer', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

const deleteOrangeSpecialOfferSuccess = offerId => ({
    type: DELETE_ORANGE_SPECIAL_OFFER,
    payload: offerId,
})

export const deleteOrangeSpecialOffer = offerId => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        await offersService.deleteOrangeOffer(offerId);
        const action = deleteOrangeSpecialOfferSuccess(offerId);
        dispatch(action);
        dispatch(showNotification('successfully deleted special offer', notificationTypes.success));
    } catch (err) {
        console.log('deleteOrangeOffer:', err);
        dispatch(showNotification('could not delete special offer', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}
