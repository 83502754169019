import {
    Dialog,
    Button,
    Typography,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
} from '@material-ui/core';

const OrangeEventConfirmDeleteDialog = ({
    open,
    event,
    handleClose,
    handleDelete,
}) => {
    const handleDeleteClick = () => {
        handleDelete(event._id);
    }

    const formatDate = (date) => {
        const d = new Date(date);
        const hours = d.getHours().toString().length === 1 ? `0${d.getHours()}` : d.getHours();
        const minutes = d.getMinutes().toString().length === 1 ? `${d.getMinutes()}0` : d.getMinutes();
        return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()} - ${hours}:${minutes}`;
    }
    const date = formatDate(event.date);

    return (
        <div>
            <Dialog fullWidth maxWidth='sm' open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Премахване на събитие</DialogTitle>
                <DialogContent>
                    <DialogContentText>Сигурни ли сте, че искате да изтриете следното събитие?</DialogContentText>
                    {
                        event &&
                        <Typography variant='h6'>{event.title.bg} - {date}ч.</Typography>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant='outlined' color="secondary">
                        Отказ
                    </Button>
                    <Button onClick={handleDeleteClick} variant='outlined' color="primary">
                        Изтрий
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default OrangeEventConfirmDeleteDialog;
