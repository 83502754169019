import { LOG_USER, SIGN_OUT } from "../actionTypes";
import { notificationTypes } from "../constants";
import { history } from "../store";
import { hideApplicationLoader, showApplicationLoader } from "./applicationLoaderActions";
import { showNotification } from "./notificationActions";

export const renewSession = () => dispatch => {
    const action = getUserSuccess(validUser());
    dispatch(action);
}

const validUser = () => ({
    name: 'admin123',
    pass: 'teatro-orange-admin-123',
    uid: 'valid_uid',
    date: Date.now(),
})

const getUserSuccess = user => ({
    type: LOG_USER,
    payload: user,
})

export const getUser = (username, password) => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        const user = validUser();
        if (username === user.name && password === user.pass) {
            const action = getUserSuccess(user);
            dispatch(action);
            dispatch(showNotification('successfully signed in', notificationTypes.success));
            history.push('/admin');
            return true;
        } else {
            dispatch(showNotification('wrong username or password', notificationTypes.error));
            return false;
        }
    } catch (err) {
        console.warn('getUser:', err);
        dispatch(showNotification('could not sign in', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

export const signOutAction = () => ({
    type: SIGN_OUT,
})

export const signOut = () => async dispatch => dispatch(signOutAction());
