import { notificationTypes } from "../constants";
import { showNotification } from "./notificationActions";
import { templatesService } from "../../services/templatesService";
import { hideApplicationLoader, showApplicationLoader } from "./applicationLoaderActions"
import {
    ADD_ORANGE_MENU_CATEGORY,
    ADD_TEATRO_MENU_CATEGORY,
    GET_ORANGE_MENU_CATEGORIES,
    GET_TEATRO_MENU_CATEGORIES,
    DELETE_TEATRO_MENU_CATEGORY,
    DELETE_ORANGE_MENU_CATEGORY,
    ADD_TEATRO_LUNCH_MENU_CATEGORY,
    GET_TEATRO_LUNCH_MENU_CATEGORIES,
    DELETE_TEATRO_LUNCH_MENU_CATEGORY,
} from "../actionTypes";

const getTeatroLunchMenuCategoriesSuccess = categories => ({
    type: GET_TEATRO_LUNCH_MENU_CATEGORIES,
    payload: categories,
})

const addTeatroLunchMenuCategorySuccess = category => ({
    type: ADD_TEATRO_LUNCH_MENU_CATEGORY,
    payload: category,
})

const deleteTeatroLunchMenuCategorySuccess = id => ({
    type: DELETE_TEATRO_LUNCH_MENU_CATEGORY,
    payload: id,
})

const getTeatroMenuCategoriesSuccess = categories => ({
    type: GET_TEATRO_MENU_CATEGORIES,
    payload: categories,
})

const addTeatroMenuCategorySuccess = category => ({
    type: ADD_TEATRO_MENU_CATEGORY,
    payload: category,
})

const deleteTeatroMenuCategorySuccess = id => ({
    type: DELETE_TEATRO_MENU_CATEGORY,
    payload: id,
})

const getOrangeMenuCategoriesSuccess = categories => ({
    type: GET_ORANGE_MENU_CATEGORIES,
    payload: categories,
})

const addOrangeMenuCategorySuccess = category => ({
    type: ADD_ORANGE_MENU_CATEGORY,
    payload: category,
})

const deleteOrangeMenuCategorySuccess = id => ({
    type: DELETE_ORANGE_MENU_CATEGORY,
    payload: id,
})

export const getTeatroLunchMenuCategories = () => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        const result = await templatesService.getTeatroLunchMenuCategories();
        const action = getTeatroLunchMenuCategoriesSuccess(result);
        dispatch(action);
    } catch (err) {
        dispatch(showNotification('could not get teatro lunch menu categories', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

export const addTeatroLunchMenuCategory = category => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        const result = await templatesService.insertTeatroLunchMenuCategory(category);
        const action = addTeatroLunchMenuCategorySuccess(result);
        dispatch(action);
        dispatch(showNotification('successfully added category', notificationTypes.success));
    } catch (err) {
        dispatch(showNotification('could not add teatro lunch menu category', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

export const deleteTeatroLunchMenuCategory = id => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        await templatesService.deleteTeatroLunchMenuCategory(id);
        const action = deleteTeatroLunchMenuCategorySuccess(id);
        dispatch(action);
        dispatch(showNotification('successfully deleted category', notificationTypes.success));
    } catch (err) {
        dispatch(showNotification('could not delete teatro lunch menu category', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

export const getTeatroMenuCategories = () => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        const result = await templatesService.getTeatroMenuCategories();
        const action = getTeatroMenuCategoriesSuccess(result);
        dispatch(action);
    } catch (err) {
        dispatch(showNotification('could not get teatro menu categories', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

export const addTeatroMenuCategory = category => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        const result = await templatesService.insertTeatroMenuCategory(category);
        const action = addTeatroMenuCategorySuccess(result);
        dispatch(action);
        dispatch(showNotification('successfully added category', notificationTypes.success));
    } catch (err) {
        dispatch(showNotification('could not add teatro menu category', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

export const deleteTeatroMenuCategory = id => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        await templatesService.deleteTeatroMenuCategory(id);
        const action = deleteTeatroMenuCategorySuccess(id);
        dispatch(action);
        dispatch(showNotification('successfully deleted category', notificationTypes.success));
    } catch (err) {
        dispatch(showNotification('could not delete teatro menu category', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

export const getOrangeMenuCategories = () => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        const result = await templatesService.getOrangeMenuCategories();
        const action = getOrangeMenuCategoriesSuccess(result);
        dispatch(action);
    } catch (err) {
        dispatch(showNotification('could not get orange menu categories', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

export const addOrangeMenuCategory = category => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        const result = await templatesService.insertOrangeMenuCategory(category);
        const action = addOrangeMenuCategorySuccess(result);
        dispatch(action);
        dispatch(showNotification('successfully added category', notificationTypes.success));
    } catch (err) {
        dispatch(showNotification('could not add orange menu category', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

export const deleteOrangeMenuCategory = id => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        await templatesService.deleteOrangeMenuCategory(id);
        const action = deleteOrangeMenuCategorySuccess(id);
        dispatch(action);
        dispatch(showNotification('successfully deleted category', notificationTypes.success));
    } catch (err) {
        dispatch(showNotification('could not delete orange menu category', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }    
}
