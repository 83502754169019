import { Paper, Typography } from "@material-ui/core";
import { Fade } from 'react-reveal';

const styles = {
    paper: {
        width: '80%',
        margin: '20px 0',
        padding: '10px 20px',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '120px',
        justifyContent: 'space-between',
    },
    cardTop: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    cardBottom: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardBottomLeft: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    bottomLeftText: {
        marginRight: '10px',
    },
    date: {
        color: '#00c92b',
    },
}

const OrangeSpecialOfferCardClient = ({
    lang,
    offer,
}) => {
    const priceText = lang === 'bg'
        ?
        `Цена: ${offer.price && offer.price !== '0' ? `${offer.price} лв.` : 'Няма'}`
        :
        `Price: ${offer.price && offer.price !== '0' ? `${offer.price} bgn` : 'None'}`;
    return (
        <Paper style={styles.paper} elevation={3}>
            <Fade top>
                <div style={styles.cardTop}>
                    <Typography variant='h6'>{offer.title[lang]}</Typography>
                </div>
                <div style={styles.cardContent}>
                    <Typography variant='caption'>{offer.description[lang]}</Typography>
                </div>
            </Fade>
            <Fade bottom>
                <div style={styles.cardBottom}>
                    <div style={styles.cardBottomLeft}></div>
                    <div>
                        <Typography variant='h6'>{priceText}</Typography>
                    </div>
                </div>
            </Fade>
        </Paper>
    )
}

export default OrangeSpecialOfferCardClient;
