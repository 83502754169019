export const styles = theme => ({
    menuContainer: {
        margin: '20px 0',
    },
    menuHeader: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    addMealIcon: {
        margin: '10px 10px 5px 0'
    },
})
