import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Chip,
    Paper,
    Link,
    Button,
    Tooltip,
    IconButton,
    withStyles,
} from "@material-ui/core";
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';

import { getLang } from '../../../../../data/reducers/pageReducer';
import {
    getTeatroLunchMenus,
    deleteTeatroLunchMenu,
    addMealToTeatroLunchMenu,
    insertBlankTeatroLunchMenu,
    deleteMealFromTeatroLunchMenu,
} from "../../../../../data/actions/menuActions";
import { showNotification } from "../../../../../data/actions/notificationActions";

import { clientTeatroMenuUrl, domainName, formatDate, notificationTypes } from "../../../../../data/constants";
import CustomTable from "../../../../../shared/components/CustomTable";
import AddNewMealDialog from "./TeatroLunchMenuDialogs/AddNewMealDialog";
import AddNewMenuDialog from "./TeatroLunchMenuDialogs/AddNewMenuDialog";
import ConfirmDeleteMenuDialog from "./TeatroLunchMenuDialogs/ConfirmDeleteMenuDialog";

import { styles } from './teatroLunchMenuConstants';
import { sendLunchMenuToSubscribers } from "../../../../../data/actions/subscribeActions";

const columns = [
    { title: 'Ред', propName: 'orderId' },
    { title: 'Име', propName: 'name' },
    { title: 'Порция', propName: 'netWeight', suffix: item => item.isDrink ? ' мл.' : ' гр.' },
    { title: 'Цена', propName: 'price', suffix: () => ' лв.' },
    { title: 'Тип', propName: 'type' },
    { title: '', propName: 'actions' },
]

const TeatroLunchMenuTab = ({
    deleteMealFromTeatroLunchMenu,
    insertBlankTeatroLunchMenu,
    sendLunchMenuToSubscribers,
    addMealToTeatroLunchMenu,
    deleteTeatroLunchMenu,
    getTeatroLunchMenus,
    showNotification,
    classes,
    menus,
    lang,
}) => {
    const [selectedMenu, setSelectedMenu] = useState('');
    const [openAddNewMealDialog, setOpenAddNewMealDialog] = useState(false);
    const [openAddNewMenuDialog, setOpenAddNewMenuDialog] = useState(false);
    const [openConfirmDeleteMenuDialog, setOpenConfirmDeleteMenuDialog] = useState(false);

    useEffect(() => {
        getTeatroLunchMenus();
    }, [getTeatroLunchMenus]);

    const handleAddNewTeatroLunchMenu = (menu) => {
        insertBlankTeatroLunchMenu(menu);
        handleCloseAddNewMenuDialog();
    }

    const handleAddMenuMeal = (menuId, meal) => {
        addMealToTeatroLunchMenu(menuId, meal);
        handleCloseAddNewMealDialog();
    }

    const handleDeleteMealClick = (menuId, mealId) => deleteMealFromTeatroLunchMenu(menuId, mealId);

    const handleDeleteMenuClick = () => {
        deleteTeatroLunchMenu(selectedMenu._id);
        handleCloseConfirmDeleteMenuDialog();
    }

    const handleOpenAddNewMealDialog = (menu) => {
        setSelectedMenu(menu);
        setOpenAddNewMealDialog(true);
    }
    const handleCloseAddNewMealDialog = () => setOpenAddNewMealDialog(false);

    const handleOpenAddNewMenuDialog = () => setOpenAddNewMenuDialog(true);
    const handleCloseAddNewMenuDialog = () => setOpenAddNewMenuDialog(false);

    const handleOpenConfirmDeleteMenuDialog = (menu) => {
        setSelectedMenu(menu);
        setOpenConfirmDeleteMenuDialog(true);
    }
    const handleCloseConfirmDeleteMenuDialog = () => {
        setSelectedMenu('');
        setOpenConfirmDeleteMenuDialog(false);
    }

    const getTodaysMenu = () => {
        const now = new Date();
        return menus.find(x => new Date(x.date).getDate() === now.getDate());
    }

    const generateTextMenu = () => {
        const now = new Date();
        const menu = getTodaysMenu();
        if (!menu) {
            return '';
        }
        
        const sortedMeals = menu ? menu.meals.sort((a, b) => a.orderId - b.orderId) : [];
        const dateFormatted = `${now.getDate()}.${now.getMonth() + 1}.${now.getFullYear()}`;

        const rows = [`ДНЕВНО МЕНЮ - ${dateFormatted}\r\n`];
        sortedMeals.forEach(meal => {
            const mealRow = `${meal.name.bg} (${meal.ingredients.bg}), ~${meal.netWeight}гр. - ${meal.price}лв.`;
            rows.push(mealRow);
        })
        rows.push('\r\nБезплатна доставка!\r\n\r\n');

        rows.push(`DAILY MENU - ${dateFormatted}\r\n`);
        sortedMeals.forEach(meal => {
            const mealRow = `${meal.name.en} (${meal.ingredients.en}), ~${meal.netWeight}gr. - ${meal.price}bgn`;
            rows.push(mealRow);
        })
        rows.push('\r\nFree delivery!');

        const result = rows.join('\r\n');
        return result;
    }

    const handleSendToSubscribers = () => {
        const menu = getTodaysMenu();
        if (menu) {
            sendLunchMenuToSubscribers(menu.meals);
        } else {
            showNotification('no available lunch menu for today', notificationTypes.error);
        }
    }

    const handleMenuContentsCopy = () => {
        const text = generateTextMenu();
        if (!text) {
            showNotification('no available lunch menu for today', notificationTypes.error);
            return;
        }

        const el = document.createElement('textarea');
        el.innerHTML = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        showNotification('successfully copied', notificationTypes.success);
    }

    const actions = (item) => [
        <IconButton
            key={1}
            color='secondary'
            onClick={() => handleDeleteMealClick(item.menuId, item._id)}
        >
            <Tooltip title='Изтрий'>
                <DeleteForeverOutlinedIcon />
            </Tooltip>
        </IconButton>
    ]

    return (
        <div>
            <Button
                size='small'
                color='primary'
                variant='outlined'
                className={classes.topActionButton}
            >
                <div
                    data-href={clientTeatroMenuUrl}
                    data-layout="button"
                    data-size="large"
                >
                    <Link
                        target="_blank"
                        rel='noreferrer'
                        className="fb-xfbml-parse-ignore"
                        href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2F${domainName}%2Fteatro%2Fmenu%2F&amp;src=sdkpreparse`}
                    >
                        Сподели във фейсбук
                    </Link>
                </div>
            </Button>
            <Button
                size='small'
                color='primary'
                variant='outlined'
                onClick={handleMenuContentsCopy}
                className={classes.topActionButton}
            >
                Копирай съдържание на меню
            </Button>
            <Button
                size='small'
                color='primary'
                variant='outlined'
                onClick={handleSendToSubscribers}
                className={classes.topActionButton}
            >
                Изпрати на абонатите
            </Button>
            <div className={classes.addNewMenuWrapper}>
                <IconButton onClick={handleOpenAddNewMenuDialog}>
                    <Tooltip title='Създай ново меню'>
                        <AddToPhotosOutlinedIcon fontSize='large' />
                    </Tooltip>
                </IconButton>
            </div>
            {
                menus.map((menuItem, i) => {
                    const mealsWithMenuId = menuItem
                        ?.meals
                        ?.map(meal => ({ ...meal, menuId: menuItem._id }))
                        ?.sort((a, b) => a.orderId - b.orderId);
                    return (
                        <Paper
                            key={menuItem._id}
                            className={classes.menuContainer}
                        >
                            <div className={classes.menuHeader}>
                                <Chip
                                    color="primary"
                                    className={classes.chipDate}
                                    label={formatDate(new Date(menuItem.date), lang)}
                                    avatar={<EventAvailableOutlinedIcon />}
                                />
                                <div>
                                    <IconButton
                                        color='primary'
                                        className={classes.addMealIcon}
                                        onClick={() => handleOpenAddNewMealDialog(menuItem._id)}
                                    >
                                        <Tooltip title='Добави ястие'>
                                            <AddBoxOutlinedIcon />
                                        </Tooltip>
                                    </IconButton>
                                    <IconButton
                                        color='secondary'
                                        className={classes.addMealIcon}
                                        onClick={() => handleOpenConfirmDeleteMenuDialog(menuItem)}
                                    >
                                        <Tooltip title='Изтрий меню'>
                                            <DeleteForeverOutlinedIcon />
                                        </Tooltip>
                                    </IconButton>
                                </div>
                            </div>
                            <CustomTable
                                lang={lang}
                                columns={columns}
                                actions={actions}
                                maxLengthAllowed={60}
                                data={mealsWithMenuId}
                            />
                        </Paper>

                    )
                })
            }
            <AddNewMealDialog
                menuId={selectedMenu}
                open={openAddNewMealDialog}
                handleAdd={handleAddMenuMeal}
                handleClose={handleCloseAddNewMealDialog}
            />
            <AddNewMenuDialog
                open={openAddNewMenuDialog}
                handleAdd={handleAddNewTeatroLunchMenu}
                handleClose={handleCloseAddNewMenuDialog}
            />
            <ConfirmDeleteMenuDialog
                open={openConfirmDeleteMenuDialog}
                handleDelete={handleDeleteMenuClick}
                handleClose={handleCloseConfirmDeleteMenuDialog}
            />
        </div >
    )
}

const mapStateToProps = state => ({
    lang: getLang(state),
    menus: state.teatroLunchMenu.menus,
})

const mapDispatchToProps = {
    showNotification,
    getTeatroLunchMenus,
    deleteTeatroLunchMenu,
    addMealToTeatroLunchMenu,
    insertBlankTeatroLunchMenu,
    sendLunchMenuToSubscribers,
    deleteMealFromTeatroLunchMenu,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TeatroLunchMenuTab));
