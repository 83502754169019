import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import DnsRoundedIcon from '@material-ui/icons/DnsRounded';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { logos } from '../../../data/constants';
import { manageOptions } from '../constants';
import bgFlag from '../../../assets/bg_flag.svg';
import ukFlag from '../../../assets/uk_flag.svg';
import { changePageLanguage } from '../../../data/actions/pageActions';

const styles = (theme) => ({
    categoryHeader: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    categoryHeaderPrimary: {
        color: theme.palette.common.white,
    },
    item: {
        paddingTop: 1,
        paddingBottom: 1,
        color: 'rgba(255, 255, 255, 0.7)',
        '&:hover,&:focus': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
    },
    itemCategory: {
        backgroundColor: '#232f3e',
        boxShadow: '0 -1px 0 #404854 inset',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    firebase: {
        fontSize: 24,
        color: theme.palette.common.white,
    },
    itemActiveItem: {
        color: '#4fc3f7',
    },
    itemPrimary: {
        fontSize: 'inherit',
    },
    itemIcon: {
        minWidth: 'auto',
        marginRight: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(2),
    },
    icon: {
        width: 38,
        height: 38,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '20px',
        cursor: 'pointer',
    }
});

function Navigator({
    manageOption,
    setManageOption,
    changePageLanguage,
    ...props
}) {
    const { classes, ...other } = props;

    const categories = [
        {
            id: 'Manage',
            children: [
                {
                    id: 'Teatro',
                    name: manageOptions.teatro,
                    icon: <PeopleIcon />,
                    active: manageOption === manageOptions.teatro
                },
                {
                    id: 'Orange',
                    name: manageOptions.orange,
                    icon: <DnsRoundedIcon />,
                    active: manageOption === manageOptions.orange
                },
            ],
        },
        {
            id: 'Account',
            children: [
                {
                    id: 'Log out',
                    name: 'logout',
                    icon: <ExitToAppIcon />,
                },
            ]
        }
    ];

    const handleChangeToBg = () => changePageLanguage('bg');
    const handleChangeToEn = () => changePageLanguage('en');

    return (
        <Drawer variant="permanent" {...other}>
            <List disablePadding>
                <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
                    <span>
                        {logos.teatro}
                        {logos.orange}
                    </span>
                </ListItem>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <img
                                src={bgFlag}
                                alt='bg-flag'
                                className={classes.icon}
                                onClick={handleChangeToBg}
                            />
                        </ListItemIcon>
                        <ListItemIcon>
                            <img
                                src={ukFlag}
                                alt='uk-flag'
                                className={classes.icon}
                                onClick={handleChangeToEn}
                            />
                        </ListItemIcon>
                    </ListItem>
                </List>
                {categories.map(({ id, children }) => (
                    <React.Fragment key={id}>
                        <ListItem className={classes.categoryHeader}>
                            <ListItemText
                                classes={{
                                    primary: classes.categoryHeaderPrimary,
                                }}
                            >
                                {id}
                            </ListItemText>
                        </ListItem>
                        {children.map(({ id: childId, icon, active, name }) => (
                            <ListItem
                                key={childId}
                                button
                                onClick={() => setManageOption(name)}
                                className={clsx(classes.item, active && classes.itemActiveItem)}
                            >
                                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                                <ListItemText
                                    classes={{
                                        primary: classes.itemPrimary,
                                    }}
                                >
                                    {childId}
                                </ListItemText>
                            </ListItem>
                        ))}
                        <Divider className={classes.divider} />
                    </React.Fragment>
                ))}
            </List>
        </Drawer>
    );
}

Navigator.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
    changePageLanguage,
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(Navigator));