import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Navigator from './Navigator';
import { constants } from '../../../data/constants';
import HeaderTeatro from './HeaderTeatro';
import HeaderOrange from './HeaderOrange';
import { manageOptions } from '../constants';
import { connect } from 'react-redux';
import { history } from '../../../data/store';
import { signOut } from '../../../data/actions/userActions';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            {constants.heading.teatro}
            {' | '}
            {constants.heading.orange}{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

let theme = createMuiTheme({
    palette: {
        primary: {
            light: '#63ccff',
            main: '#009be5',
            dark: '#006db3',
        },
    },
    typography: {
        h5: {
            fontWeight: 500,
            fontSize: 26,
            letterSpacing: 0.5,
        },
    },
    shape: {
        borderRadius: 8,
    },
    props: {
        MuiTab: {
            disableRipple: true,
        },
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    overrides: {
        MuiDrawer: {
            paper: {
                backgroundColor: '#18202c',
            },
        },
        MuiButton: {
            label: {
                textTransform: 'none',
            },
            contained: {
                boxShadow: 'none',
                '&:active': {
                    boxShadow: 'none',
                },
            },
        },
        MuiTabs: {
            root: {
                marginLeft: theme.spacing(1),
            },
            indicator: {
                height: 3,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                backgroundColor: theme.palette.common.white,
            },
        },
        MuiTab: {
            root: {
                textTransform: 'none',
                margin: '0 16px',
                minWidth: 0,
                padding: 0,
                [theme.breakpoints.up('md')]: {
                    padding: 0,
                    minWidth: 0,
                },
            },
        },
        MuiIconButton: {
            root: {
                padding: theme.spacing(1),
            },
        },
        MuiTooltip: {
            tooltip: {
                borderRadius: 4,
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: '#404854',
            },
        },
        MuiListItemText: {
            primary: {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        MuiListItemIcon: {
            root: {
                color: 'inherit',
                marginRight: 0,
                '& svg': {
                    fontSize: 20,
                },
            },
        },
        MuiAvatar: {
            root: {
                width: 32,
                height: 32,
            },
        },
    },
};

const drawerWidth = 256;

const styles = {
    root: {
        display: 'flex',
        minHeight: '100vh',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    app: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    footer: {
        padding: theme.spacing(2),
        background: '#eaeff1',
    },
};

function Paperbase({
    user,
    signOut,
    ...props
}) {
    if (!user.uid) {
        history.push('/sign-in');
    }

    const { classes } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [manageOption, setManageOption] = useState(manageOptions.teatro);

    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

    const getContent = (manageOption) => {
        switch (manageOption) {
            case manageOptions.teatro: return <HeaderTeatro />;
            case manageOptions.orange: return <HeaderOrange />;
            default: return false;
        }
    }

    const handleChangeManageOption = option => {
        if (option === 'logout') {
            signOut();
        } else {
            setManageOption(option);
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.root}>
                <CssBaseline />
                <nav className={classes.drawer}>
                    <Hidden smUp implementation="js">
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            manageOption={manageOption}
                            setManageOption={handleChangeManageOption}
                        />
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Navigator
                            manageOption={manageOption}
                            setManageOption={handleChangeManageOption}
                            PaperProps={{ style: { width: drawerWidth } }}
                        />
                    </Hidden>
                </nav>
                <div className={classes.app}>
                    {getContent(manageOption)}
                    <footer className={classes.footer}>
                        <Copyright />
                    </footer>
                </div>
            </div>
        </ThemeProvider>
    );
}

const mapStateToProps = state => ({
    user: state.user,
})

const mapDispatchToProps = {
    signOut,
}

Paperbase.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Paperbase));
