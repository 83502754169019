export const styles = theme => ({
    topActionButton: {
        marginRight: '10px',
        '&:hover': {
            textDecoration: 'underline',
        }
    },
    menuContainer: {
        margin: '20px 0',
    },
    menuHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    chipDate: {
        margin: '10px 0 5px 10px'
    },
    addMealIcon: {
        margin: '10px 10px 5px 0'
    },
    addNewMenuWrapper: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
})
