import React from 'react';
import { Fade } from 'react-reveal';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';

import withRoot from '../../../pages/orange/components/modules/withRoot';
import AppAppBar from "../../../pages/orange/components/modules/views/AppAppBar"
import Typography from '../../../pages/orange/components/modules/components/Typography';

import { constants } from '../../../data/constants';
import { getLang } from '../../../data/reducers/pageReducer';

const styles = {
    header: {
        margin: '50px 0',
        ['@media (max-width:750px)']: { // eslint-disable-line no-useless-computed-key
            margin: '30px 0',
            fontSize: '25px',
        }
    }
}

const OrangeAboutUs = ({
    lang,
    classes,
}) => {
    const renderText = (lang) => {
        if (lang === 'bg') {
            return (
                <div>
                    <Typography variant='h5' align='center'>
                        Ориндж шиша бар е разположен в центъра на град Смолян в непосредствена близост до кино и театър.
                    </Typography>
                    <Typography variant='h5' align='center'>
                        Той е ориентиран главно в предлагането на наргилета с уникални аромати и широк набор от миксове.
                    </Typography>
                    <br />
                    <Typography variant='h5' align='center'>
                        За вашето удобство са поставени меки дивани и семпли маси.
                    </Typography>
                    <Typography variant='h5' align='center'>
                        Ако огладнеете може да похапнете с разнообразна кухня.
                    </Typography>
                    <br />
                    <Typography variant='h5' align='center'>
                        През деня, когато слънчевата светлина навлиза през панорамните прозорци, можете да се насладите на чаша ароматно кафе или чай.
                    </Typography>
                    <Typography variant='h5' align='center'>
                        А за любителите на по-силни напитки - добре дошли в списъка с коктейли!
                    </Typography>
                    <Typography variant='h5' align='center'>
                        С настъпването на нощта наргиле барът се трансформира в истинско парти място с приглушена светлина и свежа музика.
                    </Typography>
                    <br />
                    <Typography variant='h5' align='center'>
                        Всичко това получено с усмихнато и приветливо обслужване.
                    </Typography>
                </div>
            )
        } else {
            return (
                <div>
                    <Typography variant='h5' align='center'>
                        Orange shisha bar is located in the center of city Smolyan right next to cinema and theater.
                    </Typography>
                    <br />
                    <Typography variant='h5' align='center'>
                        It is oriented mainly in offering a wide tastes of hookah tobaccos with possibilities for variety of mixes.
                    </Typography>
                    <Typography variant='h5' align='center'>
                        Soft sofas and semple tables aims satisfy the comfort of the clients.
                    </Typography>
                    <br />
                    <Typography variant='h5' align='center'>
                        If you get hungry you can always order something from our various kitchen offers.
                    </Typography>
                    <Typography variant='h5' align='center'>
                        During the day, when the sun is coming through the huge windows, you can enjoy a cup of coffee or a tea.
                    </Typography>
                    <br />
                    <Typography variant='h5' align='center'>
                        And for the hard drinks lovers - welcome to our cocktail list!
                    </Typography>
                    <br />
                    <Typography variant='h5' align='center'>
                        When the night comes by the bar transforms into real party place with muted light and fresh music.
                    </Typography>
                    <br />
                    <Typography variant='h5' align='center'>
                        All of that received along with friendly and smiling service.
                    </Typography>
                </div>
            )
        }
    }
    return (
        <React.Fragment>
            <AppAppBar />
            <Container>
                <Box sx={{ mt: 7, mb: 12 }}>
                    <Typography className={classes.header} variant="h3" gutterBottom align="center">
                        {constants.heading.orange}{' - '}
                        {lang === 'bg' ? 'За нас' : 'About us'}
                    </Typography>
                    <Fade bottom cascade>
                        {renderText(lang)}
                    </Fade>
                </Box>
            </Container>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    lang: getLang(state)
})

export default connect(mapStateToProps)(withStyles(styles)(withRoot(OrangeAboutUs)));
