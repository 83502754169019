import React from 'react';
import { Fade } from 'react-reveal';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';

import withRoot from '../../../pages/teatro/components/modules/withRoot';
import AppAppBar from "../../../pages/teatro/components/modules/views/AppAppBar"
import Typography from '../../../pages/teatro/components/modules/components/Typography';

import { constants } from '../../../data/constants';
import { getLang } from '../../../data/reducers/pageReducer';

const styles = {
    header: {
        margin: '50px 0',
        ['@media (max-width:750px)']: { // eslint-disable-line no-useless-computed-key
            margin: '30px 0',
            fontSize: '25px',
        }
    }
}

const TeatroAboutUs = ({
    lang,
    classes,
}) => {
    const renderText = (lang) => {
        if (lang === 'bg') {
            return (
                <div>
                    <Typography variant='h5' align='center'>
                        Уют, спокойствие и вкусна храна в сърцето на града! Театро е мястото за всеки един от вас!
                    </Typography>
                    <br />
                    <Typography variant='h5' align='center'>
                        Заповядайте при нас да пиете вашето сутрешно кафе или пресен фреш.
                    </Typography>
                    <Typography variant='h5' align='center'>
                        Всеки ден можете да намерите специално обедно меню, което освен разнообразно и вкусно, е и на много добра цена.
                    </Typography>
                    <br />
                    <Typography variant='h5' align='center'>
                        Заведението разполага с маси на открито, детски къти паркинг.
                    </Typography>
                    <Typography variant='h5' align='center'>
                        За вашите семейни или фирмени тържества изготвяме индивидуални оферти изцяло съобразени с вашите желания.
                    </Typography>
                    <br />
                    <Typography variant='h5' align='center'>
                        Можете да направите и поръчка за вкъщи с безплатна доставка.
                    </Typography>
                    <br />
                    <Typography variant='h5' align='center'>
                        Прекарайте отлично по всяко време на деня и се поглезете с прекрасна храна, събрала домашни рецепти от цял свят.
                    </Typography>
                    <Typography variant='h5' align='center'>
                        За да ви осигурим несравним вкус и аромат, използваме пресни продукти, свежи подправки и авторски гръмки.
                    </Typography>
                    <Typography variant='h5' align='center'>
                        Театро е мястото, което е готово сърдечно да посрещне всички вас, грижещи се за своето здраве със здравословно меню.
                    </Typography>
                    <Typography variant='h5' align='center'>
                        Предлагаме и суши!
                    </Typography>
                    <br />
                    <Typography variant='h5' align='center'>
                        Всичко това получено с усмихнато и приветливо обслужване.
                    </Typography>
                </div>
            )
        } else {
            return (
                <div>
                    <Typography variant='h5' align='center'>
                        Cosy and chill place with delicious food in the heart of the town! Teatro is the place for every one of you!
                    </Typography>
                    <br />
                    <Typography variant='h5' align='center'>
                        Be welcome to drink your morning coffee or fresh juice.
                    </Typography>
                    <Typography variant='h5' align='center'>
                        Every day you can find out various and tasty lunch menu on a great price.
                    </Typography>
                    <br />
                    <Typography variant='h5' align='center'>
                        The resturant has indoor and outdoor tables, children's playground and parking.
                    </Typography>
                    <Typography variant='h5' align='center'>
                        For your family or corporate parties we can prepare complete custom offers, according to your desires.
                    </Typography>
                    <br />
                    <Typography variant='h5' align='center'>
                        You can place an order with a free delivery to your home.
                    </Typography>
                    <br />
                    <Typography variant='h5' align='center'>
                        Spend excellent times enjoying our delicious meals, collecting carefully selected home recipes from all over the world.
                    </Typography>
                    <Typography variant='h5' align='center'>
                        In order to deliver incomparable taste and fragrance, we use only fresh products and spices.
                    </Typography>
                    <Typography variant='h5' align='center'>
                        Teatro also has a special healthy menu for the connoisseurs.
                    </Typography>
                    <Typography variant='h5' align='center'>
                        We are offering sushi as well!
                    </Typography>
                    <br />
                    <Typography variant='h5' align='center'>
                        All of that received along with friendly and smiling service.
                    </Typography>
                </div>
            )
        }
    }
    return (
        <React.Fragment>
            <AppAppBar />
            <Container>
                <Box sx={{ mt: 7, mb: 12 }}>
                    <Typography className={classes.header} variant="h3" gutterBottom align="center">
                        {constants.heading.teatro}{' - '}
                        {lang === 'bg' ? 'За нас' : 'About us'}
                    </Typography>
                    <Fade bottom cascade>
                        {renderText(lang)}
                    </Fade>
                </Box>
            </Container>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    lang: getLang(state)
})

export default connect(mapStateToProps)(withStyles(styles)(withRoot(TeatroAboutUs)));
