import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ContentTeatro from './ContentTeatro/ContentTeatro';

const lightColor = 'rgba(255, 255, 255, 0.7)';

export const teatroTabs = {
    lunchMenu: 0,
    menu: 1,
    events: 2,
    settings: 3,
}

const styles = (theme) => ({
    secondaryBar: {
        zIndex: 0,
        background: '#18202C',
    },
    menuButton: {
        marginLeft: -theme.spacing(1),
    },
    iconButtonAvatar: {
        padding: 4,
    },
    link: {
        textDecoration: 'none',
        color: lightColor,
        '&:hover': {
            color: theme.palette.common.white,
        },
    },
    button: {
        borderColor: lightColor,
    },
    main: {
        flex: 1,
        padding: theme.spacing(6, 4),
        background: '#eaeff1',
    },
});

function HeaderTeatro({
    ...props
}) {
    const { classes } = props;

    const [currentTab, setCurrentTab] = useState(0);

    return (
        <React.Fragment>
            <AppBar
                component="div"
                className={classes.secondaryBar}
                position="static"
                elevation={0}
            >
                <Toolbar>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item xs>
                            <Typography align='center' color="inherit" variant="h5" component="h1">
                                Управление Teatro
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <AppBar
                component="div"
                className={classes.secondaryBar}
                color='primary'
                position="static"
                elevation={0}
            >
                <Tabs value={currentTab} textColor="inherit">
                    <Tab textColor="inherit" label="Обедно меню" onClick={() => setCurrentTab(0)} />
                    <Tab textColor="inherit" label="Меню" onClick={() => setCurrentTab(1)} />
                    <Tab textColor="inherit" label="Събития" onClick={() => setCurrentTab(2)} />
                    <Tab textColor="inherit" label="Настройки" onClick={() => setCurrentTab(3)} />
                </Tabs>
            </AppBar>
            <main className={classes.main}>
                <ContentTeatro currentTab={currentTab} />
            </main>
        </React.Fragment>
    );
}

export default withStyles(styles)(HeaderTeatro);
