import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { Button, FormControl, IconButton, MenuItem, Paper, Select, Tooltip, Typography, withStyles } from "@material-ui/core";

import {
    getOrangeMenu,
    addOfferToOrangeMenu,
    editOfferOfOrangeMenu,
    deleteOfferFromOrangeMenu,
} from '../../../../../data/actions/menuActions';
import { getLang } from "../../../../../data/reducers/pageReducer";
import { getOrangeMenuCategories } from "../../../../../data/actions/templatesActions";

import EditOfferDialog from "./OrangeMenuDialogs/EditOfferDialog";
import AddNewOfferDialog from "./OrangeMenuDialogs/AddNewOfferDialog";
import CustomTable from "../../../../../shared/components/CustomTable";
import ConfirmDeleteOfferDialog from "./OrangeMenuDialogs/ConfirmDeleteNewOfferDialog";

import { styles } from "./orangeMenuConstants";

const bgColumns = [
    { title: 'Ред', propName: 'orderId' },
    { title: 'Име', propName: 'name' },
    { title: 'Тип', propName: 'type' },
    { title: 'Порция', propName: 'netWeight', suffix: item => item?.quantityType?.bg || 'мл.' },
    { title: 'Цена', propName: 'price', suffix: () => ' лв.' },
    { title: '', propName: 'actions' },
]

const enColumns = [
    { title: 'Ред', propName: 'orderId' },
    { title: 'Име', propName: 'name' },
    { title: 'Тип', propName: 'type' },
    { title: 'Порция', propName: 'netWeight', suffix: item => item?.quantityType?.en || 'ml.' },
    { title: 'Цена', propName: 'price', suffix: () => ' bgn' },
    { title: '', propName: 'actions' },
]

const OrangeMenuTab = ({
    lang,
    menu,
    classes,
    getOrangeMenu,
    addOfferToOrangeMenu,
    editOfferOfOrangeMenu,
    deleteOfferFromOrangeMenu,
}) => {
    const [categoryFilter, setCategoryFilter] = useState('all');
    const [openAddNewOfferDialog, setOpenAddNewOfferDialog] = useState(false);
    const [openEditOfferDialog, setOpenEditOfferDialog] = useState(false);
    const [openConfirmDeleteNewOfferDialog, setOpenConfirmDeleteNewOfferDialog] = useState(false);
    const [selectedOffer, setSelectedOffer] = useState('');

    useEffect(() => {
        getOrangeMenu();
    }, [getOrangeMenu]);

    const handleCategoryFilterChange = ev => setCategoryFilter(ev.target.value);
    const handleOpenAddNewOfferDialog = () => setOpenAddNewOfferDialog(true);
    const handleCloseAddNewOfferDialog = () => setOpenAddNewOfferDialog(false);
    const handleOpenEditOfferDialog = offer => {
        setSelectedOffer(offer);
        setOpenEditOfferDialog(true);
    }
    const handleCloseEditOfferDialog = () => setOpenEditOfferDialog(false);
    const handleOpenConfirmDeleteNewOfferDialog = offer => {
        setSelectedOffer(offer);
        setOpenConfirmDeleteNewOfferDialog(true);
    }
    const handleCloseConfirmDeleteNewOfferDialog = () => setOpenConfirmDeleteNewOfferDialog(false);

    const handleDeleteOfferClick = offerId => {
        deleteOfferFromOrangeMenu(offerId);
        setSelectedOffer('');
        handleCloseConfirmDeleteNewOfferDialog();
        topRef.current.scrollInfoView();
    }

    const handleAddMenuOffer = offer => {
        addOfferToOrangeMenu(offer);
        handleCloseAddNewOfferDialog();
        topRef.current.scrollInfoView();
    }

    const handleEditOffer = offer => {
        editOfferOfOrangeMenu(offer);
        setSelectedOffer('');
        handleCloseEditOfferDialog();
        topRef.current.scrollInfoView();
    }

    const topRef = useRef();

    const actions = item => [
        <IconButton
            key={1}
            color='primary'
            onClick={() => handleOpenEditOfferDialog(item)}
        >
            <Tooltip title='Промени'>
                <EditOutlinedIcon />
            </Tooltip>
        </IconButton>,
        <IconButton
            key={2}
            color='secondary'
            onClick={() => handleOpenConfirmDeleteNewOfferDialog(item)}
        >
            <Tooltip title='Изтрий'>
                <DeleteForeverOutlinedIcon />
            </Tooltip>
        </IconButton>
    ]

    const formattedPrices = menu?.offers?.map(x => ({ ...x, price: Number(x.price).toFixed(2) }));
    const orderedOffers = formattedPrices ? formattedPrices.sort((a, b) => a.orderId - b.orderId) : [];

    const nextDefaultMealOrderId = (orderedOffers.length > 0 ? +orderedOffers[orderedOffers.length - 1].orderId + 1 : 0).toString();

    const columns = lang === 'bg' ? bgColumns : enColumns;

    const categories = [...new Set(orderedOffers.map(x => x.type))];

    const filteredOffers = categoryFilter === 'all' ? orderedOffers : orderedOffers.filter(x => x.type === categoryFilter);

    return (
        <div>
            <div ref={topRef} />
            <Typography align='center' variant='body1'>
                {lang === 'bg' ? 'Филтрирай по категория' : 'Filter by category'}
            </Typography>
            <FormControl fullWidth>
                <Select
                    margin='dense'
                    variant='outlined'
                    value={categoryFilter}
                    style={{ marginBottom: '10px' }}
                    onChange={handleCategoryFilterChange}
                >
                    <MenuItem value='all'>{lang === 'bg' ? 'всички' : 'all'}</MenuItem>
                    {
                        categories && categories.map((x, i) => {
                            const [en, bg] = x.split('|');
                            const types = { en, bg };
                            return <MenuItem key={i} value={x}>{types[lang]}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
            <Paper className={classes.menuContainer}>
                <div className={classes.menuHeader}>
                    <div>
                        <IconButton
                            className={classes.addMealIcon}
                            onClick={handleOpenAddNewOfferDialog}
                        >
                            <Tooltip title='Добави оферта'>
                                <AddBoxOutlinedIcon />
                            </Tooltip>
                        </IconButton>
                    </div>
                </div>
                <CustomTable
                    lang={lang}
                    columns={columns}
                    actions={actions}
                    data={filteredOffers}
                    maxLengthAllowed={60}
                />
            </Paper>
            <Button
                fullWidth
                color='primary'
                variant='outlined'
                onClick={handleOpenAddNewOfferDialog}
            >
                Добави ново оферта
            </Button>
            <AddNewOfferDialog
                menuId={0}
                open={openAddNewOfferDialog}
                handleAdd={handleAddMenuOffer}
                handleClose={handleCloseAddNewOfferDialog}
                nextDefaultOrderId={nextDefaultMealOrderId}
            />
            <EditOfferDialog
                offer={selectedOffer}
                open={openEditOfferDialog}
                handleEdit={handleEditOffer}
                handleClose={handleCloseEditOfferDialog}
            />
            <ConfirmDeleteOfferDialog
                offer={selectedOffer}
                handleDelete={handleDeleteOfferClick}
                open={openConfirmDeleteNewOfferDialog}
                handleClose={handleCloseConfirmDeleteNewOfferDialog}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    lang: getLang(state),
    menu: state.orangeMenu.menu,
})

const mapDispatchToProps = {
    getOrangeMenu,
    addOfferToOrangeMenu,
    editOfferOfOrangeMenu,
    getOrangeMenuCategories,
    deleteOfferFromOrangeMenu,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrangeMenuTab));
