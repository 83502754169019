import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { teatroTabs } from '../HeaderTeatro';
import TeatroMenuTab from './TeatroMenuTab/TeatroMenuTab';
import TeatroEventsTab from './/TeatroEventsTab/TeatroEventsTab';
import TeatroSettingsTab from './TeatroSettingsTab/TeatroSettingsTab';
import TeatroLunchMenuTab from './TeatroLunchMenuTab/TeatroLunchMenuTab';

const styles = (theme) => ({
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '40px 16px',
  },
});

function getTabContent(tab) {
  switch (tab) {
    case teatroTabs.lunchMenu: return <TeatroLunchMenuTab />;
    case teatroTabs.menu: return <TeatroMenuTab />;
    case teatroTabs.events: return <TeatroEventsTab />;
    case teatroTabs.settings: return <TeatroSettingsTab />;
    default: return false;
  }
}

function ContentTeatro({
  currentTab,
  ...props
}) {
  return getTabContent(currentTab)
}

export default withStyles(styles)(ContentTeatro);