import { connect } from "react-redux"
import { getLang } from "../../data/reducers/pageReducer"
import Paperbase from "./components/Paperbase"

const AdminPanel = () => <Paperbase />;

const mapStateToProps = state => ({
    lang: getLang(state),
})

export default connect(mapStateToProps)(AdminPanel);
