import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import { FormControl, MenuItem, Paper, Select, withStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';

import withRoot from './modules/withRoot';
import AppAppBar from "./modules/views/AppAppBar"
import Typography from './modules/components/Typography';

import { constants } from '../../../data/constants';
import { getLang } from '../../../data/reducers/pageReducer';
import CustomTable from '../../../shared/components/CustomTable';
import { getTeatroLunchMenus, getTeatroMenu } from '../../../data/actions/menuActions';

const styles = {
    header: {
        margin: '50px 0',
        ['@media (max-width:750px)']: { // eslint-disable-line no-useless-computed-key
            margin: '30px 0',
            fontSize: '25px',
        }
    },
    mealGroupHeader: {
        marginBottom: '10px',
    },
    mealGroupContainer: {
        margin: '0 0 20px 0',
        padding: '20px 10px 10px',
    }
}

const lunchColumnWidths = ['40%', '30%', '10%', '10%', '10%'];
const columnWidths = ['40%', '30%', '15%', '15%'];

const enLunchMenuColumns = [
    { title: 'Name', propName: 'name' },
    { title: 'Ingredients', propName: 'ingredients' },
    { title: 'Type', propName: 'type' },
    { title: 'Quantity', propName: 'netWeight', suffix: item => item?.quantityType?.en || 'gr.' },
    { title: 'Price', propName: 'price', suffix: () => ' bgn' },
]

const bgLunchMenuColumns = [
    { title: 'Име', propName: 'name' },
    { title: 'Съставки', propName: 'ingredients' },
    { title: 'Тип', propName: 'type' },
    { title: 'Количество', propName: 'netWeight', suffix: item => item?.quantityType?.bg || 'гр.' },
    { title: 'Цена', propName: 'price', suffix: () => ' лв.' },
]

const enMenuColumns = [
    { title: 'Name', propName: 'name' },
    { title: 'Ingredients', propName: 'ingredients' },
    { title: 'Quantity', propName: 'netWeight', suffix: item => item?.quantityType?.en || 'gr.' },
    { title: 'Price', propName: 'price', suffix: () => ' bgn' },
]

const bgMenuColumns = [
    { title: 'Име', propName: 'name' },
    { title: 'Съставки', propName: 'ingredients' },
    { title: 'Количество', propName: 'netWeight', suffix: item => item?.quantityType?.bg || 'гр.' },
    { title: 'Цена', propName: 'price', suffix: () => ' лв.' },
]

const TeatroMenu = ({
    lang,
    menu,
    classes,
    lunchMenus,
    getTeatroMenu,
    getTeatroLunchMenus,
}) => {
    const [categoryFilter, setCategoryFilter] = useState('all');

    useEffect(() => {
        getTeatroMenu();
        getTeatroLunchMenus();
    }, [getTeatroMenu, getTeatroLunchMenus]);

    const todaysLunchMenu = lunchMenus.find(x => new Date(x.date).getDate() === new Date().getDate());
    const orderedLunchMeals = todaysLunchMenu ? todaysLunchMenu.meals.sort((a, b) => a.orderId - b.orderId) : [];

    const handleCategoryFilterChange = ev => setCategoryFilter(ev.target.value);

    const isNew = meal => {
        // one month epoch value
        const newTreshold = 30 * 24 * 60 * 60 * 1000;
        return meal.isNew && Date.now() - meal.dateCreated < newTreshold;
    }

    const groupByType = meals => {
        const grouped = {};
        meals && meals.forEach(meal => {
            if (Object.keys(grouped).includes(meal.type)) {
                if (isNew(meal)) {
                    grouped[meal.type].push({
                        ...meal,
                        name: {
                            en: `NEW - ${meal.name.en}`,
                            bg: `НОВО - ${meal.name.bg}`,
                        }
                    });
                } else {
                    grouped[meal.type].push(meal);
                }
            } else {
                if (isNew(meal)) {
                    grouped[meal.type] = [{
                        ...meal,
                        name: {
                            en: `NEW - ${meal.name.en}`,
                            bg: `НОВО - ${meal.name.bg}`,
                        }
                    }];
                } else {
                    grouped[meal.type] = [meal];
                }
            }
        })
        // sort each group by their orderIds
        Object.keys(grouped).forEach(key => {
            grouped[key].sort((a, b) => a.orderId - b.orderId);
        })
        return grouped;
    }

    const sortByOrderId = grouped => {
        const getLowestOrderId = meals => meals ? meals[0].orderId : undefined;
        const sorted = [];
        Object.keys(grouped).forEach(key => {
            if (sorted.length > 0) {
                const currentOrderId = getLowestOrderId(grouped[key]);
                const firstGroup = sorted[0];
                const firstGroupOrderId = getLowestOrderId(firstGroup);
                if (currentOrderId < firstGroupOrderId) {
                    sorted.unshift(grouped[key]);
                } else {
                    sorted.push(grouped[key]);
                }
            } else {
                sorted.push(grouped[key]);
            }
        })
        return sorted;
    }

    let groupedSortedMeals = sortByOrderId(groupByType(menu.meals));
    const lunchMenuColumns = lang === 'bg' ? bgLunchMenuColumns : enLunchMenuColumns;
    const menuColumns = lang === 'bg' ? bgMenuColumns : enMenuColumns;

    let categorized = [...groupedSortedMeals];
    if (categoryFilter !== 'all') {
        categorized = groupedSortedMeals.filter(x => !!x.find(y => y.type === categoryFilter));
    }

    return (
        <React.Fragment>
            <AppAppBar />
            <Container>
                {
                    orderedLunchMeals?.length > 0 &&
                    <Box sx={{ mt: 7, mb: 12 }}>
                        <Typography className={classes.header} variant="h3" gutterBottom align="center">
                            {constants.heading.teatro}{' - '}
                            {lang === 'bg' ? 'Обедно Меню' : 'Lunch Menu'}
                        </Typography>
                        <CustomTable
                            lang={lang}
                            maxLengthAllowed={60}
                            data={orderedLunchMeals}
                            columns={lunchMenuColumns}
                            columnWidths={lunchColumnWidths}
                        />
                    </Box>
                }
                <Box sx={{ mt: 7, mb: 12 }}>
                    <Typography className={classes.header} variant="h3" gutterBottom align="center">
                        {constants.heading.teatro}{' - '}
                        {lang === 'bg' ? 'Меню' : 'Menu'}
                    </Typography>
                    <Typography align='center' variant='body1'>
                        {lang === 'bg' ? 'Филтрирай по категория' : 'Filter by category'}
                    </Typography>
                    <FormControl fullWidth>
                        <Select
                            margin='dense'
                            variant='outlined'
                            value={categoryFilter}
                            style={{ marginBottom: '10px' }}
                            onChange={handleCategoryFilterChange}
                        >
                            <MenuItem value='all'>{lang === 'bg' ? 'всички' : 'all'}</MenuItem>
                            {
                                groupedSortedMeals && groupedSortedMeals.map((x, i) => {
                                    const [en, bg] = x[0].type.split('|');
                                    const types = { en, bg };
                                    return <MenuItem key={i} value={x[0].type}>{types[lang]}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                    {
                        categorized && categorized.map((meals, i) => {
                            const [en, bg] = meals[0].type.split('|');
                            const types = { en, bg };
                            let containsIngredeints = false;
                            for (const meal of meals) {
                                if (meal.ingredients.bg !== '-' || meal.ingredients.en !== '-') {
                                    containsIngredeints = true;
                                    break;
                                }
                            }
                            const menuCols = [...menuColumns];
                            if (!containsIngredeints) {
                                const index = menuCols.findIndex(x => x.propName === 'ingredients');
                                menuCols.splice(index, 1);
                            }
                            return (
                                <Paper key={i} className={classes.mealGroupContainer}>
                                    <Typography
                                        variant='h6'
                                        align='center'
                                        className={classes.mealGroupHeader}
                                    >
                                        {types[lang]}
                                    </Typography>
                                    <CustomTable
                                        lang={lang}
                                        data={meals}
                                        columns={menuCols}
                                        maxLengthAllowed={60}
                                        columnWidths={columnWidths}
                                    />
                                </Paper>
                            )
                        })
                    }
                </Box>
            </Container>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    lang: getLang(state),
    menu: state.teatroMenu.menu,
    lunchMenus: state.teatroLunchMenu.menus,
})

const mapDispatchToProps = {
    getTeatroMenu,
    getTeatroLunchMenus,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRoot(TeatroMenu)));
