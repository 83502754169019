export const endpoints = {
    getUser: 'users',
    subscribeUser: 'subscribe',
    teatroMenus: 'menus/teatro',
    teatroLunchMenus: 'menus/teatro/lunch',
    orangeMenus: 'menus/orange',
    teatroEvents: 'events/teatro',
    orangeEvents: 'events/orange',
    teatroOffers: 'offers/teatro',
    orangeOffers: 'offers/orange',
    catering: 'catering',
    teatroTemplates: 'templates/teatro',
    teatroLunchTemplates: 'templates/teatro/lunch',
    orangeTemplates: 'templates/orange',
}
