import React from 'react';
import clsx from 'clsx';
import RubberBand from 'react-reveal/RubberBand';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CakeIcon from '@material-ui/icons/Cake';
import { constants, corporateAddress, corporatePhoneTeatro, googleMapsTeatro, logos } from '../../../../../data/constants';
import { getLang } from '../../../../../data/reducers/pageReducer';
import bgFlag from '../../../../../assets/bg_flag.svg';
import ukFlag from '../../../../../assets/uk_flag.svg';
import { changePageLanguage } from '../../../../../data/actions/pageActions';
import facebookIcon from '../../../../../assets/facebook.svg';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
    color: 'white',
    textAlign: 'left',
    ['@media (max-width:780px)']: { // eslint-disable-line no-useless-computed-key
      marginLeft: '-10px'
    }
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  icon: {
    width: 38,
    height: 38,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '20px',
    cursor: 'pointer',
  },
  navbarIcon: {
    width: 25,
    height: 25,
    color: 'inherit',
  },
  navbarIconWrapper: {
    padding: '0 10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'white',
    cursor: 'pointer',
    opacity: 0.8,
    '&:hover': {
      opacity: 1
    }
  },
  navbarIconContent: {
    display: 'none',
    ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
      display: 'flex',
      marginLeft: 10,
    }
  },
}));

function AppAppBar({
  lang,
  changePageLanguage,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [counter, setCounter] = React.useState(0);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const handleChangeToBg = () => changePageLanguage('bg');
  const handleChangeToEn = () => changePageLanguage('en');

  const menuLinks = [
    { href: '/teatro/menu', title: lang === 'bg' ? 'Меню' : 'Menu', icon: <RestaurantMenuIcon /> },
    { href: '/teatro/events', title: lang === 'bg' ? 'Събития' : 'Events', icon: <AccessibilityNewIcon /> },
    { href: '/teatro/catering', title: lang === 'bg' ? 'Кетеринг' : 'Catering', icon: <CakeIcon /> },
    { href: '/teatro/gallery', title: lang === 'bg' ? 'Галерия' : 'Gallery', icon: <PhotoLibraryIcon /> },
  ]

  const secondaryMenuLinks = [
    { href: '/teatro/about-us', title: lang === 'bg' ? 'За нас' : 'About us', icon: <InfoIcon /> }
  ]

  const hoverTrigger = () => setCounter(oldState => oldState + 1);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Typography variant="h6" noWrap className={classes.title}>
            <Link
              href='/teatro'
              underline="none"
              className={classes.title}
              onMouseOver={hoverTrigger}
            >
              <RubberBand spy={counter}>
                {logos.teatro}
              </RubberBand>
            </Link>
          </Typography>
          <Link
            href={`tel:${corporatePhoneTeatro}`}
            variant='caption'
            underline='none'
            className={classes.navbarIconWrapper}
          >
            <PhoneInTalkIcon className={classes.navbarIcon} />
            <span className={classes.navbarIconContent}>{corporatePhoneTeatro}</span>
          </Link>
          <Link
            href={googleMapsTeatro}
            target='_blank'
            variant='caption'
            underline='none'
            className={classes.navbarIconWrapper}
          >
            <LocationOnIcon className={classes.navbarIcon} />
            <span className={classes.navbarIconContent}>{corporateAddress(lang)}</span>
          </Link>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            className={clsx(open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
      </main>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {menuLinks.map((x, i) => (
            <Link
              variant="h6"
              underline="none"
              href={x.href}
              key={i}
            >
              <ListItem button>
                <ListItemIcon>{x.icon}</ListItemIcon>
                <ListItemText primary={x.title} />
              </ListItem>
            </Link>
          ))}
        </List>
        <Divider />
        <List>
          {secondaryMenuLinks.map((x, i) => (
            <Link
              variant="h6"
              underline="none"
              href={x.href}
              key={i}
            >
              <ListItem button>
                <ListItemIcon>{x.icon}</ListItemIcon>
                <ListItemText primary={x.title} />
              </ListItem>
            </Link>
          ))}
        </List>
        <Divider />
        <List>
          <Link
            href='/orange'
            underline="none"
            variant='h6'
          >
            <ListItem button>
              {constants.heading.orange}
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List>
          <ListItem>
            <ListItemIcon>
              <img
                src={bgFlag}
                alt='bg-flag'
                className={classes.icon}
                onClick={handleChangeToBg}
              />
            </ListItemIcon>
            <ListItemIcon>
              <img
                src={ukFlag}
                alt='uk-flag'
                className={classes.icon}
                onClick={handleChangeToEn}
              />
            </ListItemIcon>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem>
            <ListItemIcon>
              <Link
                href="https://www.facebook.com/Teatro-BarDinner-150743022199612/"
                underline="none"
                variant='h6'
                target="_blank"
              >
                <img
                  alt="Facebook"
                  src={facebookIcon}
                  className={classes.icon}
                />
              </Link>
            </ListItemIcon>
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}

const mapStateToProps = state => ({
  lang: getLang(state),
})

const mapDispatchToProps = {
  changePageLanguage
}

export default connect(mapStateToProps, mapDispatchToProps)(AppAppBar)
