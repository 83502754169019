import { endpoints } from "./endpoints";
import { httpService } from "./httpService";

export const templatesService = {
    getTeatroLunchMenuCategories: () => httpService.get(endpoints.teatroLunchTemplates),
    insertTeatroLunchMenuCategory: category => httpService.post(endpoints.teatroLunchTemplates, { category }),
    deleteTeatroLunchMenuCategory: id => httpService.delete(endpoints.teatroLunchTemplates, { id }),

    getTeatroMenuCategories: () => httpService.get(endpoints.teatroTemplates),
    insertTeatroMenuCategory: category => httpService.post(endpoints.teatroTemplates, { category }),
    deleteTeatroMenuCategory: id => httpService.delete(endpoints.teatroTemplates, { id }),
    
    getOrangeMenuCategories: () => httpService.get(endpoints.orangeTemplates),
    insertOrangeMenuCategory: category => httpService.post(endpoints.orangeTemplates, { category }),
    deleteOrangeMenuCategory: id => httpService.delete(endpoints.orangeTemplates, { id }),
}
