import { LOG_USER, SIGN_OUT } from "../actionTypes";

const initialState = {

}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOG_USER: return action.payload;
        case SIGN_OUT: return initialState;
        default: return state;
    }
}
