import { endpoints } from "./endpoints"
import { httpService } from "./httpService"

const lunchMenu = endpoints.subscribeUser + '/lunch-menu';
const teatroEvent = endpoints.subscribeUser + '/teatro-event';
const orangeEvent = endpoints.subscribeUser + '/orange-event';

export const subscribeService = {
    subscribe: email => httpService.post(endpoints.subscribeUser, { email }),
    unsubscribe: token => httpService.get(`${endpoints.subscribeUser}/remove/${token}`),
    sendLunchMenuToSubscribers: meals => httpService.post(lunchMenu, { meals }),
    sendTeatroEventToSubscribers: event => httpService.post(teatroEvent, { event }),
    sendOrangeEventToSubscribers: event => httpService.post(orangeEvent, { event }),
}
