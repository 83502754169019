import { Redirect, Router } from "react-router-dom";
import { Route, Switch } from "react-router-dom";

import { history } from "./data/store";

import Notification from "./shared/common/Notification";
import ApplicationLoader from "./shared/common/ApplicationLoader";

import TeatroPage from "./pages/teatro/Teatro";
import TeatroGallery from './pages/teatro/components/TeatroGallery';
import TeatroEvents from './pages/teatro/components/TeatroEvents';
import TeatroMenu from './pages/teatro/components/TeatroMenu';
import TeatroCatering from "./pages/teatro/components/TeatroCatering";

import OrangePage from "./pages/orange/OrangePage";
import OrangeGallery from './pages/orange/components/OrangeGallery';
import OrangeEvents from './pages/orange/components/OrangeEvents';
import OrangeMenu from './pages/orange/components/OrangeMenu';

import SignIn from './shared/SignIn';
import AdminPanel from "./pages/admin/AdminPanel";

import TeatroAboutUs from "./pages/teatro/components/TeatroAboutUs";
import OrangeAboutUs from "./pages/orange/components/OrangeAboutUs";
import OrangeSpecialOffers from "./pages/orange/components/OrangeSpecialOffers";

import './global-styles.css';

export const App = () => {
  return (
    <Router history={history}>
      <ApplicationLoader />
      <Notification />
      <Switch>
        <Route exact path='/' render={() => <Redirect to='/teatro' />} />
        <Route exact path='/sign-in' component={SignIn} />
        <Route exact path='/admin' component={AdminPanel} />
        <Route exact path='/teatro' component={TeatroPage} />
        <Route exact path='/teatro/about-us' component={TeatroAboutUs} />
        <Route exact path='/teatro/gallery' component={TeatroGallery} />
        <Route exact path='/teatro/menu' component={TeatroMenu} />
        <Route exact path='/teatro/events' component={TeatroEvents} />
        <Route exact path='/teatro/catering' component={TeatroCatering} />
        <Route exact path='/orange' component={OrangePage} />
        <Route exact path='/orange/special-offers' component={OrangeSpecialOffers} />
        <Route exact path='/orange/about-us' component={OrangeAboutUs} />
        <Route exact path='/orange/gallery' component={OrangeGallery} />
        <Route exact path='/orange/menu' component={OrangeMenu} />
        <Route exact path='/orange/events' component={OrangeEvents} />
      </Switch>
    </Router>
  );
}

export default App;
