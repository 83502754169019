import React from 'react';
import { connect } from 'react-redux';

import Gallery from 'react-photo-gallery';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import Typography from './modules/components/Typography';
import AppAppBar from "./modules/views/AppAppBar"
import withRoot from './modules/withRoot';

import { getLang } from '../../../data/reducers/pageReducer';
import { constants } from '../../../data/constants';
import { withStyles } from '@material-ui/core';
import { allPhotos } from './OrangeGalleryPhotos';

const styles = theme => ({
    header: {
        margin: '50px 0',
        ['@media (max-width:750px)']: { // eslint-disable-line no-useless-computed-key
            margin: '30px 0',
            fontSize: '25px',
        }
    },
    root: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(4),
    },
    images: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexWrap: 'wrap',
    },
});

const OrangeGallery = ({
    lang,
    classes,
}) => {
    const mapToGalleryImages = images => images.map(x => ({ src: x, width: 1, height: 1 }));
    const photos = mapToGalleryImages(allPhotos);
    return (
        <React.Fragment>
            <AppAppBar />
            <Container>
                <Box sx={{ mt: 7, mb: 12 }}>
                    <Typography className={classes.header} variant="h3" gutterBottom align="center">
                        {constants.heading.orange}{' - '}
                        {lang === 'bg' ? 'Галерия' : 'Gallery'}
                    </Typography>
                    <div className={classes.images}>
                        <Gallery photos={photos} />
                    </div>
                </Box>
            </Container>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    lang: getLang(state),
})

const Comp = withStyles(styles)(OrangeGallery);
export default React.memo(connect(mapStateToProps)(withRoot(Comp)));
