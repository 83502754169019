import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import { Fade } from 'react-reveal';

import Typography from '../components/Typography';
import { getLang } from '../../../../../data/reducers/pageReducer';
import { constants } from '../../../../../data/constants';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  images: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexWrap: 'wrap',
  },
  imageWrapper: {
    cursor: 'default',
    position: 'relative',
    display: 'block',
    padding: 0,
    borderRadius: 0,
    height: '40vh',
    [theme.breakpoints.down('md')]: {
      width: '100% !important',
      height: 100,
    },
    '&:hover': {
      zIndex: 1,
    },
    '&:hover $imageBackdrop': {
      opacity: 0.15,
    },
    '&:hover $imageMarked': {
      opacity: 0,
    },
    '&:hover $imageTitle': {
      border: '4px solid currentColor',
      padding: '5px'
    },
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)} ${theme.spacing(4)} 14px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
});

function ProductCategories({
  lang,
  ...props
}) {
  const { classes } = props;

  const images = [
    {
      url:
        'https://source.unsplash.com/4_jhDO54BYg',
      title: lang === 'bg' ? 'Пресни продукти' : 'Fresh products',
      width: '40%',
    },
    {
      url:
        'https://source.unsplash.com/w6ftFbPCs9I',
      title: lang === 'bg' ? 'Ароматни супи' : 'Fragrant soups',
      width: '30%',
    },
    {
      url:
        'https://source.unsplash.com/-YHSwy6uqvk',
      title: lang === 'bg' ? 'Превъзходни ястия' : 'Excellent meals',
      width: '30%',
    },
    {
      url:
        'https://source.unsplash.com/I7A_pHLcQK8',
      title: lang === 'bg' ? 'Бургери за ценители' : 'Burgers for connoisseurs',
      width: '30%',
    },
    {
      url:
        'https://source.unsplash.com/Mzy-OjtCI70',
      title: lang === 'bg' ? 'Невероятни десерти' : 'Great desserts',
      width: '30%',
    },
    {
      url:
        'https://source.unsplash.com/69ilqMz0p1s',
      title: lang === 'bg' ? 'Висок клас кафе' : 'High class coffee',
      width: '40%',
    },
    {
      url:
        'https://source.unsplash.com/HjWzkqW1dgI',
      title: lang === 'bg' ? 'Свежи коктейли' : 'Refreshing cocktails',
      width: '40%',
    },
    {
      url:
        'https://source.unsplash.com/PeDrafNlY2Y',
      title: lang === 'bg' ? 'Качествен алкохол' : 'Quality alcohol',
      width: '30%',
    },
    {
      url:
        'https://source.unsplash.com/r8u3gSutOyw',
      title: lang === 'bg' ? 'Уютна обстановка' : 'Cosy atmosphere',
      width: '30%',
    },
  ];

  return (
    <Container className={classes.root} component="section">
      <Fade top>
        <Typography variant="h4" align="center" component="h2">
          {lang === 'bg' ? 'Защо ' : 'Why '}
          <span style={{ textDecoration: 'underline' }}>
            {constants.heading.teatro}?
          </span>
        </Typography>
      </Fade>
      <Fade left cascade>
        <div className={classes.images}>
          {images.map((image, index) => (
            <ButtonBase
              key={image.title}
              className={classes.imageWrapper}
              style={{
                width: image.width,
              }}
            >
              <div
                className={classes.imageSrc}
                style={{
                  backgroundImage: `url(${image.url})`,
                }}
              />
              <div className={classes.imageBackdrop} />
              <div className={classes.imageButton}>
                <Typography
                  component="h3"
                  variant="h6"
                  color="inherit"
                  className={classes.imageTitle}
                >
                  {image.title}
                  <div className={classes.imageMarked} />
                </Typography>
              </div>
            </ButtonBase>
          ))}
        </div>
      </Fade>
    </Container>
  );
}

const mapStateToProps = state => ({
  lang: getLang(state),
})

ProductCategories.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(ProductCategories));
