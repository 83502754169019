import {
    GET_TEATRO_EVENTS,
    GET_ORANGE_EVENTS,
    INSERT_ORANGE_EVENT,
    UPDATE_ORANGE_EVENT,
    DELETE_ORANGE_EVENT,
    INSERT_TEATRO_EVENT,
    UPDATE_TEATRO_EVENT,
    DELETE_TEATRO_EVENT,
} from "../actionTypes";

const teatroEventsInitialState = {
    all: [],
}

export const teatroEventsReducer = (state = teatroEventsInitialState, action) => {
    switch (action.type) {
        case GET_TEATRO_EVENTS: return {
            ...state,
            all: action.payload,
        }
        case INSERT_TEATRO_EVENT: return {
            ...state,
            all: [...state.all, action.payload],
        }
        case UPDATE_TEATRO_EVENT: {
            const foundIndex = state.all.findIndex(x => x._id === action.payload._id);
            if (foundIndex !== -1) {
                const copy = state.all.slice();
                copy.splice(foundIndex, 1, action.payload);
                return {
                    ...state,
                    all: copy,
                }
            } else {
                return state;
            }
        }
        case DELETE_TEATRO_EVENT: {
            const foundIndex = state.all.findIndex(x => x._id === action.payload);
            if (foundIndex !== -1) {
                const copy = state.all.slice();
                copy.splice(foundIndex, 1);
                return {
                    ...state,
                    all: copy,
                }
            } else {
                return state;
            }
        }
        default: return state;
    }
}

const orangeEventsInitialState = {
    all: [],
}

export const orangeEventsReducer = (state = orangeEventsInitialState, action) => {
    switch (action.type) {
        case GET_ORANGE_EVENTS: return {
            ...state,
            all: action.payload,
        };
        case INSERT_ORANGE_EVENT: return {
            ...state,
            all: [...state.all, action.payload],
        }
        case UPDATE_ORANGE_EVENT: {
            const foundIndex = state.all.findIndex(x => x._id === action.payload._id);
            if (foundIndex !== -1) {
                const copy = state.all.slice();
                copy.splice(foundIndex, 1, action.payload);
                return {
                    ...state,
                    all: copy,
                }
            } else {
                return state;
            }
        }
        case DELETE_ORANGE_EVENT: {
            const foundIndex = state.all.findIndex(x => x._id === action.payload);
            if (foundIndex !== -1) {
                const copy = state.all.slice();
                copy.splice(foundIndex, 1);
                return {
                    ...state,
                    all: copy,
                }
            } else {
                return state;
            }
        }
        default: return state;
    }
}
