import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { Button, FormControl, IconButton, MenuItem, Paper, Select, Tooltip, Typography, withStyles } from "@material-ui/core";

import {
    getTeatroMenu,
    addMealToTeatroMenu,
    updateTeatroMenuMeal,
    deleteMealFromTeatroMenu,
} from '../../../../../data/actions/menuActions';
import { getLang } from "../../../../../data/reducers/pageReducer";
import { getTeatroMenuCategories } from "../../../../../data/actions/templatesActions";

import AddNewMealDialog from "./TeatroMenuDialogs/AddNewMealDialog";
import CustomTable from "../../../../../shared/components/CustomTable";

import { styles } from './teatroMenuConstants';
import EditMealDialog from "./TeatroMenuDialogs/EditMealDialog";
import ConfirmDeleteMealDialog from "./TeatroMenuDialogs/ConfirmDeleteMealDialog";

const bgColumns = [
    { title: 'Ред', propName: 'orderId' },
    { title: 'Име', propName: 'name' },
    { title: 'Тип', propName: 'type' },
    { title: 'Порция', propName: 'netWeight', suffix: item => item?.quantityType?.bg || 'гр.' },
    { title: 'Цена', propName: 'price', suffix: () => ' лв.' },
    { title: '', propName: 'actions' },
]

const enColumns = [
    { title: 'Ред', propName: 'orderId' },
    { title: 'Име', propName: 'name' },
    { title: 'Тип', propName: 'type' },
    { title: 'Порция', propName: 'netWeight', suffix: item => item?.quantityType?.en || 'gr.' },
    { title: 'Цена', propName: 'price', suffix: () => ' bgn' },
    { title: '', propName: 'actions' },
]

const TeatroMenuTab = ({
    lang,
    menu,
    classes,
    getTeatroMenu,
    addMealToTeatroMenu,
    updateTeatroMenuMeal,
    getTeatroMenuCategories,
    deleteMealFromTeatroMenu,
}) => {
    const [categoryFilter, setCategoryFilter] = useState('all');
    const [openEditMealDialog, setOpenEditMealDialog] = useState(false);
    const [openAddNewMealDialog, setOpenAddNewMealDialog] = useState(false);
    const [openConfirmDeleteMealDialog, setOpenConfirmDeleteMealDialog] = useState(false);
    const [selectedMeal, setSelectedMeal] = useState('');
    
    useEffect(() => {
        getTeatroMenu();
        getTeatroMenuCategories();
    }, [getTeatroMenu, getTeatroMenuCategories]);
    
    const handleCategoryFilterChange = ev => setCategoryFilter(ev.target.value);

    const handleOpenEditMealDialog = () => setOpenEditMealDialog(true);
    const handleCloseEditMealDialog = () => setOpenEditMealDialog(false);
    
    const handleCloseAddNewMealDialog = () => setOpenAddNewMealDialog(false);
    const handleOpenAddNewMealDialog = () => setOpenAddNewMealDialog(true);
    
    const handleOpenConfirmDeleteMealDialog = meal => {
        setSelectedMeal(meal);
        setOpenConfirmDeleteMealDialog(true);
    }
    const handleCloseConfirmDeleteMealDialog = () => setOpenConfirmDeleteMealDialog(false);
    
    const handleDeleteMealClick = (mealId) => {
        deleteMealFromTeatroMenu(mealId);
        handleCloseConfirmDeleteMealDialog();
        setSelectedMeal('');
        topRef.current.scrollIntoView();
    }

    const handleEditMealClick = (meal) => {
        handleOpenEditMealDialog();
        setSelectedMeal(meal);
        topRef.current.scrollIntoView();
    }

    const handleAddMenuMeal = meal => {
        addMealToTeatroMenu(meal);
        handleCloseAddNewMealDialog();
        topRef.current.scrollIntoView();
    }

    const handleEditMeal = meal => {
        updateTeatroMenuMeal(meal);
        handleCloseEditMealDialog();
    }

    const topRef = useRef();

    const actions = item => [
        <IconButton
            key={1}
            color='primary'
            onClick={() => handleEditMealClick(item)}
        >
            <Tooltip title='Промени'>
                <EditOutlinedIcon />
            </Tooltip>
        </IconButton>,
        <IconButton
            key={2}
            color='secondary'
            onClick={() => handleOpenConfirmDeleteMealDialog(item)}
        >
            <Tooltip title='Изтрий'>
                <DeleteForeverOutlinedIcon />
            </Tooltip>
        </IconButton>
    ]

    const formattedPrices = menu?.meals?.map(x => ({ ...x, price: Number(x.price).toFixed(2) }));
    const orderedMeals = formattedPrices ? formattedPrices.sort((a, b) => a.orderId - b.orderId) : [];

    const nextDefaultMealOrderId = (orderedMeals.length > 0 ? +orderedMeals[orderedMeals.length - 1].orderId + 1 : 0).toString();

    const columns = lang === 'bg' ? bgColumns : enColumns;

    const categories = [...new Set(orderedMeals.map(x => x.type))];

    const filteredMeals = categoryFilter === 'all' ? orderedMeals : orderedMeals.filter(x => x.type === categoryFilter);

    return (
        <div>
            <div ref={topRef} />
            <Typography align='center' variant='body1'>
                {lang === 'bg' ? 'Филтрирай по категория' : 'Filter by category'}
            </Typography>
            <FormControl fullWidth>
                <Select
                    margin='dense'
                    variant='outlined'
                    value={categoryFilter}
                    style={{ marginBottom: '10px' }}
                    onChange={handleCategoryFilterChange}
                >
                    <MenuItem value='all'>{lang === 'bg' ? 'всички' : 'all'}</MenuItem>
                    {
                        categories && categories.map((x, i) => {
                            const [en, bg] = x.split('|');
                            const types = { en, bg };
                            return <MenuItem key={i} value={x}>{types[lang]}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
            <Paper className={classes.menuContainer}>
                <div className={classes.menuHeader}>
                    <div>
                        <IconButton
                            className={classes.addMealIcon}
                            onClick={handleOpenAddNewMealDialog}
                        >
                            <Tooltip title='Добави ястие'>
                                <AddBoxOutlinedIcon />
                            </Tooltip>
                        </IconButton>
                    </div>
                </div>
                <CustomTable
                    lang={lang}
                    columns={columns}
                    actions={actions}
                    data={filteredMeals}
                    maxLengthAllowed={60}
                />
            </Paper>
            <Button
                fullWidth
                color='primary'
                variant='outlined'
                onClick={handleOpenAddNewMealDialog}
            >
                Добави ново ястие
            </Button>
            <AddNewMealDialog
                menuId={0}
                open={openAddNewMealDialog}
                handleAdd={handleAddMenuMeal}
                handleClose={handleCloseAddNewMealDialog}
                nextDefaultOrderId={nextDefaultMealOrderId}
            />
            <EditMealDialog
                meal={selectedMeal}
                open={openEditMealDialog}
                handleUpdate={handleEditMeal}
                handleClose={handleCloseEditMealDialog}
            />
            <ConfirmDeleteMealDialog
                meal={selectedMeal}
                open={openConfirmDeleteMealDialog}
                handleDelete={handleDeleteMealClick}
                handleClose={handleCloseConfirmDeleteMealDialog}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    lang: getLang(state),
    menu: state.teatroMenu.menu,
})

const mapDispatchToProps = {
    getTeatroMenu,
    addMealToTeatroMenu,
    updateTeatroMenuMeal,
    getTeatroMenuCategories,
    deleteMealFromTeatroMenu,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TeatroMenuTab));
