import { IconButton, Paper, Tooltip, Typography } from "@material-ui/core"
import { EditOutlined, DeleteForeverOutlined } from '@material-ui/icons';

const styles = {
    paper: {
        margin: '20px 0',
        padding: '10px 20px',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '120px',
        justifyContent: 'space-between',
    },
    cardTop: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    cardBottom: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardBottomLeft: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    bottomLeftText: {
        marginRight: '10px',
    },
}

const OrangeSpecialOfferCard = ({
    lang,
    offer,
    handleOpenEditDialog,
    handleOpenConfirmDeleteDialog,
}) => {
    const priceText = lang === 'bg'
        ?
        `Цена: ${offer.price ? `${offer.price} лв.` : 'Няма'}`
        :
        `Price: ${offer.price ? `${offer.price} bgn` : 'None'}`;
    return (
        <Paper style={styles.paper} elevation={3}>
            <div style={styles.cardTop}>
                <Typography variant='h6'>{offer.title[lang]}</Typography>
                <div>
                    <Tooltip title='Промени'>
                        <IconButton onClick={handleOpenEditDialog} color='primary' size='small'>
                            <EditOutlined />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Изтрий'>
                        <IconButton onClick={handleOpenConfirmDeleteDialog} color='secondary' size='small'>
                            <DeleteForeverOutlined />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <div style={styles.cardContent}>
                <Typography variant='caption'>{offer.description[lang]}</Typography>
            </div>
            <div style={styles.cardBottom}>
                <div style={styles.cardBottomLeft}></div>
                <div>
                    <Typography variant='h6'>{priceText}</Typography>
                </div>
            </div>
        </Paper>
    )
}

export default OrangeSpecialOfferCard;
