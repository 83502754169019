import React, { useState } from 'react';
import {
    Select,
    Button,
    Dialog,
    MenuItem,
    InputLabel,
    withStyles,
    FormControl,
    DialogTitle,
    DialogActions,
    DialogContent,
} from '@material-ui/core';

const styles = theme => ({
    dropdown: {
        margin: '10px'
    }
})

function AddNewMenuDialog({
    open,
    classes,
    handleAdd,
    handleClose,
}) {
    const initialYear = new Date().getFullYear();
    const initialMonth = new Date().getMonth();
    const initialDate = new Date().getDate();
    const [year, setYear] = useState(initialYear);
    const [month, setMonth] = useState(initialMonth);
    const [date, setDate] = useState(initialDate);

    const clearLocalState = () => {
        setYear(initialYear);
        setMonth(initialMonth);
        setDate(initialDate);
    }

    const getYearOptions = () => {
        return [initialYear, initialYear + 1]
    }

    const getMonthOptions = () => {
        return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    }

    const getDateOptions = () => {
        const dates = [];
        const startDate = new Date(year, month, 1);
        while (startDate.getMonth() === month) {
            dates.push(startDate.getDate());
            startDate.setDate(startDate.getDate() + 1);
        }
        return dates;
    }

    const handleAddMenu = () => {
        const epoch = new Date(year, month, date).getTime()
        const menu = { date: epoch, meals: [] };
        clearLocalState();
        handleAdd(menu);
    }

    const handleCloseDialog = () => {
        clearLocalState();
        handleClose();
    }

    const handleYearChange = ev => setYear(ev.target.value);
    const handleMonthChange = ev => setMonth(ev.target.value);
    const handleDateChange = ev => setDate(ev.target.value);

    return (
        <div>
            <Dialog fullWidth maxWidth='sm' open={open} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Добавяне ново меню</DialogTitle>
                <DialogContent>
                    <FormControl className={classes.dropdown}>
                        <InputLabel id="year-select-label">Година</InputLabel>
                        <Select
                            value={year}
                            margin='dense'
                            onChange={handleYearChange}
                            labelId="year-select-label"
                        >
                            {getYearOptions().map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.dropdown}>
                        <InputLabel id="month-select-label">Месец</InputLabel>
                        <Select
                            value={month}
                            margin='dense'
                            onChange={handleMonthChange}
                            labelId="month-select-label"
                        >
                            {getMonthOptions().map(x => <MenuItem key={x} value={x}>{x + 1}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.dropdown}>
                        <InputLabel id="date-select-label">Дата</InputLabel>
                        <Select
                            value={date}
                            margin='dense'
                            onChange={handleDateChange}
                            labelId="date-select-label"
                        >
                            {getDateOptions().map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} variant='outlined' color="secondary">
                        Отказ
                    </Button>
                    <Button onClick={handleAddMenu} variant='outlined' color="primary">
                        Добави
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default withStyles(styles)(AddNewMenuDialog);
