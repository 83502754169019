import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Fade } from 'react-reveal';

import Button from '../components/Button';
import Typography from '../components/Typography';
import callIcon from '../../../../../assets/call.svg';
import menuIcon from '../../../../../assets/menu.svg';
import smileyIcon from '../../../../../assets/smiley.svg';
import { getLang } from '../../../../../data/reducers/pageReducer';
import { corporatePhoneTeatro } from '../../../../../data/constants';

const styles = (theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
    overflow: 'hidden',
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  title: {
    marginBottom: theme.spacing(14),
  },
  number: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  image: {
    height: 55,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
    opacity: 0.7,
  },
  button: {
    marginTop: theme.spacing(8),
  },
});

function ProductHowItWorks({
  lang,
  ...props
}) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <img
          src="/static/themes/onepirate/productCurvyLines.png"
          className={classes.curvyLines}
          alt="curvy lines"
        />
        <Fade top>
          <Typography
            variant="h4"
            align='center'
            className={classes.title}
            component="h2"
          >
            {lang === 'bg' ? 'Доставка за вкъщи - бързо и лесно' : 'Food Delivery - Fast and Easy'}
          </Typography>
        </Fade>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <Fade left duration={1000} cascade>
                <div className={classes.item}>
                  <div className={classes.number}>1.</div>
                  <img
                    src={callIcon}
                    alt="call"
                    className={classes.image}
                  />
                  <Typography variant="h5" align="center">
                    {lang === 'bg' ? 'Обаждате се на телефон' : 'Call our phone number'}
                    <br />
                    {corporatePhoneTeatro}
                  </Typography>
                </div>
              </Fade>
            </Grid>
            <Grid item xs={12} md={4}>
              <Fade bottom duration={1000} cascade>
                <div className={classes.item}>
                  <div className={classes.number}>2.</div>
                  <img
                    src={menuIcon}
                    alt="menu"
                    className={classes.image}
                  />
                  <Typography variant="h5" align="center">
                    {lang === 'bg' ? 'Поръчвате избраните от вас продукти' : 'Order what you want'}
                  </Typography>
                </div>
              </Fade>
            </Grid>
            <Grid item xs={12} md={4}>
              <Fade right duration={1000} cascade>
                <div className={classes.item}>
                  <div className={classes.number}>3.</div>
                  <img
                    src={smileyIcon}
                    alt="happy"
                    className={classes.image}
                  />
                  <Typography variant="h5" align="center">
                    {
                      lang === 'bg'
                        ? 'Получавате и плащате храната си на посочен от вас адрес'
                        : 'You receive the products and pay the bill on given address'
                    }
                  </Typography>
                </div>
              </Fade>
            </Grid>
          </Grid>
        </div>
        <Fade bottom>
          <Button
            color="secondary"
            size="large"
            variant="contained"
            className={classes.button}
            component="a"
            href="/teatro/menu"
          >
            {lang === 'bg' ? 'Меню' : 'Menu'}
          </Button>
        </Fade>
      </Container>
    </section>
  );
}

const mapStateToProps = state => ({
  lang: getLang(state),
})

ProductHowItWorks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(ProductHowItWorks));
