// application loader
export const SHOW_APPLICATION_LOADER = 'SHOW_APPLICATION_LOADER';
export const HIDE_APPLICATION_LOADER = 'HIDE_APPLICATION_LOADER';

// notification
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

// user
export const LOG_USER = 'LOG_USER';
export const SIGN_OUT = 'SIGN_OUT';

// language
export const CHANGE_PAGE_LANGAUGE = 'CHANGE_PAGE_LANGAUGE';

// teatroLunchMenus
export const GET_TEATRO_LUNCH_MENUS = 'GET_TEATRO_LUNCH_MENUS';
export const INSERT_BLANK_TEATRO_LUNCH_MENU = 'INSERT_BLANK_TEATRO_LUNCH_MENU';
export const INSERT_MEAL_TO_TEATRO_LUNCH_MENU = 'INSERT_MEAL_TO_TEATRO_LUNCH_MENU';
export const DELETE_TEATRO_LUNCH_MENU = 'DELETE_TEATRO_LUNCH_MENU';
export const ADD_MEAL_TO_TEATRO_LUNCH_MENU = 'ADD_MEAL_TO_TEATRO_LUNCH_MENU';
export const DELETE_MEAL_FROM_TEATRO_LUNCH_MENU = 'DELETE_MEAL_FROM_TEATRO_LUNCH_MENU';

// teatroMenus
export const GET_TEATRO_MENU = 'GET_TEATRO_MENU';
export const ADD_MEAL_TO_TEATRO_MENU = 'ADD_MEAL_TO_TEATRO_MENU';
export const UPDATE_TEATRO_MENU_MEAL = 'UPDATE_TEATRO_MENU_MEAL';
export const DELETE_MEAL_FROM_TEATRO_MENU = 'DELETE_MEAL_FROM_TEATRO_MENU';

// orangeMenus
export const GET_ORANGE_MENU = 'GET_ORANGE_MENU';
export const ADD_OFFER_TO_ORANGE_MENU = 'ADD_OFFER_TO_ORANGE_MENU';
export const EDIT_OFFER_OF_ORANGE_MENU = 'EDIT_OFFER_OF_ORANGE_MENU';
export const DELETE_OFFER_FROM_ORANGE_MENU = 'DELETE_OFFER_FROM_ORANGE_MENU';

// templates
export const GET_TEATRO_LUNCH_MENU_CATEGORIES = 'GET_TEATRO_LUNCH_MENU_CATEGORIES';
export const ADD_TEATRO_LUNCH_MENU_CATEGORY = 'ADD_TEATRO_LUNCH_MENU_CATEGORY';
export const DELETE_TEATRO_LUNCH_MENU_CATEGORY = 'DELETE_TEATRO_LUNCH_MENU_CATEGORY';
export const GET_TEATRO_MENU_CATEGORIES = 'GET_TEATRO_MENU_CATEGORIES';
export const ADD_TEATRO_MENU_CATEGORY = 'ADD_TEATRO_MENU_CATEGORY';
export const DELETE_TEATRO_MENU_CATEGORY = 'DELETE_TEATRO_MENU_CATEGORY';
export const GET_ORANGE_MENU_CATEGORIES = 'GET_ORANGE_MENU_CATEGORIES';
export const ADD_ORANGE_MENU_CATEGORY = 'ADD_ORANGE_MENU_CATEGORY';
export const DELETE_ORANGE_MENU_CATEGORY = 'DELETE_ORANGE_MENU_CATEGORY';

// events
export const GET_TEATRO_EVENTS = 'GET_TEATRO_EVENTS';
export const GET_ORANGE_EVENTS = 'GET_ORANGE_EVENTS';
export const INSERT_TEATRO_EVENT = 'INSERT_TEATRO_EVENT';
export const INSERT_ORANGE_EVENT = 'INSERT_ORANGE_EVENT';
export const UPDATE_TEATRO_EVENT = 'UPDATE_TEATRO_EVENT';
export const UPDATE_ORANGE_EVENT = 'UPDATE_ORANGE_EVENT';
export const DELETE_TEATRO_EVENT = 'DELETE_TEATRO_EVENT';
export const DELETE_ORANGE_EVENT = 'DELETE_ORANGE_EVENT';

// offers
export const GET_ORANGE_SPECIAL_OFFERS = 'GET_ORANGE_SPECIAL_OFFERS';
export const INSERT_ORANGE_SPECIAL_OFFER = 'INSERT_ORANGE_SPECIAL_OFFER';
export const UPDATE_ORANGE_SPECIAL_OFFER = 'UPDATE_ORANGE_SPECIAL_OFFER';
export const DELETE_ORANGE_SPECIAL_OFFER = 'DELETE_ORANGE_SPECIAL_OFFER';
