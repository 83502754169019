import {
    ADD_ORANGE_MENU_CATEGORY,
    ADD_TEATRO_MENU_CATEGORY,
    GET_TEATRO_MENU_CATEGORIES,
    GET_ORANGE_MENU_CATEGORIES,
    DELETE_TEATRO_MENU_CATEGORY,
    DELETE_ORANGE_MENU_CATEGORY,
    ADD_TEATRO_LUNCH_MENU_CATEGORY,
    GET_TEATRO_LUNCH_MENU_CATEGORIES,
    DELETE_TEATRO_LUNCH_MENU_CATEGORY,
} from "../actionTypes"

const initialState = {
    teatroLunchMenuCategories: [],
    teatroMenuCategories: [],
    orangeMenuCategories: [],
}

export const templatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TEATRO_LUNCH_MENU_CATEGORIES: return {
            ...state,
            teatroLunchMenuCategories: action.payload,
        };
        case ADD_TEATRO_LUNCH_MENU_CATEGORY: return {
            ...state,
            teatroLunchMenuCategories: [...state.teatroLunchMenuCategories, action.payload],
        };
        case DELETE_TEATRO_LUNCH_MENU_CATEGORY: {
            const foundItem = state.teatroLunchMenuCategories.find(x => x._id === action.payload);
            if (foundItem) {
                const index = state.teatroLunchMenuCategories.indexOf(foundItem);
                const copy = state.teatroLunchMenuCategories.slice();
                copy.splice(index, 1);
                return {
                    ...state,
                    teatroLunchMenuCategories: copy,
                }
            } else {
                return state;
            }
        }

        case GET_TEATRO_MENU_CATEGORIES: return {
            ...state,
            teatroMenuCategories: action.payload,
        };
        case ADD_TEATRO_MENU_CATEGORY: return {
            ...state,
            teatroMenuCategories: [...state.teatroMenuCategories, action.payload],
        };
        case DELETE_TEATRO_MENU_CATEGORY: {
            const foundItem = state.teatroMenuCategories.find(x => x._id === action.payload);
            if (foundItem) {
                const index = state.teatroMenuCategories.indexOf(foundItem);
                const copy = state.teatroMenuCategories.slice();
                copy.splice(index, 1);
                return {
                    ...state,
                    teatroMenuCategories: copy,
                };
            } else {
                return state;
            }
        }

        case GET_ORANGE_MENU_CATEGORIES: return {
            ...state,
            orangeMenuCategories: action.payload,
        };
        case ADD_ORANGE_MENU_CATEGORY: return {
            ...state,
            orangeMenuCategories: [...state.orangeMenuCategories, action.payload],
        };
        case DELETE_ORANGE_MENU_CATEGORY: {
            const foundItem = state.orangeMenuCategories.find(x => x._id === action.payload);
            if (foundItem) {
                const index = state.orangeMenuCategories.indexOf(foundItem);
                const copy = state.orangeMenuCategories.slice();
                copy.splice(index, 1);
                return {
                    ...state,
                    orangeMenuCategories: copy,
                };
            } else {
                return state;
            }
        }

        default: return state;
    }
}
