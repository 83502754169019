import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ErrorMessage, Formik } from 'formik';
import {
    Button,
    Dialog,
    Select,
    MenuItem,
    TextField,
    withStyles,
    FormControl,
    DialogTitle,
    DialogActions,
    DialogContent,
} from '@material-ui/core';
import { getTeatroLunchMenuCategories } from '../../../../../../data/actions/templatesActions';
import { showNotification } from '../../../../../../data/actions/notificationActions';
import { notificationTypes } from '../../../../../../data/constants';
import { validateNotEmptyInputs, validateNumberInputs } from '../../../../../../data/validations';

const styles = theme => ({
    input: {
        margin: '10px 0',
    },
    multiInput: {
        width: '45%',
    },
    oneRowMultiInputs: {
        margin: '10px 0',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
})

const defaultQuantityType = { en: 'gr.', bg: 'гр.' };
const quantityTypes = [
    defaultQuantityType,
    { en: 'ml.', bg: 'мл.' },
    { en: 'pcs.', bg: 'бр.' },
]

function AddNewMealDialog({
    open,
    menuId,
    classes,
    handleAdd,
    categories,
    handleClose,
    showNotification,
    getTeatroLunchMenuCategories,
}) {
    useEffect(() => {
        getTeatroLunchMenuCategories();
    }, [getTeatroLunchMenuCategories]);

    const handleAddMeal = values => {
        const { orderId, enName, bgName, mealType, netWeight, price, enIngredients, bgIngredients, quantityType } = values;
        if (orderId && bgName && mealType && netWeight && price) {
            const meal = {
                isAvailable: true,
                orderId,
                name: {
                    en: enName || bgName,
                    bg: bgName,
                },
                type: mealType,
                ingredients: {
                    en: enIngredients || bgIngredients,
                    bg: bgIngredients || '-',
                },
                netWeight,
                price: Number(price).toFixed(2),
                quantityType,
            }
            handleAdd(menuId, meal);
        } else {
            showNotification('required inputs are empty', notificationTypes.error);
        }
    }

    const handleCloseDialog = resetForm => {
        handleClose();
        resetForm();
    }

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    orderId: '',
                    price: '',
                    bgName: '',
                    enName: '',
                    bgIngredients: '',
                    enIngredients: '',
                    mealType: '',
                    netWeight: '',
                    quantityType: defaultQuantityType,
                }}
                validate={values => ({
                    ...validateNotEmptyInputs([
                        'orderId',
                        'price',
                        'bgName',
                        'mealType',
                        'netWeight',
                        'quantityType',
                    ], values),
                    ...validateNumberInputs(['orderId', 'price', 'netWeight'], values),
                })}
                onSubmit={(values, { resetForm }) => {
                    handleAddMeal(values);
                    resetForm();
                }}
            >
                {
                    ({
                        values,
                        errors,
                        touched,
                        resetForm,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                    }) => {
                        const {
                            bgName,
                            enName,
                            bgIngredients,
                            enIngredients,
                            price,
                            orderId,
                            mealType,
                            netWeight,
                            quantityType,
                        } = values;
                        return (
                            <form>
                                <Dialog fullWidth maxWidth='sm' open={open} onClose={() => handleCloseDialog(resetForm)} aria-labelledby="form-dialog-title">
                                    <DialogTitle id="form-dialog-title">Добавяне на ново ястие</DialogTitle>
                                    <DialogContent>
                                        <div className={classes.input}>
                                            <TextField
                                                fullWidth
                                                margin='dense'
                                                name='orderId'
                                                value={orderId}
                                                variant='outlined'
                                                onBlur={handleBlur}
                                                label="Номер подредба"
                                                onChange={handleChange}
                                            />
                                            {
                                                errors.orderId && touched.orderId &&
                                                <ErrorMessage component='div' className='invalid-field-msg' name='orderId' />
                                            }
                                        </div>
                                        <div className={classes.input}>
                                            <FormControl fullWidth>
                                                <Select
                                                    margin='dense'
                                                    name='mealType'
                                                    value={mealType}
                                                    variant='outlined'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                >
                                                    {categories.map((x, i) => <MenuItem key={i} value={`${x.en}|${x.bg}`}>{x.bg}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                            {
                                                errors.mealType && touched.mealType &&
                                                <ErrorMessage component='div' className='invalid-field-msg' name='mealType' />
                                            }
                                        </div>
                                        <div className={classes.oneRowMultiInputs}>
                                            <div className={classes.multiInput}>
                                                <TextField
                                                    fullWidth
                                                    autoFocus
                                                    name='bgName'
                                                    margin='dense'
                                                    value={bgName}
                                                    variant='outlined'
                                                    onBlur={handleBlur}
                                                    label="Име (Български)"
                                                    onChange={handleChange}
                                                />
                                                {
                                                    errors.bgName && touched.bgName &&
                                                    <ErrorMessage component='div' className='invalid-field-msg' name='bgName' />
                                                }
                                            </div>
                                            <div className={classes.multiInput}>
                                                <TextField
                                                    fullWidth
                                                    name='enName'
                                                    margin='dense'
                                                    value={enName}
                                                    variant='outlined'
                                                    onBlur={handleBlur}
                                                    label="Име (Английски)"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.oneRowMultiInputs}>
                                            <div className={classes.multiInput}>
                                                <TextField
                                                    rows={3}
                                                    fullWidth
                                                    multiline
                                                    margin='dense'
                                                    variant='outlined'
                                                    onBlur={handleBlur}
                                                    name='bgIngredients'
                                                    value={bgIngredients}
                                                    onChange={handleChange}
                                                    label="Съставки (Български)"
                                                />
                                                {
                                                    errors.bgIngredients && touched.bgIngredients &&
                                                    <ErrorMessage component='div' className='invalid-field-msg' name='bgIngredients' />
                                                }
                                            </div>
                                            <div className={classes.multiInput}>
                                                <TextField
                                                    rows={3}
                                                    fullWidth
                                                    multiline
                                                    margin='dense'
                                                    variant='outlined'
                                                    onBlur={handleBlur}
                                                    name='enIngredients'
                                                    value={enIngredients}
                                                    onChange={handleChange}
                                                    label="Съставки (Английски)"
                                                />
                                                {
                                                    errors.enIngredients && touched.enIngredients &&
                                                    <ErrorMessage component='div' className='invalid-field-msg' name='enIngredients' />
                                                }
                                            </div>
                                        </div>
                                        <div className={classes.oneRowMultiInputs}>
                                            <TextField
                                                margin='dense'
                                                name='netWeight'
                                                value={netWeight}
                                                variant='outlined'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                className={classes.multiInput}
                                                label={`Количество (${quantityType.bg})`}
                                            />
                                            {
                                                errors.netWeight && touched.netWeight &&
                                                <ErrorMessage component='div' className='invalid-field-msg' name='netWeight' />
                                            }
                                            <FormControl className={classes.multiInput}>
                                                <Select
                                                    margin='dense'
                                                    variant='outlined'
                                                    name='quantityType'
                                                    onBlur={handleBlur}
                                                    value={quantityType}
                                                    onChange={handleChange}
                                                >
                                                    {quantityTypes.map((x, i) => <MenuItem key={i} value={x}>{x.bg}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                            {
                                                errors.quantityType && touched.quantityType &&
                                                <ErrorMessage component='div' className='invalid-field-msg' name='quantityType' />
                                            }
                                        </div>
                                        <div className={classes.input}>
                                            <TextField
                                                fullWidth
                                                name='price'
                                                value={price}
                                                margin='dense'
                                                variant='outlined'
                                                label="Цена (лв.)"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            {
                                                errors.price && touched.price &&
                                                <ErrorMessage component='div' className='invalid-field-msg' name='price' />
                                            }
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => handleCloseDialog(resetForm)} variant='outlined' color="secondary">
                                            Отказ
                                        </Button>
                                        <Button type='submit' onClick={handleSubmit} variant='outlined' color="primary">
                                            Добави
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </form>
                        )
                    }
                }
            </Formik>
        </div>
    );
}

const mapStateToProps = state => ({
    categories: state.templates.teatroLunchMenuCategories,
})

const mapDispatchToProps = {
    showNotification,
    getTeatroLunchMenuCategories,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddNewMealDialog));
