import * as React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Tooltip,
  TextField,
  IconButton,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import withRoot from '../pages/teatro/components/modules/withRoot';
import AppForm from '../pages/teatro/components/modules/views/AppForm';
import Typography from '../pages/teatro/components/modules/components/Typography';
import { getUser } from '../data/actions/userActions';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: theme.spacing(6),
  },
  input: {
    width: '51%',
    marginTop: theme.spacing(3),
  },
  button: {
    width: '60%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}));

function SignIn({
  getUser,
}) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom align="center">
            Sign In
          </Typography>
        </React.Fragment>
        <Formik
          initialValues={{
            username: '',
            password: '',
            showPassword: false
          }}
          onSubmit={values => getUser(values.username, values.password)}
        >
          {
            ({ values, setFieldValue, handleChange, handleSubmit, handleBlur, ...props }) =>
              <form onSubmit={handleSubmit} className={classes.form}>
                <div className={classes.input}>
                  <TextField
                    fullWidth
                    label='Username'
                    name='username'
                    variant='outlined'
                    value={values.username}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.input}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      name='password'
                      type={values.showPassword ? 'text' : 'password'}
                      value={values.password}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setFieldValue('showPassword', !values.showPassword)}
                            edge="end"
                          >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                  </FormControl>
                </div>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={handleSubmit}
                  className={classes.button}
                >
                  Sign In
                </Button>
              </form>
          }
        </Formik>
        <Typography align="center">
          <Tooltip title='Contact your developer for more info'>
            <span>Forgot password?</span>
          </Tooltip>
        </Typography>
      </AppForm>
    </React.Fragment>
  );
}

const mapDispatchToProps = {
  getUser,
}

export default connect(null, mapDispatchToProps)(withRoot(SignIn));
