export const validateNotEmptyInputs = (names, values) => {
    const errors = {};
    names.forEach(x => {
        if (values[x].length === 0) {
            errors[x] = 'Задължително поле';
        }
    })
    return errors;
}

export const validateNumberInputs = (names, values) => {
    const errors = {};
    names.forEach(x => {
        if (isNaN(values[x])) {
            errors[x] = 'Стойността трябва да е число';
        }
    })
    return errors;
}

export const validateInputMinLength = (names, values, length) => {
    const errors = {};
    names.forEach(x => {
        if (values[x].length < length) {
            errors[x] = 'Минимален брой символи: ' + length;
        }
    })
    return errors;
}

export const validateInputMaxLength = (names, values, length) => {
    const errors = {};
    names.forEach(x => {
        if (values[x].length > length) {
            errors[x] = 'Максимален брой символи: ' + length;
        }
    })
    return errors;
}

export const isValidEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
