import { ErrorMessage, Formik } from 'formik';
import {
    Dialog,
    Button,
    Checkbox,
    TextField,
    withStyles,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    FormControlLabel,
} from '@material-ui/core';
import {
    validateNumberInputs,
    validateInputMaxLength,
    validateInputMinLength,
    validateNotEmptyInputs,
} from '../../../../../data/validations';

const styles = theme => ({
    input: {
        margin: '10px 0',
    },
    timeInputsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    timeInput: {
        width: '18%',
    },
})

const OrangeEventCreateDialog = ({
    open,
    classes,
    handleClose,
    handleCreate,
}) => {
    const n = new Date();
    const weekAfter = new Date(n.getFullYear(), n.getMonth(), n.getDate(), 20, 0, 0, 0);
    weekAfter.setDate(weekAfter.getDate() + 7);

    const handleCreateClick = values => {
        const {
            enTitle,
            bgTitle,
            enDescription,
            bgDescription,
            year,
            month,
            date,
            hours,
            minutes,
            price,
            minimalConsumption,
            reservationRequired,
        } = values;
        const event = {
            title: {
                en: enTitle,
                bg: bgTitle,
            },
            description: {
                en: enDescription,
                bg: bgDescription,
            },
            date: new Date(year, month - 1, date, hours, minutes, 0, 0).getTime(),
            price: Number(price).toFixed(2),
            minimalConsumption,
            reservationRequired,
        }
        handleCreate(event);
    }

    const handleCloseClick = () => handleClose();

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    enTitle: '',
                    bgTitle: '',
                    enDescription: '',
                    bgDescription: '',
                    year: weekAfter.getFullYear(),
                    month: weekAfter.getMonth() + 1,
                    date: weekAfter.getDate(),
                    hours: weekAfter.getHours(),
                    minutes: weekAfter.getMinutes(),
                    price: '',
                    minimalConsumption: '',
                    reservationRequired: false,
                }}
                validate={values => ({
                    ...validateNotEmptyInputs([
                        'date',
                        'month',
                        'year',
                        'hours',
                        'minutes',
                        'price',
                        'minimalConsumption',
                        'enTitle',
                        'bgTitle',
                        'enDescription',
                        'bgDescription',
                    ], values),
                    ...validateNumberInputs([
                        'date',
                        'month',
                        'year',
                        'hours',
                        'minutes',
                        'price',
                        'minimalConsumption',
                    ], values),
                    ...validateInputMinLength(['year'], values, 4),
                    ...validateInputMaxLength(['year'], values, 4),
                    ...validateInputMaxLength(['date', 'month', 'hours', 'minutes'], values, 2),
                })}
                onSubmit={(values, { resetForm }) => {
                    handleCreateClick(values);
                    resetForm();
                }}
            >
                {
                    ({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                    }) => {
                        const {
                            date,
                            month,
                            year,
                            hours,
                            minutes,
                            bgTitle,
                            enTitle,
                            bgDescription,
                            enDescription,
                            price,
                            minimalConsumption,
                            reservationRequired,
                        } = values;
                        return (
                            <form>
                                <Dialog fullWidth maxWidth='sm' open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                                    <DialogTitle id="form-dialog-title">Създаване на ново събитие</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>Препоръка: попълнете всички полета</DialogContentText>
                                        <div className={classes.timeInputsWrapper}>
                                            <div className={classes.timeInput}>
                                                <TextField
                                                    autoFocus
                                                    name='date'
                                                    value={date}
                                                    label="Дата"
                                                    margin='dense'
                                                    variant='outlined'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {
                                                    errors.date && touched.date &&
                                                    <ErrorMessage component='div' name='date' className='invalid-field-msg' />
                                                }
                                            </div>
                                            <div className={classes.timeInput}>
                                                <TextField
                                                    name='month'
                                                    value={month}
                                                    label="Месец"
                                                    margin='dense'
                                                    variant='outlined'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {
                                                    errors.month && touched.month &&
                                                    <ErrorMessage component='div' name='month' className='invalid-field-msg' />
                                                }
                                            </div>
                                            <div className={classes.timeInput}>
                                                <TextField
                                                    name='year'
                                                    value={year}
                                                    label="Година"
                                                    margin='dense'
                                                    variant='outlined'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {
                                                    errors.year && touched.year &&
                                                    <ErrorMessage component='div' name='year' className='invalid-field-msg' />
                                                }
                                            </div>
                                            <div className={classes.timeInput}>
                                                <TextField
                                                    name='hours'
                                                    value={hours}
                                                    label="Час"
                                                    margin='dense'
                                                    variant='outlined'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {
                                                    errors.hours && touched.hours &&
                                                    <ErrorMessage component='div' name='hours' className='invalid-field-msg' />
                                                }
                                            </div>
                                            <div className={classes.timeInput}>
                                                <TextField
                                                    name='minutes'
                                                    margin='dense'
                                                    label="Минути"
                                                    value={minutes}
                                                    variant='outlined'
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {
                                                    errors.minutes && touched.minutes &&
                                                    <ErrorMessage component='div' name='minutes' className='invalid-field-msg' />
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            <TextField
                                                fullWidth
                                                name='bgTitle'
                                                margin='dense'
                                                value={bgTitle}
                                                variant='outlined'
                                                onBlur={handleBlur}
                                                label="Име (Български)"
                                                onChange={handleChange}
                                            />
                                            {
                                                errors.bgTitle && touched.bgTitle &&
                                                <ErrorMessage component='div' name='bgTitle' className='invalid-field-msg' />
                                            }
                                        </div>
                                        <div>
                                            <TextField
                                                rows={3}
                                                fullWidth
                                                multiline
                                                margin='dense'
                                                variant='outlined'
                                                onBlur={handleBlur}
                                                name='bgDescription'
                                                value={bgDescription}
                                                onChange={handleChange}
                                                label="Описание (Български)"
                                            />
                                            {
                                                errors.bgDescription && touched.bgDescription &&
                                                <ErrorMessage component='div' name='bgDescription' className='invalid-field-msg' />
                                            }
                                        </div>
                                        <div>
                                            <TextField
                                                fullWidth
                                                name='enTitle'
                                                margin='dense'
                                                value={enTitle}
                                                variant='outlined'
                                                onBlur={handleBlur}
                                                label="Име (Английски)"
                                                onChange={handleChange}
                                            />
                                            {
                                                errors.enTitle && touched.enTitle &&
                                                <ErrorMessage component='div' name='enTitle' className='invalid-field-msg' />
                                            }
                                        </div>
                                        <div>
                                            <TextField
                                                rows={3}
                                                multiline
                                                fullWidth
                                                margin='dense'
                                                variant='outlined'
                                                onBlur={handleBlur}
                                                name='enDescription'
                                                value={enDescription}
                                                onChange={handleChange}
                                                label="Описание (Английски)"
                                            />
                                            {
                                                errors.enDescription && touched.enDescription &&
                                                <ErrorMessage component='div' name='enDescription' className='invalid-field-msg' />
                                            }
                                        </div>
                                        <div>
                                            <TextField
                                                fullWidth
                                                margin='dense'
                                                variant='outlined'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name='minimalConsumption'
                                                className={classes.input}
                                                value={minimalConsumption}
                                                label="Минимална консумация (лв.)"
                                            />
                                            {
                                                errors.minimalConsumption && touched.minimalConsumption &&
                                                <ErrorMessage component='div' name='minimalConsumption' className='invalid-field-msg' />
                                            }
                                        </div>
                                        <div>
                                            <TextField
                                                fullWidth
                                                name='price'
                                                value={price}
                                                margin='dense'
                                                label="Цена (лв.)"
                                                variant='outlined'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                className={classes.input}
                                            />
                                            {
                                                errors.price && touched.price &&
                                                <ErrorMessage component='div' name='price' className='invalid-field-msg' />
                                            }
                                        </div>
                                        <div>
                                            <FormControlLabel
                                                label="Задължителна резервация"
                                                control={
                                                    <Checkbox
                                                        margin='dense'
                                                        color="primary"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        name='reservationRequired'
                                                        checked={reservationRequired}
                                                    />
                                                }
                                            />
                                            {
                                                errors.reservationRequired && touched.reservationRequired &&
                                                <ErrorMessage component='div' name='reservationRequired' className='invalid-field-msg' />
                                            }
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseClick} variant='outlined' color="secondary">
                                            Отказ
                                        </Button>
                                        <Button type='submit' onClick={handleSubmit} variant='outlined' color="primary">
                                            Създай
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </form>
                        )
                    }
                }
            </Formik>
        </div>
    )
}

export default withStyles(styles)(OrangeEventCreateDialog);
