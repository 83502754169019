import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { orangeTabs } from '../HeaderOrange';
import OrangeMenuTab from './OrangeMenuTab/OrangeMenuTab';
import OrangeEventsTab from './OrangeEventsTab/OrangeEventsTab';
import OrangeSpecialOffersTab from './OrangeSpecialOffersTab/OrangeSpecialOffersTab';
import OrangeSettingsTab from './OrangeSettingsTab/OrangeSettingsTab';

const styles = (theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '40px 16px',
  },
});

function getTabContent(tab) {
  switch (tab) {
    case orangeTabs.menu: return <OrangeMenuTab />;
    case orangeTabs.events: return <OrangeEventsTab />;
    case orangeTabs.settings: return <OrangeSettingsTab />;
    case orangeTabs.specialOffers: return <OrangeSpecialOffersTab />;
    default: return false;
  }
}

function ContentOrange({
  currentTab,
  ...props
}) {
  return getTabContent(currentTab)
}

export default withStyles(styles)(ContentOrange);