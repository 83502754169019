import { showNotification } from '../data/actions/notificationActions';
import { renewSession, signOutAction } from '../data/actions/userActions';
import { notificationTypes } from '../data/constants';
import { history, store } from '../data/store';

// const baseUrl = 'http://localhost:5000/';
const baseUrl = 'https://teatro-orange-api.herokuapp.com/';

const buildUrl = endpoint => {
    return `${baseUrl}${endpoint}`;
}

const timeDiffAllowed = 30 * 60 * 1000; // 30 minutes

const validateExpiration = async () => {
    const state = await store.getState();
    const date = state.user.date;
    if (date) {
        if (Date.now() - date > timeDiffAllowed) {
            store.dispatch(signOutAction());
            history.push('/sign-in');
            store.dispatch(showNotification('session expired, please sign in again', notificationTypes.error));
            throw Error('session expired');
        } else {
            store.dispatch(renewSession());
        }
    } else {
        history.push('/sign-in');
        store.dispatch(showNotification('please sign in', notificationTypes.error));
        throw Error('not signed in');
    }
}

export const httpService = {
    get: async (endpoint) => {
        const url = buildUrl(endpoint);
        const response = await fetch(url);
        const json = response.json();
        return json;
    },
    post: async (endpoint, body) => {
        if (endpoint !== 'subscribe') {
            await validateExpiration();
        }
        const url = buildUrl(endpoint);
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        const json = await response.json();
        return json;
    },
    patch: async (endpoint, body) => {
        await validateExpiration();
        const url = buildUrl(endpoint);
        const response = await fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        const json = await response.json();
        return json;
    },
    delete: async (endpoint, body) => {
        await validateExpiration();
        const url = buildUrl(endpoint);
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        const json = await response.json();
        return json;
    }
}
