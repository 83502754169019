import { useEffect, useState } from "react";
import { connect } from "react-redux"
import { Button } from "@material-ui/core";

import OrangeEventCard from "./OrangeEventCard";
import OrangeEventEditDialog from "./OrangeEventEditDialog";
import OrangeEventCreateDialog from "./OrangeEventCreateDialog";
import OrangeEventConfirmDeleteDialog from "./OrangeEventConfirmDeleteDialog";
import {
    getOrangeEvents,
    insertOrangeEvent,
    updateOrangeEvent,
    deleteOrangeEvent,
} from "../../../../../data/actions/eventActions";

const OrangeEventsTab = ({
    lang,
    events,
    getOrangeEvents,
    insertOrangeEvent,
    updateOrangeEvent,
    deleteOrangeEvent,
}) => {
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState('');
    const [openEditEventDialog, setOpenEditEventDialog] = useState(false);
    const [openConfirmDeleteEventDialog, setOpenConfirmDeleteEventDialog] = useState(false);

    const handleOpenCreateDialog = () => setOpenCreateDialog(true);
    const handleCloseCreateDialog = () => setOpenCreateDialog(false);

    const handleCreateEvent = event => {
        insertOrangeEvent(event);
        handleCloseCreateDialog();
    }

    const handleUpdateEvent = event => {
        updateOrangeEvent(event);
        handleCloseEditEventDialog();
    }

    const handleDeleteEvent = eventId => {
        deleteOrangeEvent(eventId);
        handleCloseConfirmDeleteDialog();
    }

    const handleOpenEditEventDialog = event => {
        setSelectedEvent(event);
        setOpenEditEventDialog(true);
    }
    const handleCloseEditEventDialog = () => {
        setSelectedEvent('');
        setOpenEditEventDialog(false);
    }

    const handleOpenConfirmDeleteEventDialog = event => {
        setSelectedEvent(event);
        setOpenConfirmDeleteEventDialog(true);
    }
    const handleCloseConfirmDeleteDialog = () => {
        setSelectedEvent('');
        setOpenConfirmDeleteEventDialog(false);
    }

    useEffect(() => {
        getOrangeEvents();
    }, [getOrangeEvents]);

    const futureEvents = events.filter(x => x.date > Date.now());
    const pastEvents = events.filter(x => x.date <= Date.now());

    return (
        <div>
            <Button
                fullWidth
                color='primary'
                variant='outlined'
                onClick={handleOpenCreateDialog}
            >
                Създай ново събитие
            </Button>
            {
                futureEvents.map(event => {
                    return (
                        <OrangeEventCard
                            lang={lang}
                            event={event}
                            key={event._id}
                            isFuture={true}
                            handleOpenEditDialog={() => handleOpenEditEventDialog(event)}
                            handleOpenConfirmDeleteDialog={() => handleOpenConfirmDeleteEventDialog(event)}
                        />
                    )
                })
            }
            {
                pastEvents.map(event => {
                    return (
                        <OrangeEventCard
                            lang={lang}
                            event={event}
                            key={event._id}
                            isFuture={false}
                            handleOpenEditDialog={() => handleOpenEditEventDialog(event)}
                            handleOpenConfirmDeleteDialog={() => handleOpenConfirmDeleteEventDialog(event)}
                        />
                    )
                })
            }
            <OrangeEventCreateDialog
                open={openCreateDialog}
                handleCreate={handleCreateEvent}
                handleClose={handleCloseCreateDialog}
            />
            <OrangeEventEditDialog
                event={selectedEvent}
                open={openEditEventDialog}
                handleUpdate={handleUpdateEvent}
                handleClose={handleCloseEditEventDialog}
            />
            <OrangeEventConfirmDeleteDialog
                event={selectedEvent}
                handleDelete={handleDeleteEvent}
                open={openConfirmDeleteEventDialog}
                handleClose={handleCloseConfirmDeleteDialog}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    lang: state.page.language,
    events: state.orangeEvents.all,
})

const mapDispatchToProps = {
    getOrangeEvents,
    insertOrangeEvent,
    updateOrangeEvent,
    deleteOrangeEvent,
}

export default connect(mapStateToProps, mapDispatchToProps)(OrangeEventsTab);
