import l_room1 from '../../../assets/photos/orange/room/landscape/1.JPG';
import l_room2 from '../../../assets/photos/orange/room/landscape/2.JPG';
import l_room3 from '../../../assets/photos/orange/room/landscape/3.JPG';
import l_room4 from '../../../assets/photos/orange/room/landscape/40.JPG';
import l_room5 from '../../../assets/photos/orange/room/landscape/50.JPG';
import l_room6 from '../../../assets/photos/orange/room/landscape/60.JPG';
import l_room7 from '../../../assets/photos/orange/room/landscape/70.JPG';
import l_room8 from '../../../assets/photos/orange/room/landscape/80.JPG';
import l_room9 from '../../../assets/photos/orange/room/landscape/90.JPG';
import l_room10 from '../../../assets/photos/orange/room/landscape/100.JPG';
import l_room11 from '../../../assets/photos/orange/room/landscape/110.JPG';
import l_room12 from '../../../assets/photos/orange/room/landscape/120.JPG';
import l_room13 from '../../../assets/photos/orange/room/landscape/130.JPG';
import l_room14 from '../../../assets/photos/orange/room/landscape/140.JPG';
import l_room15 from '../../../assets/photos/orange/room/landscape/150.JPG';
import l_room16 from '../../../assets/photos/orange/room/landscape/160.JPG';
import l_room17 from '../../../assets/photos/orange/room/landscape/170.JPG';
import l_room18 from '../../../assets/photos/orange/room/landscape/180.JPG';
import l_room19 from '../../../assets/photos/orange/room/landscape/190.JPG';
import l_room20 from '../../../assets/photos/orange/room/landscape/200.JPG';
import l_room21 from '../../../assets/photos/orange/room/landscape/210.JPG';
import l_room22 from '../../../assets/photos/orange/room/landscape/220.JPG';
import l_room23 from '../../../assets/photos/orange/room/landscape/230.JPG';
import l_room24 from '../../../assets/photos/orange/room/landscape/240.JPG';
import l_room25 from '../../../assets/photos/orange/room/landscape/250.JPG';
import l_room26 from '../../../assets/photos/orange/room/landscape/260.JPG';
import l_room27 from '../../../assets/photos/orange/room/landscape/270.JPG';
import l_room28 from '../../../assets/photos/orange/room/landscape/280.JPG';
import l_room29 from '../../../assets/photos/orange/room/landscape/290.JPG';
import l_room30 from '../../../assets/photos/orange/room/landscape/300.JPG';
import l_room31 from '../../../assets/photos/orange/room/landscape/310.JPG';
import l_room32 from '../../../assets/photos/orange/room/landscape/320.JPG';
import l_room33 from '../../../assets/photos/orange/room/landscape/330.JPG';
import l_room34 from '../../../assets/photos/orange/room/landscape/340.JPG';
import l_room35 from '../../../assets/photos/orange/room/landscape/350.JPG';
import l_room36 from '../../../assets/photos/orange/room/landscape/360.JPG';
import l_room37 from '../../../assets/photos/orange/room/landscape/370.JPG';
import l_room38 from '../../../assets/photos/orange/room/landscape/380.JPG';
import l_room39 from '../../../assets/photos/orange/room/landscape/390.JPG';
import l_room40 from '../../../assets/photos/orange/room/landscape/400.JPG';
import l_room41 from '../../../assets/photos/orange/room/landscape/410.JPG';
import l_room42 from '../../../assets/photos/orange/room/landscape/420.JPG';
import l_room43 from '../../../assets/photos/orange/room/landscape/430.JPG';
import l_room44 from '../../../assets/photos/orange/room/landscape/440.JPG';
import l_room45 from '../../../assets/photos/orange/room/landscape/450.JPG';
import l_room46 from '../../../assets/photos/orange/room/landscape/460.JPG';
import l_room47 from '../../../assets/photos/orange/room/landscape/470.JPG';
import l_room48 from '../../../assets/photos/orange/room/landscape/480.JPG';
import l_room49 from '../../../assets/photos/orange/room/landscape/490.JPG';
import l_room50 from '../../../assets/photos/orange/room/landscape/500.JPG';
import l_room51 from '../../../assets/photos/orange/room/landscape/510.JPG';
import l_room52 from '../../../assets/photos/orange/room/landscape/520.JPG';
import l_room53 from '../../../assets/photos/orange/room/landscape/530.JPG';
import l_room54 from '../../../assets/photos/orange/room/landscape/540.JPG';
import l_room55 from '../../../assets/photos/orange/room/landscape/550.JPG';
import l_room56 from '../../../assets/photos/orange/room/landscape/560.JPG';
import l_room57 from '../../../assets/photos/orange/room/landscape/570.JPG';

import p_room1 from '../../../assets/photos/orange/room/portrait/1.JPG';
import p_room2 from '../../../assets/photos/orange/room/portrait/2.JPG';
import p_room3 from '../../../assets/photos/orange/room/portrait/3.JPG';
import p_room4 from '../../../assets/photos/orange/room/portrait/4.JPG';
import p_room5 from '../../../assets/photos/orange/room/portrait/5.JPG';
import p_room6 from '../../../assets/photos/orange/room/portrait/6.JPG';
import p_room7 from '../../../assets/photos/orange/room/portrait/7.JPG';
import p_room8 from '../../../assets/photos/orange/room/portrait/8.jpg';
import p_room9 from '../../../assets/photos/orange/room/portrait/9.jpg';
import p_room10 from '../../../assets/photos/orange/room/portrait/10.JPG';
import p_room11 from '../../../assets/photos/orange/room/portrait/11.jpg';

import p_cocktail1 from '../../../assets/photos/orange/cocktails/portrait/1.JPG';
import p_cocktail2 from '../../../assets/photos/orange/cocktails/portrait/2.JPG';
import p_cocktail3 from '../../../assets/photos/orange/cocktails/portrait/3.JPG';

export const landscapeRoom = [
    l_room1, 
    l_room2, 
    l_room3,
    l_room4,
    l_room5,
    l_room6,
    l_room7,
    l_room8,
    l_room9,
    l_room10,
    l_room11,
    l_room12,
    l_room13,
    l_room14,
    l_room15,
    l_room16,
    l_room17,
    l_room18,
    l_room19,
    l_room20,
    l_room21,
    l_room22,
    l_room23,
    l_room24,
    l_room25,
    l_room26,
    l_room27,
    l_room28,
    l_room29,
    l_room30,
    l_room31,
    l_room32,
    l_room33,
    l_room34,
    l_room35,
    l_room36,
    l_room37,
    l_room38,
    l_room39,
    l_room40,
    l_room41,
    l_room42,
    l_room43,
    l_room44,
    l_room45,
    l_room46,
    l_room47,
    l_room48,
    l_room49,
    l_room50,
    l_room51,
    l_room52,
    l_room53,
    l_room54,
    l_room55,
    l_room56,
    l_room57,
]
export const portraitRoom = [p_room1, p_room2, p_room3, p_room4, p_room5, p_room6, p_room7, p_room8, p_room9, p_room10, p_room11];
export const portraitCocktail = [p_cocktail1, p_cocktail2, p_cocktail3];

export const allPhotos = [
    ...landscapeRoom,
    ...portraitRoom,
    ...portraitCocktail,
];
