import { Paper, Typography } from "@material-ui/core";
import { Fade } from 'react-reveal';

const styles = {
    paper: {
        margin: '20px 0',
        padding: '10px 20px',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '120px',
        justifyContent: 'space-between',
    },
    cardTop: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    cardBottom: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardBottomLeft: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    bottomLeftText: {
        marginRight: '10px',
    },
    date: {
        color: '#00c92b',
    },
}

const OrangeEventCardClient = ({
    lang,
    event,
}) => {
    const formatDate = (date) => {
        const d = new Date(date);
        const hours = d.getHours().toString().length === 1 ? `0${d.getHours()}` : d.getHours();
        const minutes = d.getMinutes().toString().length === 1 ? `${d.getMinutes()}0` : d.getMinutes();
        return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()} - ${hours}:${minutes}`;
    }
    const date = formatDate(event.date);
    const minimalConsumptionText = lang === 'bg'
        ?
        `Минимална консумация: ${event.minimalConsumption && event.minimalConsumption !== '0' ? `${event.minimalConsumption} лв.` : 'НЯМА'}`
        :
        `Minimal consumption: ${event.minimalConsumption && event.minimalConsumption !== '0' ? `${event.minimalConsumption} bgn` : 'NONE'}`
    const reservationText = lang === 'bg'
        ?
        `Задължителна резервация: ${event.reservationRequired ? 'ДА' : 'НЕ'}`
        :
        `Reservation required: ${event.reservationRequired ? 'YES' : 'NO'}`;
    const entranceText = lang === 'bg'
        ?
        `Вход: ${event.price && event.price !== '0' ? `${event.price} лв.` : 'Свободен'}`
        :
        `Entrance: ${event.price && event.price !== '0' ? `${event.price} bgn` : 'FREE'}`;
    return (
        <Paper style={styles.paper} elevation={3}>
            <Fade top>
                <div style={styles.cardTop}>
                    <Typography variant='h6'>
                        {event.title[lang]} - <span style={styles.date}>{date}{lang === 'bg' ? 'ч.' : ''}</span>
                    </Typography>
                </div>
                <div style={styles.cardContent}>
                    <Typography variant='caption'>{event.description[lang]}</Typography>
                </div>
            </Fade>
            <div style={styles.cardBottom}>
                <Fade bottom>
                    <div style={styles.cardBottomLeft}>
                        <Typography variant='body2' style={styles.bottomLeftText}>{minimalConsumptionText}</Typography>
                        <Typography variant='body2' style={styles.bottomLeftText}>{reservationText}</Typography>
                    </div>
                    <div>
                        <Typography variant='h6'>{entranceText}</Typography>
                    </div>
                </Fade>
            </div>
        </Paper>
    )
}

export default OrangeEventCardClient;
