import * as React from 'react';
import PropTypes from 'prop-types';
import { Fade } from 'react-reveal';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import teatroPhoto from '../../../../../assets/photos/orange/orange-photo.JPG';
import { getLang } from '../../../../../data/reducers/pageReducer';
import { constants } from '../../../../../data/constants';

const styles = (theme) => ({
  background: {
    backgroundImage: `url(${teatroPhoto})`,
    backgroundColor: '#7fc7d9', // Average color of the background image.
    backgroundPosition: 'center',
  },
  button: {
    minWidth: 200,
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
  heading: {
    fontSize: '30px',
    marginTop: '20px',
    ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '40px',
      marginTop: '0',
    }
  },
});

function ProductHero({
  lang,
  handleArrowDownClick,
  ...props
}) {
  const { classes } = props;

  return (
    <ProductHeroLayout backgroundClassName={classes.background} onArrowClick={handleArrowDownClick}>
      {/* Increase the network loading priority of the background image. */}
      <Fade top>
        <Typography className={classes.heading} color="inherit" align="center" variant="h2">
          {constants.heading.orange}
        </Typography>
      </Fade>
      <Fade bottom>
        <Typography color="inherit" align="center" variant="h5" className={classes.h5}>
          {
            lang === 'bg'
              ? 'Насладете се на приятна музика, страхотен интериор, качествени напитки и перфектно обслужване.'
              : 'Enjoy good music, great interior, quality drinks and perfect service.'
          }
        </Typography>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          className={classes.button}
          component="a"
          href="/orange/menu"
        >
          {lang === 'bg' ? 'Меню' : 'Menu'}
        </Button>
      </Fade>
    </ProductHeroLayout>
  );
}

const mapStateToProps = state => ({
  lang: getLang(state),
})

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(ProductHero));
