import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import { Paper, withStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';

import withRoot from './modules/withRoot';
import AppAppBar from "./modules/views/AppAppBar"
import Typography from './modules/components/Typography';

import { constants } from '../../../data/constants';
import { getLang } from '../../../data/reducers/pageReducer';
import { getOrangeMenu } from '../../../data/actions/menuActions';

import CustomTable from '../../../shared/components/CustomTable';

const styles = {
    header: {
        margin: '50px 0',
        ['@media (max-width:750px)']: { // eslint-disable-line no-useless-computed-key
            margin: '30px 0',
            fontSize: '25px',
        }
    },
    mealGroupHeader: {
        marginBottom: '10px',
    },
    mealGroupContainer: {
        margin: '0 0 20px 0',
        padding: '20px 10px 10px',
    }
}

const columnWidths = ['40%', '30%', '15%', '15%'];

const enMenuColumns = [
    { title: 'Name', propName: 'name' },
    { title: 'Ingredients', propName: 'ingredients' },
    { title: 'Portion', propName: 'netWeight', suffix: item => item.isDrink ? ' ml.' : 'gr.' },
    { title: 'Price', propName: 'price', suffix: () => ' bgn' },
]

const bgMenuColumns = [
    { title: 'Име', propName: 'name' },
    { title: 'Съставки', propName: 'ingredients' },
    { title: 'Порция', propName: 'netWeight', suffix: item => item.isDrink ? ' мл.' : 'гр.' },
    { title: 'Цена', propName: 'price', suffix: () => ' лв.' },
]

const OrangeMenu = ({
    lang,
    menu,
    classes,
    getOrangeMenu,
}) => {

    useEffect(() => {
        getOrangeMenu();
    }, [getOrangeMenu]);

    const groupByType = offers => {
        const grouped = {};
        offers && offers.forEach(offer => {
            if (Object.keys(grouped).includes(offer.type)) {
                grouped[offer.type].push(offer);
            } else {
                grouped[offer.type] = [offer];
            }
        })
        // sort each group by their orderIds
        Object.keys(grouped).forEach(key => {
            grouped[key].sort((a, b) => a.orderId - b.orderId);
        })
        return grouped;
    }

    const sortByOrderId = grouped => {
        const getLowestOrderId = offers => offers ? offers[0].orderId : undefined;
        const sorted = [];
        Object.keys(grouped).forEach(key => {
            if (sorted.length > 0) {
                const currentOrderId = getLowestOrderId(grouped[key]);
                const firstGroup = sorted[0];
                const firstGroupOrderId = getLowestOrderId(firstGroup);
                if (currentOrderId < firstGroupOrderId) {
                    sorted.unshift(grouped[key]);
                } else {
                    sorted.push(grouped[key]);
                }
            } else {
                sorted.push(grouped[key]);
            }
        })
        return sorted;
    }

    const groupedSortedOffers = sortByOrderId(groupByType(menu.offers));
    const columns = lang === 'bg' ? bgMenuColumns : enMenuColumns;

    return (
        <React.Fragment>
            <AppAppBar />
            <Container>
                <Box sx={{ mt: 7, mb: 12 }}>
                    <Typography className={classes.header} variant="h3" gutterBottom align="center">
                        {constants.heading.orange}{' - '}
                        {lang === 'bg' ? 'Меню' : 'Menu'}
                    </Typography>
                    {
                        groupedSortedOffers && groupedSortedOffers.map((offers, i) => {
                            const [en, bg] = offers[0].type.split('|');
                            const types = { en, bg };
                            let containsIngredeints = false;
                            for (const meal of offers) {
                                if (meal.ingredients.bg !== '-' || meal.ingredients.en !== '-') {
                                    containsIngredeints = true;
                                    break;
                                }
                            }
                            const menuCols = [...columns];
                            if (!containsIngredeints) {
                                const index = menuCols.findIndex(x => x.propName === 'ingredients');
                                menuCols.splice(index, 1);
                            }
                            return (
                                <Paper key={i} className={classes.mealGroupContainer}>
                                    <Typography
                                        variant='h6'
                                        align='center'
                                        className={classes.mealGroupHeader}
                                    >
                                        {types[lang]}
                                    </Typography>
                                    <CustomTable
                                        lang={lang}
                                        data={offers}
                                        columns={menuCols}
                                        maxLengthAllowed={60}
                                        columnWidths={columnWidths}
                                    />
                                </Paper>
                            )
                        })
                    }
                </Box>
            </Container>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    lang: getLang(state),
    menu: state.orangeMenu.menu,
})

const mapDispatchToProps = {
    getOrangeMenu,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRoot(OrangeMenu)));
