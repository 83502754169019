import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { pageReducer } from "./pageReducer";
import { cateringReducer } from "./cateringReducer";
import { templatesReducer } from "./templatesReducer";
import {
    orangeEventsReducer,
    teatroEventsReducer,
} from "./eventReducer";
import {
    orangeMenuReducer,
    teatroLunchMenuReducer,
    teatroMenuReducer,
} from "./menuReducer";
import { orangeOffersReducer } from "./offerReducer";
import { notificationReducer } from "./notificationReducer";
import { applicationLoaderReducer } from "./applicationLoaderReducer";

export const createRootReducer = () => combineReducers({
    page: pageReducer,
    user: userReducer,
    catering: cateringReducer,
    teatroMenu: teatroMenuReducer,
    teatroLunchMenu: teatroLunchMenuReducer,
    templates: templatesReducer,
    orangeMenu: orangeMenuReducer,
    teatroEvents: teatroEventsReducer,
    orangeEvents: orangeEventsReducer,
    orangeOffers: orangeOffersReducer,
    notification: notificationReducer,
    applicationLoader: applicationLoaderReducer,
})
