import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Fade } from 'react-reveal';

import Typography from '../components/Typography';
import { getLang } from '../../../../../data/reducers/pageReducer';
import mealIcon from '../../../../../assets/fork_and_spoon.svg';
import serviceIcon from '../../../../../assets/service.svg';
import moneyIcon from '../../../../../assets/money.svg';
import productCurvyLines from '../../../../../assets/productCurvyLines.png';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'default',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});

function ProductValues({
  lang,
  id,
  ...props
}) {
  const { classes } = props;

  return (
    <section className={classes.root} id={id}>
      <Container className={classes.container}>
        <img
          src={productCurvyLines}
          className={classes.curvyLines}
          alt="curvy lines"
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Fade left duration={1000} cascade>
              <div className={classes.item}>
                <img
                  className={classes.image}
                  src={mealIcon}
                  alt="meal"
                />
                <Typography align='center' variant="h6" className={classes.title}>
                  {lang === 'bg' ? 'Вкусна Храна' : 'Delicious Food'}
                </Typography>
                <Typography align='center' variant="h5">
                  {
                    lang === 'bg'
                      ?
                      'Вкусни рецепти и пресни продукти приготвени от професионалните ни готвачи'
                      :
                      'Delicious food and fresh products, prepared from our professional chefs'
                  }
                </Typography>
              </div>
            </Fade>
          </Grid>
          <Grid item xs={12} md={4}>
            <Fade top duration={1000} cascade>
              <div className={classes.item}>
                <img
                  className={classes.image}
                  src={serviceIcon}
                  alt="service"
                />
                <Typography align='center' variant="h6" className={classes.title}>
                  {lang === 'bg' ? 'Качествено Обслужване' : 'Quality Service'}
                </Typography>
                <Typography align='center' variant="h5">
                  {
                    lang === 'bg'
                      ?
                      'Усмихнат и приветлив персонал на ваше разположение'
                      :
                      'Fresh and nice service ready to make you feel special'
                  }
                </Typography>
              </div>
            </Fade>
          </Grid>
          <Grid item xs={12} md={4}>
            <Fade right duration={1000} cascade>
              <div className={classes.item}>
                <img
                  className={classes.image}
                  src={moneyIcon}
                  alt="prices"
                />
                <Typography align='center' variant="h6" className={classes.title}>
                  {lang === 'bg' ? 'Конкурентни Цени' : 'Competitive Prices'}
                </Typography>
                <Typography align='center' variant="h5">
                  {
                    lang === 'bg'
                      ?
                      'Опитайте нашите уникални предложения на досъпни цени'
                      :
                      'Try out our unique offers on competitive prices'
                  }
                </Typography>
              </div>
            </Fade>
          </Grid>
        </Grid>
      </Container>
    </section >
  );
}

const mapStateToProps = state => ({
  lang: getLang(state),
})

ProductValues.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(ProductValues));
