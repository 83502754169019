import { subscribeService } from "../../services/subscribeService";
import { notificationTypes } from "../constants";
import { hideApplicationLoader, showApplicationLoader } from "./applicationLoaderActions";
import { showNotification } from "./notificationActions";

export const subscribeUser = email => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        await subscribeService.subscribe(email);
        dispatch(showNotification('successfully subscribed', notificationTypes.success));
    } catch (err) {
        console.warn('subscribeUser:', err);
        dispatch(showNotification('could not subscribe', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

export const unsubscribeUser = token => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        await subscribeService.unsubscribe(token);
        dispatch(showNotification('successfully unsubsribed', notificationTypes.success));
    } catch (err) {
        console.warn('unsubscribeUser:', err);
        dispatch(showNotification('could not unsubscribe', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

export const sendLunchMenuToSubscribers = meals => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        await subscribeService.sendLunchMenuToSubscribers(meals);
        dispatch(showNotification('successfully sent', notificationTypes.success));
    } catch (err) {
        console.warn('sendLunchMenuToSubscribers:', err);
        dispatch(showNotification('could not send', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

export const sendTeatroEventToSubscribers = event => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        await subscribeService.sendTeatroEventToSubscribers(event);
        dispatch(showNotification('successfully sent', notificationTypes.success));
    } catch (err) {
        console.warn('sendTeatroEventToSubscribers:', err);
        dispatch(showNotification('could not send', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

export const sendOrangeEventToSubscribers = event => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        await subscribeService.sendOrangeEventToSubscribers(event);
        dispatch(showNotification('successfully sent', notificationTypes.success));
    } catch (err) {
        console.warn('sendOrangeEventToSubscribers:', err);
        dispatch(showNotification('could not send', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}
