import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import { Fade } from 'react-reveal';

import Typography from '../components/Typography';
import TextField from '../components/TextField';
import Button from '../components/Button';
import { subscribeUser } from '../../../../../data/actions/subscribeActions';
import { getLang } from '../../../../../data/reducers/pageReducer';
import { isValidEmail } from '../../../../../data/validations';
import { showNotification } from '../../../../../data/actions/notificationActions';
import { notificationTypes } from '../../../../../data/constants';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(10),
    marginBottom: 0,
    display: 'flex',
  },
  cardWrapper: {
    zIndex: 1,
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.warning.main,
    padding: theme.spacing(8, 3),
  },
  cardContent: {
    maxWidth: 400,
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  button: {
    width: '100%',
  },
  imagesWrapper: {
    position: 'relative',
  },
  imageDots: {
    position: 'absolute',
    top: -67,
    left: -67,
    right: 0,
    bottom: 0,
    width: '100%',
    background: 'url(/static/onepirate/productCTAImageDots.png)',
  },
  image: {
    position: 'absolute',
    top: -28,
    left: -28,
    right: 0,
    bottom: 0,
    width: '100%',
    maxWidth: 600,
  },
});

function ProductCTA({
  lang,
  subscribeUser,
  showNotification,
  ...props
}) {
  const { classes } = props;
  const [email, setEmail] = React.useState('');

  const handleSubmit = ev => {
    ev.preventDefault();
    if (isValidEmail(email)) {
      subscribeUser(email);
    } else {
      showNotification('invalid email', notificationTypes.warning);
    }
  };

  const handleChangeEmail = ev => {
    setEmail(ev.target.value);
  }

  return (
    <Container className={classes.root} component="section">
      <Grid container>
        <Grid item xs={12} md={6} className={classes.cardWrapper}>
          <div className={classes.card}>
            <Fade top cascade>
              <form onSubmit={handleSubmit} className={classes.cardContent}>
                <Typography variant="h4" component="h4" gutterBottom>
                  {lang === 'bg' ? 'Абонирайте се.' : 'Subscribe'}
                </Typography>
                <Typography variant="h5">
                  {
                    lang === 'bg'
                      ? 'Ще ви изпращаме имейли с всяко обедно меню, както и информация за интересни събития.'
                      : 'We will send you emails with each lunch menu and information about interesting events.'
                  }
                </Typography>
                <TextField
                  noBorder
                  className={classes.textField}
                  placeholder={lang === 'bg' ? "Вашият имейл" : 'Your email'}
                  variant="standard"
                  value={email}
                  onChange={handleChangeEmail}
                />
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.button}
                >
                  {lang === 'bg' ? 'Абонирай ме сега' : 'Subscribe me now'}
                </Button>
              </form>
            </Fade>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.imagesWrapper}>
          <Hidden mdDown>
            <div className={classes.imageDots} />
            <img
              src="https://source.unsplash.com/u3ajSXhZM_U"
              alt="call to action"
              className={classes.image}
            />
          </Hidden>
        </Grid>
      </Grid>
    </Container>
  );
}

const mapStateToProps = state => ({
  lang: getLang(state),
})

const mapDispatchToProps = {
  subscribeUser,
  showNotification,
}

ProductCTA.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProductCTA));
