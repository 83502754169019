import {
    Paper,
    Table,
    Tooltip,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    withStyles,
    TableContainer,
} from '@material-ui/core';

const styles = theme => ({
    tableRowHeader: {
        fontWeight: '600',
    }
})

const CustomTable = ({
    data,
    lang,
    columns,
    actions,
    classes,
    columnWidths,
    maxLengthAllowed,
}) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                {
                    columnWidths && (
                        <colgroup>
                            {columnWidths.map((x, i) => <col key={i} style={{ width: x }} />)}
                        </colgroup>
                    )
                }
                <TableHead>
                    <TableRow>
                        {
                            columns.map((col, colId) => (
                                <TableCell
                                    key={colId}
                                    className={classes.tableRowHeader}
                                >
                                    {col.title}
                                </TableCell>
                            ))
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data && data.map((row, rowId) => {
                            return (
                                <TableRow hover key={rowId}>
                                    {
                                        columns.map((col, colId) => {
                                            if (typeof row[col.propName] === 'object') {
                                                if (row[col.propName][lang].length > maxLengthAllowed) {
                                                    return (
                                                        <Tooltip key={colId} title={row[col.propName][lang]}>
                                                            <TableCell key={colId}>
                                                                {`${col.prefix ?? ''}${row[col.propName][lang].slice(0, maxLengthAllowed)}...`}
                                                            </TableCell>
                                                        </Tooltip>
                                                    )
                                                } else {
                                                    return (
                                                        <TableCell key={colId}>
                                                            {`${col.prefix ?? ''}${row[col.propName][lang]}`}
                                                        </TableCell>
                                                    )
                                                }
                                            } else {
                                                if (col.propName === 'actions') {
                                                    return (
                                                        <TableCell key={colId}>
                                                            {actions(row)}
                                                        </TableCell>
                                                    )
                                                } else {
                                                    if (row[col.propName].length > maxLengthAllowed) {
                                                        return (
                                                            <Tooltip key={colId} title={row[col.propName]}>
                                                                <TableCell key={colId}>
                                                                    {`${col.prefix ?? ''}${row[col.propName].slice(0, maxLengthAllowed)}...`}
                                                                </TableCell>
                                                            </Tooltip>
                                                        )
                                                    } else {
                                                        if (row[col.propName].includes('|')) {
                                                            const splitted = row[col.propName].split('|');
                                                            const obj = {
                                                                en: splitted[0],
                                                                bg: splitted[1],
                                                            }
                                                            return <TableCell key={colId}>{obj[lang]}</TableCell>
                                                        } else {
                                                            return <TableCell key={colId}>{`${col.prefix ?? ''}${row[col.propName]} ${col.suffix ? col.suffix(row) : ''}`}</TableCell>
                                                        }
                                                    }
                                                }
                                            }
                                        })
                                    }
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer >
    )
}

export default withStyles(styles)(CustomTable);
