import * as React from 'react';
import ProductCategories from './modules/views/ProductCategories';
import ProductSmokingHero from './modules/views/ProductSmokingHero';
import ProductHero from './modules/views/ProductHero';
import ProductValues from './modules/views/ProductValues';
import ProductHowItWorks from './modules/views/ProductHowItWorks';
import ProductCTA from './modules/views/ProductCTA';
import AppAppBar from './modules/views/AppAppBar';
import withRoot from './modules/withRoot';

function TeatroHome() {
  const id = 'second-banner-id';

  const scrollDown = () => {
    document.getElementById(id)
      ?.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <React.Fragment>
      <AppAppBar />
      <ProductHero handleArrowDownClick={scrollDown} />
      <ProductValues id={id} />
      <ProductCategories />
      <ProductHowItWorks />
      <ProductCTA />
      <ProductSmokingHero />
    </React.Fragment>
  );
}

export default withRoot(TeatroHome);
