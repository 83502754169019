import teatroLogo from '../assets/teatro-logo.svg';
import orangeLogo from '../assets/orange-logo.svg';

const getTeatroLogoStyle = () => {
    const mq = window.matchMedia("(max-width: 400px)");
    if (mq.matches) {
        return {
            margin: '-70px 0 -90px -30px',
            width: 200,
            height: 200,
        }
    }
    else {
        return {
            margin: '-80px 0 -100px -30px',
            width: 250,
            height: 250,
        }
    }
}

const getOrangeLogoStyle = () => {
    const mq = window.matchMedia("(max-width: 400px)");
    if (mq.matches) {
        return {
            margin: '-50px 0 -60px 0',
            width: 150,
            height: 150,
        }
    }
    else {
        return {
            margin: '-70px 0 -80px 0',
            width: 200,
            height: 200,
        }
    }
}

export const pages = {
    teatro: {
        path: '/teatro',
        name: 'teatro',
    },
    orange: {
        path: '/orange',
        name: 'orange',
    },
}

export const logos = {
    teatro: <img src={teatroLogo} alt='teatro-logo' style={getTeatroLogoStyle()} />,
    orange: <img src={orangeLogo} alt='orange-logo' style={getOrangeLogoStyle()} />
}

export const constants = {
    heading: {
        teatro: <>Bar<span style={{ color: 'red' }}>&</span>Dinner Teatro</>,
        orange: <>Shisha<span style={{ color: 'red' }}>&</span>Bar Orange</>,
    },
}

export const corporatePhoneTeatro = '+359887780004';
export const corporatePhoneOrange = '+359878604327';
export const corporateAddress = lang => lang === 'bg' ? 'гр. Смолян, бул. България 8' : 'Smolyan, blvd. Bulgaria 8';
export const googleMapsTeatro = 'https://www.google.com/maps/place/%22Teatro%22+Bar+%26+DInner/@41.5742249,24.7140008,17.3z/data=!4m5!3m4!1s0x0:0x3ea10af1bb683ef9!8m2!3d41.5744444!4d24.7147222';
export const googleMapsOrange = 'https://www.google.com/maps/place/ORANGE+SHISHA+%26+BAR/@41.5744528,24.7147294,17z/data=!4m5!3m4!1s0x0:0x59f9283eac14f61e!8m2!3d41.5745331!4d24.7147187';
export const domainName = 'www.teatro-orange.com';
export const clientUrl = `https://${domainName}`;
export const clientTeatroMenuUrl = clientUrl + '/teatro/menu';

export const languages = {
    bg: {
        code: 'bg',
        title: 'Български',
    },
    en: {
        code: 'en',
        title: 'English',
    }
}

export const notificationTypes = {
    error: 'error',
    warning: 'warning',
    information: 'info',
    success: 'success',
}

export const getDayOfWeek = (day, lang) => {
    switch (day) {
        case 1: return lang === 'bg' ? 'Понеделник' : 'Monday';
        case 2: return lang === 'bg' ? 'Вторник' : 'Tuesday';
        case 3: return lang === 'bg' ? 'Сряда' : 'Wednesday';
        case 4: return lang === 'bg' ? 'Четвъртък' : 'Thursday';
        case 5: return lang === 'bg' ? 'Петък' : 'Friday';
        case 6: return lang === 'bg' ? 'Събота' : 'Saturday';
        case 0: return lang === 'bg' ? 'Неделя' : 'Sunday';
        default: return '';
    }
}

export const formatDate = (date, lang) => {
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} - ${getDayOfWeek(date.getDay(), lang)}`;
}
