import { notificationTypes } from "../constants";
import { showNotification } from "./notificationActions";
import { menusService } from "../../services/menusService";
import { hideApplicationLoader, showApplicationLoader } from "./applicationLoaderActions";
import {
    GET_ORANGE_MENU,
    GET_TEATRO_MENU,
    GET_TEATRO_LUNCH_MENUS,
    ADD_MEAL_TO_TEATRO_MENU,
    UPDATE_TEATRO_MENU_MEAL,
    DELETE_TEATRO_LUNCH_MENU,
    ADD_OFFER_TO_ORANGE_MENU,
    DELETE_MEAL_FROM_TEATRO_MENU,
    ADD_MEAL_TO_TEATRO_LUNCH_MENU,
    DELETE_OFFER_FROM_ORANGE_MENU,
    INSERT_BLANK_TEATRO_LUNCH_MENU,
    DELETE_MEAL_FROM_TEATRO_LUNCH_MENU,
    EDIT_OFFER_OF_ORANGE_MENU,
} from '../actionTypes';

const getTeatroMenuSuccess = menu => ({
    type: GET_TEATRO_MENU,
    payload: menu,
})

export const getTeatroMenu = () => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        const result = await menusService.getTeatroMenu();
        const action = getTeatroMenuSuccess(result[0]);
        dispatch(action);
    } catch (err) {
        console.log('getTeatroMenus:', err);
        dispatch(showNotification('could not insert menus', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

const addMealToTeatroMenuSuccess = meal => ({
    type: ADD_MEAL_TO_TEATRO_MENU,
    payload: meal,
})

export const addMealToTeatroMenu = meal => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        const result = await menusService.addMealToTeatroMenu(meal);
        const action = addMealToTeatroMenuSuccess(result);
        dispatch(action);
        dispatch(showNotification('successfully added teatro meal', notificationTypes.success));
    } catch (err) {
        dispatch(showNotification('could not add teatro meal', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

const updateTeatroMenuMealSuccess = updatedMeal => ({
    type: UPDATE_TEATRO_MENU_MEAL,
    payload: updatedMeal,
})

export const updateTeatroMenuMeal = meal => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        await menusService.updateTeatroMenuMeal(meal);
        const action = updateTeatroMenuMealSuccess(meal);
        dispatch(action);
        dispatch(showNotification('successfully updated teatro meal', notificationTypes.success));
    } catch (err) {
        dispatch(showNotification('could not update teatro meal', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

const deleteMealFromTeatroMenuSuccess = mealId => ({
    type: DELETE_MEAL_FROM_TEATRO_MENU,
    payload: mealId,
})

export const deleteMealFromTeatroMenu = mealId => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        await menusService.deleteMealFromTeatroMenu(mealId);
        const action = deleteMealFromTeatroMenuSuccess(mealId);
        dispatch(action);
        dispatch(showNotification('successfully deleted teatro meal', notificationTypes.success));
    } catch (err) {
        dispatch(showNotification('could not delete teatro meal', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

const getTeatroLunchMenusSuccess = menus => ({
    type: GET_TEATRO_LUNCH_MENUS,
    payload: menus,
})

export const getTeatroLunchMenus = () => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        const result = await menusService.getTeatroLunchMenus();
        const action = getTeatroLunchMenusSuccess(result);
        dispatch(action);
    } catch (err) {
        console.log('getTeatroLunchMenus:', err);
        dispatch(showNotification('could not get menus', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

const addMealToTeatroLunchMenuSuccess = addInfo => ({
    type: ADD_MEAL_TO_TEATRO_LUNCH_MENU,
    payload: addInfo,
})

export const addMealToTeatroLunchMenu = (menuId, meal) => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        const result = await menusService.addMealToTeatroLunchMenu(menuId, meal);
        const action = addMealToTeatroLunchMenuSuccess({ menuId, meal: result });
        dispatch(action);
        dispatch(showNotification('successfully added meal to menu', notificationTypes.success));
    } catch (err) {
        console.log(err);
        dispatch(showNotification('could not add meal to menu', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

const deleteMealFromTeatroLunchMenuSuccess = deleteInfo => ({
    type: DELETE_MEAL_FROM_TEATRO_LUNCH_MENU,
    payload: deleteInfo,
})

export const deleteMealFromTeatroLunchMenu = (menuId, mealId) => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        await menusService.deleteMealFromTeatroLunchMenu(menuId, mealId);
        const action = deleteMealFromTeatroLunchMenuSuccess({ menuId, mealId });
        dispatch(action);
        dispatch(showNotification('successfully deleted meal from menu', notificationTypes.success));
    } catch (err) {
        dispatch(showNotification('could not delete meal from menu', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

const insertBlankTeatroLunchMenuSuccess = menu => ({
    type: INSERT_BLANK_TEATRO_LUNCH_MENU,
    payload: menu,
})

export const insertBlankTeatroLunchMenu = menu => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        const result = await menusService.insertBlankTeatroLunchMenu(menu);
        const action = insertBlankTeatroLunchMenuSuccess(result);
        dispatch(action);
        dispatch(showNotification('successfully inserted menu', notificationTypes.success));
    } catch (err) {
        console.log('insertTeatroLunchMenu:', err);
        dispatch(showNotification('could not insert menu', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

const deleteTeatroLunchMenuSuccess = menuId => ({
    type: DELETE_TEATRO_LUNCH_MENU,
    payload: menuId,
})

export const deleteTeatroLunchMenu = menuId => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        await menusService.deleteTeatroLunchMenu(menuId);
        const action = deleteTeatroLunchMenuSuccess(menuId);
        dispatch(action);
        dispatch(showNotification('successfully deleted menu', notificationTypes.success));
    } catch (err) {
        console.log('deleteTeatroLunchMenu:', err);
        dispatch(showNotification('could not delete menu', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

const getOrangeMenuSuccess = menu => ({
    type: GET_ORANGE_MENU,
    payload: menu,
})

export const getOrangeMenu = () => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        const result = await menusService.getOrangeMenu();
        const action = getOrangeMenuSuccess(result[0]);
        dispatch(action);
    } catch (err) {
        console.log('getOrangeMenus:', err);
        dispatch(showNotification('could not get menus', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

const addOfferToOrangeMenuSuccess = offer => ({
    type: ADD_OFFER_TO_ORANGE_MENU,
    payload: offer,
})

export const addOfferToOrangeMenu = offer => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        const result = await menusService.addOfferToOrangeMenu(offer);
        const action = addOfferToOrangeMenuSuccess(result);
        dispatch(action);
        dispatch(showNotification('successfully added offer to orange menu', notificationTypes.success));
    } catch (err) {
        dispatch(showNotification('could not add offer to orange menu', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

const editOfferOfOrangeMenuSuccess = offer => ({
    type: EDIT_OFFER_OF_ORANGE_MENU,
    payload: offer,
})

export const editOfferOfOrangeMenu = offer => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        await menusService.editOfferOfOrangeMenu(offer);
        const action = editOfferOfOrangeMenuSuccess(offer);
        dispatch(action);
        dispatch(showNotification('successfully updated offer of orange menu', notificationTypes.success));
    } catch (err) {
        console.log(err);
        dispatch(showNotification('could not edit offer of orange menu', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

const deleteOfferFromOrangeMenuSuccess = offerId => ({
    type: DELETE_OFFER_FROM_ORANGE_MENU,
    payload: offerId,
})

export const deleteOfferFromOrangeMenu = offerId => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        await menusService.deleteOfferFromOrangeMenu(offerId);
        const action = deleteOfferFromOrangeMenuSuccess(offerId);
        dispatch(action);
        dispatch(showNotification('successfully deleted offer from orange menu', notificationTypes.success));
    } catch (err) {
        dispatch(showNotification('could not delete offer from orange menu', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}
