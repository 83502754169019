import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Fade } from 'react-reveal';

import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';

import withRoot from './modules/withRoot';
import AppAppBar from "./modules/views/AppAppBar"
import Typography from './modules/components/Typography';

import { constants } from '../../../data/constants';
import { getLang } from '../../../data/reducers/pageReducer';
import { getOrangeSpecialOffers } from '../../../data/actions/offerActions';
import OrangeSpecialOfferCardClient from './modules/components/OrangeSpecialOfferCardClient';

const styles = ({
    header: {
        margin: '50px 0',
        ['@media (max-width:750px)']: { // eslint-disable-line no-useless-computed-key
            margin: '30px 0',
            fontSize: '25px',
        }
    },
    offersWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
});

const OrangeSpecialOffers = ({
    lang,
    offers,
    classes,
    getOrangeSpecialOffers,
}) => {
    useEffect(() => {
        getOrangeSpecialOffers();
    }, [getOrangeSpecialOffers]);

    const noEventsText = {
        en: 'Currently there is no special offers. You can expect something interesting very soon :)',
        bg: 'В момента няма специални оферти. Можете да очаквате нещо интересно скоро :)',
    }
    return (
        <React.Fragment>
            <AppAppBar />
            <Container>
                <Box sx={{ mt: 7, mb: 12 }}>
                    <Fade top>
                        <Typography className={classes.header} variant="h3" gutterBottom align="center">
                            {constants.heading.orange}{' - '}
                            {lang === 'bg' ? 'Специални Оферти' : 'Special Offers'}
                        </Typography>
                    </Fade>
                    <div className={classes.offersWrapper}>
                        {
                            offers.length > 0
                                ?
                                offers.map(offer => <OrangeSpecialOfferCardClient lang={lang} offer={offer} key={offer._id} />)
                                :
                                <Fade bottom>
                                    <Typography align='center' variant='h6'>{noEventsText[lang]}</Typography>
                                </Fade>
                        }
                    </div>
                </Box>
            </Container>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    lang: getLang(state),
    offers: state.orangeOffers.all,
})

const mapDispatchToProps = {
    getOrangeSpecialOffers,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRoot(OrangeSpecialOffers)));
