import { endpoints } from './endpoints';
import { httpService } from './httpService';

export const eventsService = {
    getTeatroEvents: () => httpService.get(endpoints.teatroEvents),
    insertTeatroEvent: event => httpService.post(endpoints.teatroEvents, { event }),
    updateTeatroEvent: (event) => httpService.patch(endpoints.teatroEvents, { event }),
    deleteTeatroEvent: (eventId) => httpService.delete(endpoints.teatroEvents, { eventId }),

    getOrangeEvents: () => httpService.get(endpoints.orangeEvents),
    insertOrangeEvent: event => httpService.post(endpoints.orangeEvents, { event }),
    updateOrangeEvent: (event) => httpService.patch(endpoints.orangeEvents, { event }),
    deleteOrangeEvent: eventId => httpService.delete(endpoints.orangeEvents, { eventId })
}
