import { notificationTypes } from "../constants";
import { showNotification } from "./notificationActions";
import { eventsService } from "../../services/eventsService";
import { hideApplicationLoader, showApplicationLoader } from "./applicationLoaderActions";
import {
    GET_ORANGE_EVENTS,
    GET_TEATRO_EVENTS,
    INSERT_ORANGE_EVENT,
    INSERT_TEATRO_EVENT,
    UPDATE_ORANGE_EVENT,
    UPDATE_TEATRO_EVENT,
    DELETE_ORANGE_EVENT,
    DELETE_TEATRO_EVENT,
} from "../actionTypes";

const getTeatroEventsSuccess = events => ({
    type: GET_TEATRO_EVENTS,
    payload: events,
})

export const getTeatroEvents = () => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        const result = await eventsService.getTeatroEvents();
        const action = getTeatroEventsSuccess(result);
        dispatch(action);
    } catch (err) {
        console.log('getTeatroEvents:', err);
        dispatch(showNotification('could not get events', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

const insertTeatroEventSuccess = event => ({
    type: INSERT_TEATRO_EVENT,
    payload: event,
})

export const insertTeatroEvent = (event) => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        const result = await eventsService.insertTeatroEvent(event);
        const action = insertTeatroEventSuccess(result);
        dispatch(action);
        dispatch(showNotification('successfully created event', notificationTypes.success));
    } catch (err) {
        console.log('insertTeatroEvent:', err);
        dispatch(showNotification('could not create event', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

const updateTeatroEventSuccess = event => ({
    type: UPDATE_TEATRO_EVENT,
    payload: event,
})

export const updateTeatroEvent = (event) => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        await eventsService.updateTeatroEvent(event);
        const action = updateTeatroEventSuccess(event);
        dispatch(action);
        dispatch(showNotification('successfully updated event', notificationTypes.success));
    } catch (err) {
        console.log('updateTeatroEvent:', err);
        dispatch(showNotification('could not update event', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

const deleteTeatroEventSuccess = eventId => ({
    type: DELETE_TEATRO_EVENT,
    payload: eventId,
})

export const deleteTeatroEvent = (eventId) => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        await eventsService.deleteTeatroEvent(eventId);
        const action = deleteTeatroEventSuccess(eventId);
        dispatch(action);
        dispatch(showNotification('successfully deleted event', notificationTypes.success));
    } catch (err) {
        console.log('deleteTeatroEvent:', err);
        dispatch(showNotification('could not delete event', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

const getOrangeEventsSuccess = events => ({
    type: GET_ORANGE_EVENTS,
    payload: events,
})

export const getOrangeEvents = () => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        const result = await eventsService.getOrangeEvents();
        const action = getOrangeEventsSuccess(result);
        dispatch(action);
    } catch (err) {
        console.log('getOrangeEvents:', err);
        dispatch(showNotification('could not get events', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

const insertOrangeEventSuccess = event => ({
    type: INSERT_ORANGE_EVENT,
    payload: event,
})

export const insertOrangeEvent = (event) => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        const result = await eventsService.insertOrangeEvent(event);
        const action = insertOrangeEventSuccess(result);
        dispatch(action);
        dispatch(showNotification('successfully created event', notificationTypes.success));
    } catch (err) {
        console.log('insertOrangeEvent:', err);
        dispatch(showNotification('could not create event', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

const updateOrangeEventSuccess = event => ({
    type: UPDATE_ORANGE_EVENT,
    payload: event,
})

export const updateOrangeEvent = (event) => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        await eventsService.updateOrangeEvent(event);
        const action = updateOrangeEventSuccess(event);
        dispatch(action);
        dispatch(showNotification('successfully updated event', notificationTypes.success));
    } catch (err) {
        console.log('updateOrangeEvent:', err);
        dispatch(showNotification('could not update event', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

const deleteOrangeEventSuccess = eventId => ({
    type: DELETE_ORANGE_EVENT,
    payload: eventId,
})

export const deleteOrangeEvent = (eventId) => async dispatch => {
    try {
        dispatch(showApplicationLoader());
        await eventsService.deleteOrangeEvent(eventId);
        const action = deleteOrangeEventSuccess(eventId);
        dispatch(action);
        dispatch(showNotification('successfully deleted event', notificationTypes.success));
    } catch (err) {
        console.log('deleteOrangeEvent:', err);
        dispatch(showNotification('could not delete event', notificationTypes.error));
    } finally {
        dispatch(hideApplicationLoader());
    }
}
