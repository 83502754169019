import { useEffect, useState } from "react";
import { connect } from "react-redux"
import { Button } from "@material-ui/core";

import OrangeSpecialOfferCard from "./OrangeSpecialOfferCard";
import OrangeSpecialOfferEditDialog from "./OrangeSpecialOfferEditDialog";
import OrangeSpecialOfferCreateDialog from "./OrangeSpecialOfferCreateDialog";
import OrangeSpecialOfferConfirmDeleteDialog from "./OrangeSpecialOfferConfirmDeleteDialog";
import {
    getOrangeSpecialOffers,
    insertOrangeSpecialOffer,
    deleteOrangeSpecialOffer,
    updateOrangeSpecialOffer,
} from "../../../../../data/actions/offerActions";

const OrangeSpecialOffersTab = ({
    lang,
    offers,
    getOrangeSpecialOffers,
    insertOrangeSpecialOffer,
    deleteOrangeSpecialOffer,
    updateOrangeSpecialOffer,
}) => {
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [selectedOffer, setSelectedSpecialOffer] = useState('');
    const [openEditDialog, setOpenEditEventDialog] = useState(false);
    const [openConfirmDeleteDialog, setOpenConfirmDeleteSpecialOfferDialog] = useState(false);

    const handleOpenCreateDialog = () => setOpenCreateDialog(true);
    const handleCloseCreateDialog = () => setOpenCreateDialog(false);

    const handleCreateSpecialOffer = specialOffer => {
        insertOrangeSpecialOffer(specialOffer);
        handleCloseCreateDialog();
    }

    const handleUpdateSpecialOffer = specialOffer => {
        updateOrangeSpecialOffer(specialOffer);
        handleCloseEditSpecialOfferDialog();
    }

    const handleDeleteSpecialOffer = specialOfferId => {
        deleteOrangeSpecialOffer(specialOfferId);
        handleCloseConfirmDeleteDialog();
    }

    const handleOpenEditSpecialOfferDialog = specialOffer => {
        setSelectedSpecialOffer(specialOffer);
        setOpenEditEventDialog(true);
    }
    const handleCloseEditSpecialOfferDialog = () => {
        setSelectedSpecialOffer('');
        setOpenEditEventDialog(false);
    }

    const handleOpenConfirmDeleteSpecialOfferDialog = specialOffer => {
        setSelectedSpecialOffer(specialOffer);
        setOpenConfirmDeleteSpecialOfferDialog(true);
    }
    const handleCloseConfirmDeleteDialog = () => {
        setSelectedSpecialOffer('');
        setOpenConfirmDeleteSpecialOfferDialog(false);
    }

    useEffect(() => {
        getOrangeSpecialOffers();
    }, [getOrangeSpecialOffers]);

    return (
        <div>
            <Button
                fullWidth
                color='primary'
                variant='outlined'
                onClick={handleOpenCreateDialog}
            >
                Създай нова специална оферта
            </Button>
            {
                offers.map(offer => {
                    return (
                        <OrangeSpecialOfferCard
                            lang={lang}
                            offer={offer}
                            key={offer._id}
                            handleOpenEditDialog={() => handleOpenEditSpecialOfferDialog(offer)}
                            handleOpenConfirmDeleteDialog={() => handleOpenConfirmDeleteSpecialOfferDialog(offer)}
                        />
                    )
                })
            }
            <OrangeSpecialOfferCreateDialog
                open={openCreateDialog}
                handleCreate={handleCreateSpecialOffer}
                handleClose={handleCloseCreateDialog}
            />
            <OrangeSpecialOfferEditDialog
                offer={selectedOffer}
                open={openEditDialog}
                handleUpdate={handleUpdateSpecialOffer}
                handleClose={handleCloseEditSpecialOfferDialog}
            />
            <OrangeSpecialOfferConfirmDeleteDialog
                offer={selectedOffer}
                handleDelete={handleDeleteSpecialOffer}
                open={openConfirmDeleteDialog}
                handleClose={handleCloseConfirmDeleteDialog}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    lang: state.page.language,
    offers: state.orangeOffers.all,
})

const mapDispatchToProps = {
    getOrangeSpecialOffers,
    insertOrangeSpecialOffer,
    deleteOrangeSpecialOffer,
    updateOrangeSpecialOffer,
}

export default connect(mapStateToProps, mapDispatchToProps)(OrangeSpecialOffersTab);
