import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ConfirmDeleteMenuDialog({
    open,
    handleClose,
    handleDelete,
}) {
    return (
        <Dialog
            fullWidth
            open={open}
            maxWidth='sm'
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Потвърди изтриване на меню</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Сигурни ли сте, че искате да изтриете това меню?
                    </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant='outlined' color="secondary">
                    Не
                    </Button>
                <Button onClick={handleDelete} variant='outlined' color="primary" autoFocus>
                    Да
                    </Button>
            </DialogActions>
        </Dialog>
    );
}