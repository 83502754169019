import * as React from 'react';
import PropTypes from 'prop-types';
import { Fade } from 'react-reveal';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Link, Button, Container } from '@material-ui/core';

import Typography from '../components/Typography';
import happyIcon from '../../../../../assets/happy.svg';
import { getLang } from '../../../../../data/reducers/pageReducer';
import { constants, corporatePhoneOrange } from '../../../../../data/constants';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  button: {
    border: '4px solid currentColor',
    borderRadius: 0,
    height: 'auto',
    padding: theme.spacing(2, 5),
  },
  link: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  buoy: {
    width: 60,
  },
});

function ProductSmokingHero({
  lang,
  ...props
}) {
  const { classes } = props;

  return (
    <Container className={classes.root} component="section">
      <Fade top>
        <Button className={classes.button}>
          <Link href={`tel:${corporatePhoneOrange}`}>
            <Typography variant="h4" component="span">
              {lang === 'bg' ? 'Имате въпроси? Свържете се с нас.' : 'Have any questions? Contact us.'}
            </Typography>
          </Link>
        </Button>
      </Fade>
      <Fade bottom>
        <Typography align='center' variant="subtitle1" className={classes.link}>
          {lang === 'bg' ? 'Екипът на ' : 'The team of '}
          <span style={{ textDecoration: 'underline' }}>
            {constants.heading.orange}
          </span>
          {lang === 'bg' ? ' е на ваше разположение.' : ' is at your disposal.'}
        </Typography>
      </Fade>
      <img
        src={happyIcon}
        className={classes.buoy}
        alt="happy"
      />
    </Container>
  );
}

const mapStateToProps = state => ({
  lang: getLang(state),
})

ProductSmokingHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(ProductSmokingHero));
