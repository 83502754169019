import { endpoints } from './endpoints';
import { httpService } from './httpService';

const teatroMeal = endpoints.teatroMenus + '/meal';
const teatroLunchMeal = endpoints.teatroLunchMenus + '/meal';
const orangeOffer = endpoints.orangeMenus + '/offer';

export const menusService = {
    getTeatroMenu: () => httpService.get(endpoints.teatroMenus),
    addMealToTeatroMenu: meal => httpService.patch(teatroMeal, { meal }),
    updateTeatroMenuMeal: meal => httpService.patch(teatroMeal + '/update', { meal }),
    deleteMealFromTeatroMenu: mealId => httpService.delete(teatroMeal, { mealId }),

    getTeatroLunchMenus: () => httpService.get(endpoints.teatroLunchMenus),
    insertBlankTeatroLunchMenu: menu => httpService.post(endpoints.teatroLunchMenus, { menu }),
    deleteTeatroLunchMenu: menuId => httpService.delete(endpoints.teatroLunchMenus, { menuId }),
    addMealToTeatroLunchMenu: (menuId, meal) => httpService.patch(teatroLunchMeal, { menuId, meal }),
    deleteMealFromTeatroLunchMenu: (menuId, mealId) => httpService.delete(teatroLunchMeal, { menuId, mealId }),

    getOrangeMenu: () => httpService.get(endpoints.orangeMenus),
    addOfferToOrangeMenu: offer => httpService.patch(orangeOffer, { offer }),
    editOfferOfOrangeMenu: offer => httpService.patch(orangeOffer + '/update', { offer }),
    deleteOfferFromOrangeMenu: offerId => httpService.delete(orangeOffer, { offerId }),
}
