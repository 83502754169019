import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Fade } from 'react-reveal';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';

import withRoot from './modules/withRoot';
import AppAppBar from "./modules/views/AppAppBar"
import Typography from './modules/components/Typography';

import { constants } from '../../../data/constants';
import { getLang } from '../../../data/reducers/pageReducer';
import { getOrangeEvents } from '../../../data/actions/eventActions';
import OrangeEventCardClient from './modules/components/OrangeEventCardClient';

const styles = ({
    header: {
        margin: '50px 0',
        ['@media (max-width:750px)']: { // eslint-disable-line no-useless-computed-key
            margin: '30px 0',
            fontSize: '25px',
        }
    },
});

const OrangeEvents = ({
    lang,
    events,
    classes,
    getOrangeEvents,
}) => {
    useEffect(() => {
        getOrangeEvents();
    }, [getOrangeEvents]);

    const noEventsText = {
        en: 'Currently there is no events. You can expect something interesting very soon :)',
        bg: 'В момента няма очаквани събития, но може да очаквате нещо интересно скоро :)',
    }
    const futureEvents = events.filter(x => x.date > Date.now());
    return (
        <React.Fragment>
            <AppAppBar />
            <Container>
                <Box sx={{ mt: 7, mb: 12 }}>
                    <Fade top>
                        <Typography className={classes.header} variant="h3" gutterBottom align="center">
                            {constants.heading.orange}{' - '}
                            {lang === 'bg' ? 'Събития' : 'Events'}
                        </Typography>
                    </Fade>
                    {
                        futureEvents.length > 0
                            ?
                            futureEvents.map(event => <OrangeEventCardClient lang={lang} event={event} key={event._id} />)
                            :
                            <Fade bottom>
                                <Typography align='center' variant='h6'>{noEventsText[lang]}</Typography>
                            </Fade>
                    }
                </Box>
            </Container>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    lang: getLang(state),
    events: state.orangeEvents.all,
})

const mapDispatchToProps = {
    getOrangeEvents,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRoot(OrangeEvents)));
